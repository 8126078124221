import { Guid } from "guid-typescript";

export class Partner {
    public id: number = 0;
    public name: string = "";
    public shopId: string = "";
    public relationshipUrl: string = "";
    public partnerBusinessContractCode: string = "";
    public commissionPercentage: number = 0.00;
    public erpSalesPersonCode: string = "";
    public mainContractId: number = 0;
    public abletechContractId: number = 0;
    public abletechSlaveAooContractId: number = 0;
    public abletechCancellationContractId: number = 0;
    public stripeShopUrl: string = "";
    public stripeKey: string = "";
    public stripePublicKey: string = "";
    public stripeWebhookToken: string = "";
    public stripeIvaId: string = "";
    public dns: string = "";
    public crmPartnerId: string = "";
    public systemId: string = "";
    public hashKey: string = "";
}

export class PartnerDashboardCue {
    public lead: number = 0;
    public newLead: number = 0;
    public activeLead: number = 0;
    public trialLead: number = 0;
    public customer: number = 0;
    public newCustomer: number = 0;
    public totalSubscription: number = 0;
    public newSubscription: number = 0;
    public totalRevenue: number = 0;
    public newRevenue: number = 0;
    public interest: number = 0;
    public firstContact: number = 0;
    public develop: number = 0;
    public propose: number = 0;
    public buy: number = 0;
    public partnerId: number = 0;
}

export class Lead {
    public id: number = 0;
    public companyName: string = "";
    public firstname: string = "";
    public lastname: string = "";
    public email: string = "";
    public address: string = "";
    public zipCode: string = "";
    public phone: string = "";
    public county: string = "";
    public city: string = "";
    public country: string = "";
    public origin: string = "";
    public campaignCode: string = "";
    public originName: string = "";
    public salesPersonId: number = 0;
    public preSalesPersonId: number = 0;
    public partnerId: number = 0;
    public crmLeadId: string = "";
    public workflowCode: number = 0;
    public full_Users_No: number = 0;
    public limited_Users_No: number = 0;
    public limited_User_No: number = 0;
    public tenantNew: boolean = false;
    public isTrial: number = 0;
    public note: string = "";
    public finance: boolean = false;
    public sales: boolean = false;
    public purchase: boolean = false;
    public job: boolean = false;
    public inventory: boolean = false;
    public assembly: boolean = false;
    public eCommerce: boolean = false;
    public service: boolean = false;
    public retail: boolean = false;
    public budget: number = 0;
    public negotiationState: string = "";
    public tokenForDemo: string = "";
    public autoSendToken: boolean = false;
    public salesHeaderTemplateId: number = 0;
    public openPopup: boolean = false;
    public noOfCompany: number = 0;
    public offerLink: string = "";
    public score: number = 0;
    public createdWithPromoCode: string = "";
    public systemId: string = Guid.EMPTY;
    public systemCreationDate: Date = new Date();
    public timestamp: string = "";
    public linkUserId: number = 0;
    public foreignAddress: boolean = false;
    public marketingQualificationCarriedOut: boolean = false;
    public expectedLiveDate: Date = new Date();
    public decisionDate: Date = new Date();
    public password: string = "";
    public referralKey: string = "";
    public registerRequest: string = "";
    public evaluationMode: number = 0;
    public rating: number = 0;
    public revenue: number = 0;
    public currentSystem: string = "";
    public currentERPName: string = "";
    public liveMode: LeadLiveMode = 0;
    public salesHeaderTemplateCode: string = "";
    public legalEntityCode: string = "";
}

export class SalesHeaderTemplate {
    public id: number = 0;
    public createdAt: Date = new Date();
    public expirationDate: Date = new Date();
    public source: number = 0;
    public code: string = "";
    public description: string = "";
    public budgetPerFullUser: number = 0.00;
    public budgetPerLimitedUser: number = 0.00;
    public systemId: string = "";
}

export class SalesLineTemplate {
    public id: number = 0;
    public createdAt: Date = new Date();
    public expirationDate: Date = new Date();
    public source: number = 0;
    public code: string = "";
    public description: string = "";
    public budgetPerFullUser: number = 0.00;
    public budgetPerLimitedUser: number = 0.00;
    public systemId: string = "";
}

export class Customer {
    public id: number = 0;
    public companyName: string = "";
    public domain: string = "";
    public hasTenant: boolean = false;
    public mainContact: string = "";
    public phone: string = "";
    public customerInternalRefNo: string = "";
    public address: string = "";
    public zipCode: string = "";
    public city: string = "";
    public county: string = "";
    public country: string = "";
    public email: string = "";
}

export class Product {
    public id: number = 0;
    public description: string = '';
    public isBundle: boolean = true;
    public salePrice: number = 0;
    public defaultInvoicingPeriod: string = '';
    public saleEnabled: boolean = true;
    public bundleId: number = 0;
}

export class OrderHeader {
    public documentNo: string = "";
    public customerId: number = 0;
    public customerName: string = "";
    public country: string = "";
    public activationDate: Date = new Date(); 
}

export class OrderLine {
    public documentNo: string = "";
    public lineNo: number = 0;
    public productId: number = 0;
    public description: string = "";
    public quantity: number = 0;
    public unitPrice: number = 0;
    public lineAmount: number = 0;
    public activationDate: Date = new Date();
}

export class CrmSetup {
    public primaryKey: string = "";
    public authType: number = 0;
    public resource: string = "";
    public tenantId: string = "";
    public clientId: string = "";
    public clientSecret: string = "";
    public redirectUrl: string = "";
    public aadInstance: string = "";
    public baseAddress: string = "";
    public oDataVersion: string = "";
    public oDataMaxVersion: string = "";
    public defaultCustomerTypeCode: number = 0;
    public defaultCountryId: number = 0;
    public defaultLegalEntityId: number = 0;
    public defaultEntitlementName: string = "";
    public defaultServiceLevel: string = "";
    public serviceScopeEOSName: string = "";
    public serviceScopeQueueId: string = "";
    public serviceScopeId: string = "";
    public crmSyncEnabled: boolean = false;
    public incidentBaseAddress: string = "";
    public incidentDayLimitForExpiration: number = 0;
    public incidentDayLimitForTakeInCharge: number = 0;
    public knowledgeArticleBaseAddress: string = "";
    public leadQualificationInteractionLimitDays: number = 0;
    public leadGetDemoFeedbackLimitDays: number = 0;
    public leadWithoutDemoFeedbackLimitDays: number = 0;
    public leadQualifiedWithoutInteractionLimitDays: number = 0;
}

export class Item {
    public id: number = 0;
    public description: string = "";
    public category: string = "";
    public itemErpNo: string = "";
}

export class PartnerBusinessContract {
    public code: string = "";
    public description: string = "";
    public partnerAccountCrmSyncActive: boolean = false;
    public partnerAccountCrmOdataFilter: string = "";
    public leadCrmSyncActive: boolean = false;
    public leadCrmOdataFilter: string = "";
    public defaultAppId: string = "";
    public defaultRoleCode: string = "";
    public erpChannelDimension: string = "";
    public isItemRequired: boolean = false;
}

export class BookingAvailabilityItem {
    public serviceId: string = "";
    public serviceName: string = "";
    public staffId: string = "";
    public staffName: string = "";
    public status: string = "";
    public startDateTime: Date = new Date();
    public startDateTimeZone: string = "";
    public endDateTime: Date = new Date();
    public endDateTimeZone: string = "";
    public serviceDuration: number = 0;
}

export class BookingAvailabilityObject {
    public ianaTimeZone: string = "";
    public bookingAvailabilityItem: BookingAvailabilityItem = new BookingAvailabilityItem();
}

export class SharepointFile {
    public id: string = "";
    public listId: string = "";
    public fileName: string = "";
    public fileSize: number = 0;
    public fileExtension: string = "";
    public webUrl: string = "";
}

export class LeadQualifiedAnswer {
    public entryNo: number = 0;
    public leadId: number = 0;
    public answerCode: string = "";
    public answerType: string = "";
    public description: string = "";
    public score: number = 0;
    public note: string = "";
    public wizardCode: string = "";
}

export enum LeadQuestionnaireInputType {
    Text,
    Number,
    Date,
    Boolean,
    None
}

export enum LeadLiveMode {
    None,
    ImmidiateLive,
    ShortTimeChange,
    AllFeatureValuation,
    NoClearGoals
}

export class LeadQuestionnaireData {
    public id: string = "";
    public title: string = "";
    public questionnaireRows: LeadQuestionnaireRow[] = [];
}

export class LeadQuestionnaireRow {
    public answerType: string = "";
    public answerText: string = "";
    public answerValue: string = "";
    public inputType: LeadQuestionnaireInputType = LeadQuestionnaireInputType.Text;
    public isGroup: boolean = false;
}

export class LeadQuestionnaireDataModified {
    public answerType: string = "";
    public answerValue: string = "";
    public questionnaireId: string = "";
}

export enum BookingSource
{
    Partner = 'Partner',
    Demo = 'Demo'
}