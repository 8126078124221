import { ICommandBarItemProps,mergeStyles,PrimaryButton,Stack} from "@fluentui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PartnerService } from "../../../ApplicationCode/Partner/PartnerService";
import { SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { Lead, PartnerDashboardCue, SharepointFile } from "../../../Model/PartnerModels";
import { SystemDataLoadingStatus, SystemOperation } from "../../../Model/SystemModels";
import { Banner, CueBox, DashboardCarousel, DashboardContentColumn, DashboardContentContainer, DashboardContentRow, DataBox, UserProfileCard } from "../../Common/Dashboard/DashboardComponents";
import { Loader } from "../../Common/Loader/Loader";
import { PageContainerBase } from "../../Common/Template/PageContainer";
import { PartnerLeadCard } from "../PartnerLeadCard/PartnerLeadCard";
import { ActionBar,CarouselItem,DashboardContainer, SharepointDocumentList} from "./PartnerDashboardComponents";
import { CustomerSocialSlide } from "../../Customer/CustomerDashboard/CustomerDashboardComponents";

const dashboardCarouselClassName = mergeStyles([{
    width: "100%",
    height: "auto",
    position: "relative",
    margin: "auto"
}])

export const PartnerDashboard : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [dashboardCue,setDashboardCue] = useState<PartnerDashboardCue>({} as PartnerDashboardCue);
    const [sharepointFiles, setSharepointFiles] = useState<SharepointFile[]>([] as SharepointFile[]);
    const sessionInfoContext = useContext(SessionStateContext);
    const leadCardRef = useRef<PageContainerBase>(null);
    const partnerService = new PartnerService();
    const navigate = useNavigate();

    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            partnerService.getPartnerDashboardCue()
            .then((resp: PartnerDashboardCue) => {
                setDashboardCue(resp);
                partnerService.getSharepointFiles(resp.partnerId)
                .then((resp: SharepointFile[]) =>{
                    setSharepointFiles(resp);                    
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                })
                .catch(() => {
                    setSharepointFiles([])
                    setDataStatus(SystemDataLoadingStatus.Loaded);
                })
            })
        }
    },[dataStatus])

    //#region ActionProps

    const Items: ICommandBarItemProps[] = [
        {
            key: 'NewLead',
            text: 'Nuova Lead',
            iconProps: { iconName: 'AddFriend' },
            onClick: () => {
                let newRecord = new Lead();
                newRecord.partnerId = dashboardCue.partnerId;
                newRecord.marketingQualificationCarriedOut = true;
                leadCardRef.current?.setRecord(newRecord);
                
                leadCardRef.current?.openPage(SystemOperation.Insert);
            }
        },
        {
            key: 'WebSite',
            text: 'Sito web',
            iconProps: { iconName: 'Globe' },
            onClick: () => { window.open("https://www.so-smart.it/",'_blank') }
        },
        {
            key: 'Shop',
            text: 'Shop',
            iconProps: { iconName: 'ShoppingCart' },
            onClick: () => { window.open(process.env.REACT_APP_SHOP_URL,'_blank') }
        },
        {
            key: 'Ticket',
            text: 'Ticket assistenza',
            iconProps: { iconName: 'Comment' },
            onClick: () => {navigate("/app/partner/incidents")}
        }
    ]

    //#endregion

    if (! SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text="Stiamo caricando la tua dashboard..." />
    else
    {
        return (
            <>
                <DashboardContainer>
                    <ActionBar items={Items}></ActionBar>
                    <Banner 
                        leftContent={
                            <UserProfileCard/>
                        }
                        rightContent={
                            <DashboardCarousel containerClassName={dashboardCarouselClassName} activeSlide={1}>
                                <CarouselItem
                                    title="Nuove Lead"
                                    description={"Negli ultimi 30 giorni sono state segnalate "+dashboardCue.newLead+" lead"}
                                    iconName="UserFollowed"
                                />
                                <CarouselItem
                                    title="Nuovi clienti"
                                    description={"Negli ultimi 30 giorni sono stati acquisiti "+dashboardCue.newCustomer+" nuovi clienti"}
                                    iconName="FabricUserFolder"
                                />
                                <CarouselItem
                                    title={"Fatturato: "+dashboardCue.totalRevenue+" €"}
                                    description={"Negli ultimi 30 giorni il fatturato è stato di "+dashboardCue.newRevenue+" €"}
                                    iconName="Money"
                                />
                                <CarouselItem
                                    title={"Sottoscrizioni: "+dashboardCue.totalSubscription +" €"}
                                    description={"Negli ultimi 30 giorni sono state create nuove sottoscrizioni di "+dashboardCue.newSubscription+" €"}
                                    iconName="Subscribe"
                                />
                            </DashboardCarousel>
                        }
                    />
                    <DashboardContentRow id={'1'}>
                        <Stack key={"dashboardVerticalWidgets"} styles={{root: {width: '60%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer title="Indicatori generali" name="generalIndicators">
                                    <Stack horizontal wrap tokens={{childrenGap: 10}}>
                                        <DataBox 
                                            name="ActiveLead" 
                                            title="Lead attive" 
                                            iconName="UserFollowed" 
                                            value={dashboardCue.activeLead} 
                                            link={"/app/partner/leads?activeLeads=true"} 
                                        />
                                        <DataBox 
                                            name="TotalLead" 
                                            title="Lead totali" 
                                            value={dashboardCue.lead} 
                                            iconName="CheckList" 
                                            link={"/app/partner/leads"} 
                                        />
                                        <DataBox 
                                            name="ActiveCustomer" 
                                            title="Clienti attivi" 
                                            value={dashboardCue.customer} 
                                            iconName="UserEvent" 
                                            link={"mailto:orders@so-smart.it"}
                                            openExternalLink 
                                        />
                                    </Stack> 
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer title="Le tue app" name="appsContainer">
                                    <Stack id="appsSection" horizontal tokens={{
                                        childrenGap: 10
                                    }}>
                                        <CueBox name="Teams" isIconBox title="Teams" iconName="TeamsLogo" link={"https://aka.ms/mstfw?login_hint_safe=" + sessionInfoContext.currentUser.email} />
                                        <CueBox name="Outlook" isIconBox title="Outlook" iconName="OutlookLogo" link={"https://outlook.office365.com/mail/inbox"} />
                                        <CueBox name="OneDrive" isIconBox title="OneDrive" iconName="OneDriveLogo" link={"https://onedrive.live.com/about/it-it/signin"} />
                                        <CueBox name="PowerBi" isIconBox title="PowerBI" iconName="PowerBILogo16" link={"https://app.powerbi.com/home"} />
                                        <CueBox name="BusinessCentral" isIconBox title="Business Central" iconName="Dynamics365Logo" link={"https://businesscentral.dynamics.com/"} />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                        <Stack key={"dashboardSharepointDocs"} styles={{root: {width: '40%'}}} tokens={{childrenGap: 20}}>
                            <DashboardContentColumn>
                                <DashboardContentContainer  title="Documentazione sharepoint" name="sharepointDocumentation">
                                    <SharepointDocumentList items={sharepointFiles} partnerId={dashboardCue.partnerId} />
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                            <DashboardContentContainer name="messageCarouselContainer" title="Alcuni consigli per te">
                                    <DashboardCarousel containerClassName={dashboardCarouselClassName} activeSlide={1}>
                                        <CustomerSocialSlide />
                                        <Stack key={"message2"} style={{
                                            width: '100%',
                                            display: 'flex',
                                            minHeight: '320px',
                                            padding: 40,
                                            backgroundColor: 'rgb(217, 240, 242)'
                                        }}>
                                            <Stack.Item style={{
                                                fontSize: 32,
                                                padding: 10,
                                                display: 'flex',
                                                alignItems: 'stretch'
                                            }}>
                                                Scopri la soluzione SoSmart
                                            </Stack.Item>
                                            <Stack.Item style={{
                                                fontSize: 18,
                                                padding: 10,
                                                display: 'flex',
                                                alignItems: 'stretch'
                                            }}>
                                                <div>
                                                Non hai ancora attivato SoSmart per gestire la tua Azienda?<br />
                                                Contatta un nostro referente per avere tutte le informazioni necesarie.
                                                <br /><br />
                                                <PrimaryButton text="Contattaci" styles={{
                                                    root: {
                                                        backgroundColor: '#008089',
                                                        borderColor: '#008089'
                                                    },
                                                    rootHovered: {
                                                        backgroundColor: 'rgb(1, 93, 99)',
                                                        borderColor: 'rgb(1, 93, 99)'
                                                    }
                                                }}  onClick={() => {window.open("mailto:orders@so-smart.it", "_blank");}}  />
                                                </div>
                                            </Stack.Item>
                                        </Stack>
                                    </DashboardCarousel>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                    </DashboardContentRow>
                </DashboardContainer>
                <PartnerLeadCard ref={leadCardRef} />
            </>
        );
    }
}