import React from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import {SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const PartnerCrmLeadWorkflowList : React.FC = () => {
    const leadId= HttpHelper.getParameter('leadId');

    return (
        <>
            <PageContainer endpoint={"/odata/crm/lead/workflow?$filter=leadId eq "+leadId} pageType={SystemPageType.List} title={"Lista CRM Lead Workflow Log"} backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="ID Lead" fieldName="leadId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Codice Workflow" fieldName="workflowCode" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Attivo" fieldName="isActive" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}