import { DirectionalHint, ICommandBarItemProps, mergeStyles, Stack } from "@fluentui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CustomerInformationContext } from "../../../ApplicationCode/Customer/CustomerInformationContext";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { SessionStateContext } from "../../../Core/State/SessionStateContext";
import { SystemCore } from "../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { ApplicationForUserType, Customer, CustomerCompany, CustomerEnvironment, CustomerModuleTelemetry, CustomerProgressChecklist, CustomerTrialStatus, TenantInformationSetup, UserInformationSetup } from "../../../Model/CustomerModels";
import { ActionType, ITourEntry, SystemDataLoadingStatus, SystemOperation, SystemUser, User, UserSettings } from "../../../Model/SystemModels";
import { Loader } from "../../Common/Loader/Loader";
import { CustomerBillingInfoCard } from "../CustomerBillingInfoCard/CustomerBillingInfoCard";
import { PageContainerBase } from "../../Common/Template/PageContainer";
import { CustomerCreateLeadCard } from "../CustomerCreateLeadCard/CustomerCreateLeadCard";
import { Banner, CueBox, DashboardCommandBar, DashboardContainer, DashboardContentColumn, DashboardContentContainer, DashboardContentRow, DashboardMessageCard, TaskProgressCard, UserProfileCard } from "../../Common/Dashboard/DashboardComponents";
import { CustomerEnvironmentList } from "./CustomerDashboardComponents";
import { useNavigate } from "react-router-dom";
import { Lead } from "../../../Model/PartnerModels";
import { CustomerTenantSetupBox } from "../CustomerTenantSetupBox/CustomerTenantSetupBox";
import { CustomerTenantDetailBox } from "../CustomerTenantDetailBox/CustomerTenantDetailBox";
import { CustomerSetupCompanyList } from "../CustomerSetupCompanyList/CustomerSetupCompanyList";
import { CustomerUserSetupList } from "../CustomerUserSetupList/CustomerUserSetupList";
import { CustomerMainUserSetupCard } from "../CustomerMainUserSetupCard/CustomerMainUserSetupCard";
import { CommonGuideTourDialog } from "../../Common/CommonGuideTourDialog/CommonGuideTourDialog";
import { CommonGuideTour } from "../../Common/CommonGuideTour/CommonGuideTour";
import { CommonVideoModal } from "../../Common/CommonVideoModal/CommonVideoModal";
import { ContentManagement, IContentManagement } from "../../../ApplicationCode/Common/ContentManagement";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { t } from "i18next";


const dashboardCarouselClassName = mergeStyles([{
    width: "100%",
    minHeight: "320px",
    position: "relative",
    margin: "auto"
}])

export const CustomerDashboard : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [loaderMessage, setLoaderMessage] = useState(t('customerCenter:messageContent1')!);
    const [environments, setEnvironments] = useState([] as CustomerEnvironment[]);
    const [tasks, setTasks] = useState([] as CustomerProgressChecklist[]);
    const [adminUser,setAdminUser] = useState({} as User);
    const [adminUserName,setAdminUserName] = useState("");
    const [customer, setCustomer] = useState({} as Customer);
    const [showLeadCard, setShowLeadCard] = useState(false);
    const [showTenantInfo, setShowTenantInfo] = useState(false);
    const [showTenantDetailInfo, setShowTenantDetailInfo] = useState(false);
    const [showCompanySetup, setShowCompanySetup] = useState(false);
    const [showUserSetup, setShowUserSetup] = useState(false);
    const [showMainUserSetup, setShowMainUserSetup] = useState(false);
    const [showGuideTourDialog,setShowGuideTourDialog] = useState(false);
    const [guideTourElement,setGuideTourElement] = useState<JSX.Element>(<></>);
    const [currentGuideTourEntries,setCurrentGuideTourEntries] = useState([] as ITourEntry[]);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
    const [videoResource, setVideoResource] = useState<IContentManagement>();
    const [shopLink, setShopLink] = useState("");
    const [purchaseClicked, setPurchasedClicked] = useState(false);
    const [tenantInformation, setTenantInformation] = useState(new TenantInformationSetup());
    const [customerModuleTelemetry, setCustomerModuleTelemetry] = useState({} as CustomerModuleTelemetry);
    const sessionInfoContext = useContext(SessionStateContext);
    const customerInfoContext = useContext(CustomerInformationContext);
    const customerService = new CustomerService();
    const navigate = useNavigate();
    const billingCardRef = useRef<PageContainerBase>(null);
    const [bubbleVisible, setBubbleVisible] = useState<boolean>(true);
    const [timerId, setTimerId] = useState<number>(0);

    useEffect(() => {
        if (dataStatus == SystemDataLoadingStatus.ToLoad)
        {
            let customerRef = new Customer();
            let shopLinkRef = "";
            setDataStatus(SystemDataLoadingStatus.Loading);

            if (!sessionInfoContext.currentUserSettings.hideCustomerCommonGuideTour)
                setShowGuideTourDialog(true);
            else
                setShowGuideTourDialog(false);

            customerService.getCustomerEnvironments(customerInfoContext.setupConfig.customerId)
                .then((result) => {
                    setEnvironments(result);
                    customerService.getCustomer(customerInfoContext.setupConfig.customerId)
                        .then((resp) => {
                            setCustomer(resp);
                            customerRef = resp;
                            customerService.generateShopLink()
                                .then((resp) => {                                        
                                    setShopLink(resp);
                                    shopLinkRef = resp;
                                    customerService.getTasks(customerInfoContext.setupConfig.customerId)
                                        .then((result) => {
                                            setTasks(result);
                                            customerService.getAdminUser(customerInfoContext.setupConfig.customerId)
                                                .then((resp) => {
                                                    setAdminUser(resp);
                                                    setAdminUserName(resp.displayName);
                                                    setDataStatus(SystemDataLoadingStatus.Loaded);
                                                    
                                                    //#region Handle Shared video
                                                    
                                                    let videoContent = HttpHelper.getParameter('videoContent');
                                                    let descriptionContent = HttpHelper.getParameter('description');
                                                    const buyShortCut = HttpHelper.getParameter('shortCutBuy');

                                                    if( videoContent != null ){
                                                        ContentManagement.generateResource(videoContent,descriptionContent).then((resource)=>{
                                                            setVideoResource(resource);
                                                            setShowVideoModal(true);
                                                            
                                                            CommonFunctions.logEvent("Apertura dialog video condiviso: "+resource.description,resource.contentVideo!)
                                                        })
                                                        .catch((jqXHR)=>{
                                                            setDataStatus(SystemDataLoadingStatus.Loaded);
                                                            ToastService.showMessage(MessageType.Error,t('customerCenter:Err00001Text'));
                                                        })

                                                        deleteVideoParameters();
                                                    }

                                                    //#endregion

                                                    //#region Buy Shortcut

                                                    if((buyShortCut != null) && (buyShortCut === "true")){
                                                        if (sessionInfoContext.currentUser.mainUser)
                                                            handleRedirectToShop(customerRef,shopLinkRef);
                                                        else
                                                            ToastService.showMessage(MessageType.Error,t('customerCenter:Err00002Text'));
                                                            
                                                        CommonFunctions.deleteShortCutParameters(navigate);
                                                    }

                                                    //#endregion

                                                    customerService.getCustomerModuleTelemetry(sessionInfoContext.currentUser.referenceId)
                                                        .then((resp)=>{
                                                            setCustomerModuleTelemetry(resp);
                                                        })
                                                })
                                                .catch((jqXHR)=>{
                                                    console.log(jqXHR.message);
                                                });
                                        });
 
                                });
                        });
                });
        }
    }, [dataStatus]);

    const deleteVideoParameters = () => {
        let urlParams = new URLSearchParams(window.location.search);
        urlParams.delete('videoContent');
        urlParams.delete('description');

        navigate("?"+urlParams.toString(),{replace:true});
    }

    const onCloseVideoModal = (closeWithouthPlaying:boolean) => {
        if(closeWithouthPlaying)
            CommonFunctions.logEvent("Chiusura dialog video condiviso senza visualizzazione: "+videoResource?.description,videoResource?.contentVideo!);
        else
            CommonFunctions.logEvent("Chiusura dialog video condiviso: "+videoResource?.description,videoResource?.contentVideo!);
        
        setShowVideoModal(false);
        setVideoResource({} as IContentManagement);
    }

    const handleRedirectToShop = (currentCustomer : Customer,shopLink:string)=>{
        if(checkCustomerBillingData(currentCustomer)){
            ToastService.showMessage(MessageType.Information,t('customerCenter:Text00001Lbl'))
            window.open(shopLink,'_self');
        }
        else {
            setPurchasedClicked(true);
            setCustomer(currentCustomer);
            reloadCustomer(currentCustomer);
            billingCardRef.current!.setRecord(currentCustomer);
            billingCardRef.current!.openPage(SystemOperation.Update);
            ToastService.showMessage(MessageType.Warning,t('customerCenter:Err00003Text'));
        }
    }

    const buildDashboardActions = () => {
        let actions: ICommandBarItemProps[] = [];
        if (sessionInfoContext.currentUser.mainUser)
        {  
            actions.push({
                key: "manageBillingData",
                text: t('customerCenter:DashboardCommandBar:manageBillingData')!,
                onClick: () => {
                    reloadCustomer(customer);
                    billingCardRef.current!.setRecord(customer);
                    billingCardRef.current!.openPage(SystemOperation.Update);
                }
            });
            actions.push({
                key: "manageUsers",
                text: t('customerCenter:DashboardCommandBar:manageUsers')!,
                onClick: () => {navigate("/app/customer/users")}
            });

            if(!getSubscriptionKPIText())
                actions.push({
                    key: "manageSubscription",
                    text: t('customerCenter:DashboardCommandBar:manageSubscription')!,
                    onClick: () => {
                        if(checkCustomerBillingData(customer))
                            window.open(shopLink,'_blank');
                        else{
                            billingCardRef.current!.setRecord(customer);
                            billingCardRef.current!.openPage(SystemOperation.Update);
                            ToastService.showMessage(MessageType.Warning,t('customerCenter:Err00003Text'));
                        }
                    }
                });
        }
            
        actions.push({
            key: "viewIncidents",
            text: t('customerCenter:DashboardCommandBar:viewIncidents')!,
            onClick: () => {navigate("/app/customer/incidents")}
        });

        return actions;
    }

    const buildDashboardFarActions = () => {
        let actions: ICommandBarItemProps[] = [];
        if (sessionInfoContext.currentUser.mainUser && getSubscriptionKPIText())
        {  
            actions.push({
                key: 'Purchase',
                text: t('customerCenter:DashboardCommandBar:purchase')!,
                id:"purchaseButton",
                onClick: ()=>{
                    handleRedirectToShop(customer,shopLink);
                }
            });
        }

        return actions;
    }

    const getCurrentTask = () : any | undefined => {
        let notCompletedTask = tasks.find(task => ! task.completed);

        return notCompletedTask;
    }

    const handleBannerReload = ()=>{
        customerService.getTasks(customerInfoContext.setupConfig.customerId)
            .then((result) => {
                setTasks(result);
            });
    }

    const handleNextClick = (currentTask: CustomerProgressChecklist)=>{
        switch(currentTask.code)
        {
            case "TENANT_INFO":
                setShowTenantInfo(true);
                break;
            case "TENANT_AUTH":
                setShowTenantDetailInfo(true);
                break;
            case "COMPANY_SETUP":
                setShowCompanySetup(true);
                break;
            case "MAIN_USER_SETUP":
                setShowMainUserSetup(true);
                break;
            case "USER_SETUP":
                setShowUserSetup(true);
                break;
            default:
                if (currentTask.actionType == ActionType.OpenPage)
                    navigate(currentTask.pageEndpoint);
                break;
        }
    }

    const getBannerRightContent = () : JSX.Element => {
        let currentTask = getCurrentTask();
        if(currentTask === undefined)
        {
            return (
                <DashboardMessageCard 
                    title={t('customerCenter:DashboardMessageCard:completeTitle')}
                    messageIcon="CheckMark"
                    showIcon={false}
                >
                    {t('customerCenter:DashboardMessageCard:completeContent')}
                </DashboardMessageCard>
            );
        }
        else if (! currentTask.enabled)
        {
            return (
                <DashboardMessageCard 
                    title={currentTask.translatedWaitingTitle}
                    messageIcon="CheckMark"
                    showIcon={false}
                    targetId="setupStepper"
                >
                    {currentTask.translatedWaitingMessage}
                </DashboardMessageCard>
            );
        }
        else if ((currentTask.forUserType === ApplicationForUserType.AdminUser && !sessionInfoContext.currentUser.mainUser))
            return ( 
                <DashboardMessageCard 
                    title={t('customerCenter:DashboardMessageCard:waitAdminTitle')}
                    messageIcon="Clock"
                    showIcon={false}
                    targetId="setupStepper"
                >
                    {
                        adminUser ? 
                            t(
                                'customerCenter:DashboardMessageCard:waitAdminContent1',
                                {
                                    taskDescription:currentTask.translatedDescription
                                }
                            ) : 
                            t(
                                'customerCenter:DashboardMessageCard:waitAdminContent2',
                                {
                                    taskDescription:currentTask.translatedDescription,
                                    username: adminUserName
                                }
                            )
                    }
                </DashboardMessageCard>
            );
        else
            return (
                <TaskProgressCard onSuccess={() => handleBannerReload()} tasks={tasks} onNextClick={handleNextClick} targetId="setupStepper"></TaskProgressCard>
            );
    }
    
    const updateCustomer = (rec: Customer) => {
        customerService.updateCustomer(rec)
        .then((resp)=>{
            customerService.getCustomer(customerInfoContext.setupConfig.customerId)
            .then((custResp) => {
                setCustomer(custResp);
                billingCardRef.current?.closePage();
                ToastService.showMessage(MessageType.Success, t('customerCenter:Text00002Lbl'));
                
                if (purchaseClicked)
                {
                    window.open(shopLink,'_blank');
                    setPurchasedClicked(false);
                }
            });
        })
        .catch((jqXHR)=>{
            reloadCustomer(rec);
            ToastService.showMessage(MessageType.Error, jqXHR.message);
        });
    }

    const reloadCustomer = (rec: Customer) => {
        customerService.getCustomer(customerInfoContext.setupConfig.customerId)
        .then((custResp) => {
            setCustomer(custResp);
            billingCardRef.current?.closePage();
            billingCardRef.current!.setRecord(custResp);
            billingCardRef.current!.openPage(SystemOperation.Update);
         });
    }

    const reloadCardData = (operationType: SystemOperation, rec: Customer)=>{
        if (!checkCustomerBillingData(rec)){
            reloadCustomer(rec);
            ToastService.showMessage(MessageType.Error, t('customerCenter:Err00004Text'));
        }
        else
        {
            if (!(rec.legalEntityCode === 'ESS'))
            {
                CommonFunctions.validateVat(rec.vat)
                .then((resp) => {
                    if (resp.risultato === "NON_VALIDA" || resp.risultato === "ERROR")
                    {
                        reloadCustomer(rec);
                        ToastService.showMessage(MessageType.Warning, t('customerCenter:Err00005Text')+": "+rec.vat);
                    }
                    else
                        updateCustomer(rec);
                })
                .catch((err) => {
                    reloadCustomer(rec);
                    ToastService.showMessage(MessageType.Error, err.message);
                })
            }
            else
                updateCustomer(rec);
        }
    }

    const checkCustomerBillingData = (current: Customer): boolean => {
        let statusValue = true;
        let customerData = {
            companyName: current.companyName,
            firstName: current.firstName,
            lastName: current.lastName,
            phoneNo: current.phoneNo,
            streetAddress: current.streetAddress,
            zipCode: current.zipCode,
            city: current.city,
            county: current.county,
            country: current.country,
            vat: current.vat,
            sdi: current.sdi,
            pecEmail: current.pecEmail,
            email: current.email,
            legalRepresentativeFirstName: current.legalRepresentativeFirstName,
            legalRepresentativeLastName: current.legalRepresentativeLastName,
        };
        
        Object.keys(customerData).forEach((value: string, index: number, array: string[]) => {
            const currentValue = Object.values(customerData)[index];
            
            if (currentValue === undefined || currentValue === null || currentValue === "") {
                statusValue = false;
                
                if (current.legalEntityCode === 'ESS') {
                    if (value === "sdi" || value === "vat" || value === "pecEmail") {
                        statusValue = true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        });
        
        return statusValue;
    };

    const getSubscriptionKPIText = () : boolean => {
        if (customer == undefined)
            return false;

        if (customer.isTrial === CustomerTrialStatus.On)
            return true;
        return false;
    }

    const handleSaveLead = (lead:Lead)=>{

        if(lead.companyName !=="" && lead.phone !=="" && lead.email !==""){
            setSaveInProgress(true);
            let currentLead = lead;
            currentLead.origin = "WEB";
            customerService.saveCustomerLead(currentLead)
                .then((resp)=>{
                    setSaveInProgress(false);
                    setShowLeadCard(false);
                    ToastService.showMessage(MessageType.Success, t('customerCenter:Text00002Lbl'));
                })
                .catch((jqXHR)=>{
                    setSaveInProgress(false);
                    setShowLeadCard(false);
                    ToastService.showMessage(MessageType.Error, jqXHR.message);
                })
        }
        else
            ToastService.showMessage(MessageType.Warning, t('customerCenter:Text00003Lbl'));
    }

    const handleTenantStepSave = () => {
        setSaveInProgress(true);
        customerService.completeSetupStep(customerInfoContext.setupConfig.customerId, 1).then((resp) => {
            customerService.getTasks(customerInfoContext.setupConfig.customerId)
                .then((result) => {
                    setTasks(result);
                    setShowTenantInfo(false);
                    setSaveInProgress(false);
                });
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, err);
            setSaveInProgress(false);
        })
    };

    const handleTenantDetailStepSave = (tenantInfo: TenantInformationSetup) => {    
        //setTenantInformation(tenantInfo);
        setSaveInProgress(true);
        let currentData: TenantInformationSetup = JSON.parse(JSON.stringify(tenantInfo));
        currentData.tenantId = customerInfoContext.setupConfig.tenantId;

        if (! customerInfoContext.setupConfig.hasTenant)
            currentData.domain += ".onmicrosoft.com";

        customerService.saveSetupStepData(customerInfoContext.setupConfig.customerId, 2, currentData).then((resp) => {
            customerService.completeSetupStep(customerInfoContext.setupConfig.customerId, 2).then((resp) => {
                customerInfoContext.loadDefaultDomain();
                customerService.getTasks(customerInfoContext.setupConfig.customerId)
                .then((result) => {
                    setTasks(result);
                    setShowTenantDetailInfo(false);
                    setSaveInProgress(false);
                });
            })
            .catch((err) => {
                console.log(err);
                ToastService.showMessage(MessageType.Error, err.message);
                setSaveInProgress(false);
            })
        })
        .catch((err) => {
            console.log(err);
            ToastService.showMessage(MessageType.Error, err.errorMessage);
            setSaveInProgress(false);
        });
    };

    const handleCompanySetupStepSave = (companies: CustomerCompany[]) => {        
        setSaveInProgress(true);

        customerService.saveSetupStepData(customerInfoContext.setupConfig.customerId, 3, companies).then((resp) => {
            customerService.completeSetupStep(customerInfoContext.setupConfig.customerId, 3).then((resp) => {
                customerService.getTasks(customerInfoContext.setupConfig.customerId)
                .then((result) => {
                    setTasks(result);
                    setShowCompanySetup(false);
                    setSaveInProgress(false);
                });
            })
            .catch((err) => {
                ToastService.showMessage(MessageType.Error, err.message);
                setSaveInProgress(false);
            })
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, err.errorMessage);
            setSaveInProgress(false);
        });
    };

    const handleMainUserStep = (user: SystemUser) => {
        setSaveInProgress(true);
        let userInfo = new UserInformationSetup();
        userInfo.userInformation = user;
        userInfo.userInformation.mainUser = true;
        if (! customerInfoContext.setupConfig.hasTenant)
            userInfo.userInformation.userPrincipleName = userInfo.userInformation.userPrincipleName.split('@')[0] +  "@" + customerInfoContext.defaultDomain.tenantDomain;
        userInfo.licences = [];
        userInfo.userInformation.customerId = customerInfoContext.setupConfig.customerId;

        customerService.updateMainUserInformations(customerInfoContext.setupConfig.customerId, userInfo).then((resp) => {
            customerService.completeTask(getCurrentTask()!)
                .then((resp) => {
                    customerService.getTasks(customerInfoContext.setupConfig.customerId)
                        .then((result) => {
                            customerInfoContext.reloadSetupConfig();
                            setTasks(result);
                            setShowMainUserSetup(false);
                            setSaveInProgress(false);
                        })
                        .catch((err) => {
                            ToastService.showMessage(MessageType.Error, err);
                            setSaveInProgress(false);
                        });
                })
                .catch((err) => {
                    ToastService.showMessage(MessageType.Error, err);
                    setSaveInProgress(false);
                })
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, err);
            setSaveInProgress(false);
        });
    }

    const handleUserStep = () => {
        setSaveInProgress(true);
        customerService.completeSetupStep(customerInfoContext.setupConfig.customerId, 4).then((resp) => {
            customerService.getTasks(customerInfoContext.setupConfig.customerId)
                .then((result) => {
                    customerInfoContext.reloadSetupConfig();
                    setTasks(result);
                    setShowUserSetup(false);
                    setSaveInProgress(false);
                });
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, err.message);
            setSaveInProgress(false);
        });
    }

    const updateHideCustomerGuideTour = (userSettings: UserSettings) => {
        setSaveInProgress(true);
        customerService.updateHideCustomerGuideTour(userSettings).then((resp) => {
            setSaveInProgress(false);
        })
        .catch((err) => {
            ToastService.showMessage(MessageType.Error, err.message);
            setSaveInProgress(false);
        });
    }

    const createGuideTourElement = (elementIndex:number) => {
        setBubbleVisible(false);
        setGuideTourElement(<></>)

        const guideTourEntry:ITourEntry[]=[
            {
                title: t('customerCenter:CommonGuideTourDialog:guideTourEntry:1:title'),
                description: t('customerCenter:CommonGuideTourDialog:guideTourEntry:1:description'),
                targetId: "setupStepper",
                direction:DirectionalHint.rightCenter,
                adminRequired: false
            },
            {
                title: t('customerCenter:CommonGuideTourDialog:guideTourEntry:2:title'),
                description: t('customerCenter:CommonGuideTourDialog:guideTourEntry:2:description'),
                targetId: "purchaseButton",
                direction:DirectionalHint.bottomAutoEdge,
                adminRequired:true
            },
            /*{
                title: "Terzo passo",
                description: "Completando il task corrispondente, in questa sezione poitrai visualizzare i tuoi ambienti Business Central dedicati.",
                targetId: "environmentsSection",
                direction:DirectionalHint.leftCenter,
                adminRequired:false
            },
            {
                title: "Quarto passo",
                description: "Dopo aver concluso le operazioni del profilo microsoft in questa sezione troverai le app microsoft 365 a te dedicate.",
                targetId: "appsSection",
                canScroll: true,
                direction:DirectionalHint.leftCenter,
                adminRequired:false
            },
            {
                title: "Quinto passo",
                description: "In questa sezione trovi una lista di azioni e pagine navigabilie che ti permettono di gestire al meglio le tue attività.",
                targetId: "actionBar",
                canScroll: true,
                direction:DirectionalHint.bottomAutoEdge,
                adminRequired:true
            }*/
        ]

        let totalEntryCount:number = 0;
        let currentTotalGuideEntry : ITourEntry[] = [];
        let currentIndex:number = 0;

        if(sessionInfoContext.currentUser.mainUser){
            currentTotalGuideEntry = guideTourEntry;
            totalEntryCount = guideTourEntry.length;
        }
        else{
            currentTotalGuideEntry = guideTourEntry.filter(entry => !entry.adminRequired);
            totalEntryCount = guideTourEntry.filter(entry => !entry.adminRequired).length;
        }

        currentIndex = elementIndex + 1;
        let currentEntry = currentTotalGuideEntry[elementIndex];

        if(currentEntry !== undefined){
            if (currentEntry.canScroll && currentEntry.canScroll !== undefined){
                let element = document.getElementById(currentEntry.targetId);
                element?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            }

            let primaryButtonLabel:string =t('customerCenter:CommonGuideTourDialog:tourNextButtonText');
            if(currentIndex === totalEntryCount)
                primaryButtonLabel = t('customerCenter:CommonGuideTourDialog:tourFinishButtonText');

            if(currentEntry.adminRequired)
                if(sessionInfoContext.currentUser.mainUser){
                    if(timerId !== 0)
                        window.clearTimeout(timerId);
                    
                    setTimerId(window.setTimeout(() => {
                        setGuideTourElement(
                            <CommonGuideTour
                                customStyle={{zIndex: "800000 !important"}}
                                currentEntry={currentEntry} 
                                currentIndex={currentIndex}
                                totalSteps={totalEntryCount}
                                primaryButtonLabel={primaryButtonLabel}
                                secondaryButtonLabel={t('customerCenter:CommonGuideTourDialog:tourDefaultButtonText')} 
                                onNext={()=>{createGuideTourElement(elementIndex+1)}}  
                                onCancel={()=>{setGuideTourElement(<></>)}} 
                                onDismiss={()=>{setGuideTourElement(<></>)}} 
                            />
                        )
                    }, 50));
                }
                else
                    setGuideTourElement(<></>)
            else{
                if(timerId !== 0)
                    window.clearTimeout(timerId);
                
                setTimerId(window.setTimeout(() => {
                    setGuideTourElement(
                        <CommonGuideTour
                            customStyle={{zIndex: "800000 !important"}}
                            currentEntry={currentEntry} 
                            currentIndex={currentIndex}
                            totalSteps={totalEntryCount}
                            primaryButtonLabel={primaryButtonLabel}
                            secondaryButtonLabel={t('customerCenter:CommonGuideTourDialog:tourDefaultButtonText')} 
                            onNext={()=>{createGuideTourElement(elementIndex+1)}}  
                            onCancel={()=>{setGuideTourElement(<></>)}} 
                            onDismiss={()=>{setGuideTourElement(<></>)}} 
                        />
                    )
                }, 50));
            }
            
            setBubbleVisible(true);
        }
        else{
            window.scrollTo(0,0)
            setGuideTourElement(<></>)
            setBubbleVisible(true);
        }
    }

    if (! SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage} />
    else
    {
        return (
            <>
                <DashboardContainer>
                    <DashboardCommandBar 
                        items={buildDashboardActions()} 
                        farItems={buildDashboardFarActions()} 
                    />
                    <Banner leftContent={
                        <UserProfileCard />
                    } 
                    rightContent={
                        getBannerRightContent()
                    } />
                    <DashboardContentRow id="1">
                        <Stack key={"dashboardVerticalWidgets"} styles={{
                            root: {
                                width: '40%'
                            }
                        }} tokens={
                            {
                                childrenGap: 20
                            }
                        }>
                            <DashboardContentColumn>
                                <DashboardContentContainer title={t('customerCenter:DashboardContentContainer:envTitle')} name="environmentsContainer">
                                        <CustomerEnvironmentList items={environments} />
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                            <DashboardContentColumn>
                                <DashboardContentContainer title={t('customerCenter:DashboardContentContainer:appTitle')} name="appsContainer">
                                    <Stack id="appsSection" horizontal tokens={{
                                        childrenGap: 10
                                    }}>
                                        <CueBox name="Teams" isIconBox title="Teams" iconName="TeamsLogo" link={"https://aka.ms/mstfw?login_hint_safe=" + sessionInfoContext.currentUser.email} />
                                        <CueBox name="Outlook" isIconBox title="Outlook" iconName="OutlookLogo" link={"https://outlook.office365.com/mail/inbox"} />
                                        <CueBox name="OneDrive" isIconBox title="OneDrive" iconName="OneDriveLogo" link={"https://onedrive.live.com/about/it-it/signin"} />
                                        <CueBox name="PowerBi" isIconBox title="PowerBI" iconName="PowerBILogo16" link={"https://app.powerbi.com/home"} />
                                    </Stack>
                                </DashboardContentContainer>
                            </DashboardContentColumn>
                        </Stack>
                    </DashboardContentRow>
                </DashboardContainer>
                <CommonGuideTourDialog 
                    showDialog={showGuideTourDialog}
                    subText={t('customerCenter:CommonGuideTourDialog:subtext')!} 
                    onSuccess={()=>{
                        updateHideCustomerGuideTour(sessionInfoContext.currentUserSettings);
                        setShowGuideTourDialog(false);
                        createGuideTourElement(0);
                    }}
                    onDismiss={()=>{
                        updateHideCustomerGuideTour(sessionInfoContext.currentUserSettings);
                        setShowGuideTourDialog(false)}
                    }
                />
                <CustomerCreateLeadCard showDialog={showLeadCard} 
                                            title={t('customerCenter:CustomerCreateLeadCard:title')}
                                            note={t('customerCenter:CustomerCreateLeadCard:note')!}
                                            saveInProgress={saveInProgress}
                                            onSuccessModal={handleSaveLead}

                                            onCancelModal={()=>{
                                                setShowLeadCard(false);
                                            }} />
                <CustomerBillingInfoCard onSuccess={reloadCardData} ref={billingCardRef} record={customer} />
                <CustomerTenantSetupBox showModal={showTenantInfo} saveInProgress={saveInProgress} onDismiss={handleTenantStepSave} onCancel={() => setShowTenantInfo(false)} />
                <CustomerTenantDetailBox tenantInfo={tenantInformation} showModal={showTenantDetailInfo} saveInProgress={saveInProgress} onDismiss={handleTenantDetailStepSave} onCancel={() => setShowTenantDetailInfo(false)} />
                <CustomerSetupCompanyList onDismiss={handleCompanySetupStepSave} showModal={showCompanySetup} saveInProgress={saveInProgress} onCancel={() => setShowCompanySetup(false)} />
                <CustomerUserSetupList showModal={showUserSetup} onDismiss={handleUserStep} saveInProgress={saveInProgress} onCancel={() => setShowUserSetup(false)} />
                <CustomerMainUserSetupCard showModal={showMainUserSetup} saveInProgress={saveInProgress} onDismiss={handleMainUserStep} onCancel={() => setShowMainUserSetup(false)} />
                <CommonVideoModal showModal={showVideoModal} key={"CustomerVideoModal-Share"} onDismiss={(isVideoPlayed:boolean)=>{onCloseVideoModal(isVideoPlayed)}} resource={videoResource!} />
                {bubbleVisible && guideTourElement}
            </>
        );
    }
}
