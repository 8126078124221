import React, { forwardRef} from "react";
import {SystemFieldType, SystemPageType} from "../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { CardField } from "../../Common/Template/Card/CardField";

export const AdminCustomerCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} 
                       title={"Scheda cliente "} 
                       endpoint="odata/customer"
                       ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="id" type={SystemFieldType.Number} label="Id" readonly/>
                <CardField name="companyName" type={SystemFieldType.String} label="Nome società" readonly/>
                <CardField name="isTrial" type={SystemFieldType.Option} label="Cliente Trial" cmbOptions={[
                    {key: 0, text: "Empty"},
                    {key: 1, text: "On"},
                    {key: 2, text: "Off"},
                    {key: 3, text: "Converted"},
                ]} />
                <CardField name="firstName" type={SystemFieldType.String} label="Nome" readonly/>
                <CardField name="lastName" type={SystemFieldType.String} label="Cognome" readonly/>
                <CardField name="email" type={SystemFieldType.String} label="Email" readonly/>
            </CardGroup>
            <CardGroup name="localization" label="Localizzazione" isOpen={true}>
                <CardField label="Legal Entity" name="legalEntityCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name" />
                <CardField label="Distributore" name="distributorCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name" />
                <CardField label="Partner" name="partnerId" type={SystemFieldType.Number} hasTableRelation tableRelationEndpoint="/odata/partner" tableRelationKey="id" tableRelationField="name" />
                <CardField label="Partner Business Contract" name="partnerBusinessContractCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner/business/contract" tableRelationKey="code" tableRelationField="description" />
                <CardField label="Business line" name="businessLineCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/business/line" tableRelationKey="code" tableRelationField="description" />
                <CardField label="Lingua" name="languageCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/language" tableRelationKey="code" tableRelationField="name" />
            </CardGroup>
            <CardGroup name="update" label="Update" isOpen={true}>
                <CardField name="enabled" type={SystemFieldType.Boolean} label="Abilitato" />
                <CardField name="updateFrom" type={SystemFieldType.Date} label="Data aggiornamento" />
                <CardField name="updateNeeded" type={SystemFieldType.Boolean} label="Aggiornamento necessario" readonly />
                <CardField name="forceUpdate" type={SystemFieldType.Boolean} label="Aggiornamento forzato" />
            </CardGroup>
            <CardGroup name="customerDetail" label="Dettaglio cliente" isOpen={false}>
                <CardField name="streetAddress" type={SystemFieldType.String} label="Indirizzo" readonly/>
                <CardField name="phoneNo" type={SystemFieldType.String} label="Nr. telefono" readonly/>
                <CardField name="city" type={SystemFieldType.String} label="Città" readonly/>
                <CardField name="pecEmail" type={SystemFieldType.String} label="Email PEC" readonly/>
                <CardField name="zipCode" type={SystemFieldType.String} label="CAP" readonly/>
                <CardField name="vat" type={SystemFieldType.String} label="Partita IVA" readonly/>
                <CardField name="county" type={SystemFieldType.String} label="Provincia" readonly/>
                <CardField name="sdi" type={SystemFieldType.String} label="SDI" readonly/>
                <CardField name="country" type={SystemFieldType.String} label="Nazione" readonly/>
                <CardField name="expectedLiveDate" type={SystemFieldType.Date} label="Data aspettativa live" />
            </CardGroup> 
            <CardGroup name="connection" label="Connessioni" isOpen={false}>
                <CardField name="shopCustomerId" type={SystemFieldType.String} label="Id shop" readonly/>
                <CardField name="erpCustomerNo" type={SystemFieldType.String} label="Nr. cliente ERP" readonly/>
                <CardField name="crmAccountId" type={SystemFieldType.String} label="Id account CRM" readonly/>
                <CardField name="ignoreCRMBulkEmail" type={SystemFieldType.Boolean} label="Ignora CRM bulk email" />
            </CardGroup>
            <CardGroup name="lossRisk" label="Rischio Perdita" isOpen={false}>
                <CardField name="cancellationRisk" type={SystemFieldType.Boolean} label="Rischio Disdetta" />
                <CardField name="lossCauseCode" type={SystemFieldType.String} label="Causa Perdita" hasTableRelation tableRelationEndpoint="odata/crm/losscause" tableRelationKey="code" tableRelationField="description" />
            </CardGroup>
            <CardGroup name="interaction" label="Interazioni" isOpen={false}>
                <CardField name="progressChecklistStatus" type={SystemFieldType.String} label="Stato Progress Checklist" readonly />
                <CardField name="weekInteractionCount" type={SystemFieldType.Number} label="Numero Interazioni Settimanali" readonly />
                <CardField name="monthInteractionCount" type={SystemFieldType.Number} label="Numero Interazioni Mensili" readonly />
                <CardField name="interactionCount" type={SystemFieldType.Number} label="Numero Interazioni Totali" readonly />
            </CardGroup>
        </PageContainer>
    );
});