import { rejects } from "assert";
import { resolve } from "path";
import React, { createContext, useContext, useState } from "react";
import { SessionStateContext } from "../../Core/State/SessionStateContext";
import { Partner } from "../../Model/PartnerModels";
import { PartnerService } from "./PartnerService";

export interface IPartnerInformationContext {
    partner: Partner;
}

export interface IPartnerInformationContextProviderProps {
    partner: Partner;
}

let defaultValue : IPartnerInformationContext = {} as IPartnerInformationContext;
defaultValue.partner = new Partner();

export const PartnerInformationContext = createContext<IPartnerInformationContext>(defaultValue);
export const PartnerInformationContextProvider : React.FC<IPartnerInformationContextProviderProps> = (props) => {
    const [partner, setPartner] = useState(props.partner);
    
    const sessionStateContext = useContext(SessionStateContext);
    const partnerService = new PartnerService();

    return (
        <PartnerInformationContext.Provider value={{
            partner: partner
        }}>
            {props.children}
        </PartnerInformationContext.Provider>
    );
}