import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import {SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminBusinessCentralSetupCard } from "../AdminBusinessCentralSetupCard/AdminBusinessCentralSetupCard";

export const AdminBusinessCentralSetupList : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const [loaderMessage, setLoaderMessage] = useState("");
    const cardRef = useRef<PageContainerBase>(null);
    const adminService = new AdminService();
    const navigate = useNavigate();

    const syncBCNProcessTables = () => {
        adminService.syncBCNProcessTables().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success, "Sincronizzazione tabelle process module completata con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error, jqXHR.message);
        })
    }

    const syncBCNProcessTranslations = () => {
        adminService.syncBCNProcessTranslations().then((resp:string)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success, "Sincronizzazione traduzioni tabelle process module completata con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error, jqXHR.message);
        })
    }

    const syncKnowledgeArticles = () => {
        adminService.syncKnowledgeArticles().then(()=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success, "Sincronizzazione knowledge articles completata con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error, jqXHR.message);
        })
    }

    const initKnowledgeArticles = () => {
        adminService.initKnowledgeArticles().then(()=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Success, "Inizializzazione knowledge articles completata con successo");
        })
        .catch((jqXHR)=>{
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error, jqXHR.message);
        })
    }
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint="/odata/businesscentral/setup" pageType={SystemPageType.List} title="Business Central setup" backAllowed insertAllowed updateAllowed cardRef={cardRef}>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry name="bcDefaultApp" label="Default app" iconName="AppIconDefault" onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/businessCentralDefaultApp');
                                }}>
                            </ActionEntry>
                            <ActionEntry name="bcnProcessTest" label="Process test" iconName="KnowledgeArticle" onClick={(req:any)=>{
                                    navigate('/app/admin/administrative/bcnProcessTest');
                                }}>
                            </ActionEntry>
                            <ActionEntry name="customerQueuedStatus" label="Stato coda clienti" iconName="BuildQueue" onClick={(req:any)=>{
                                    navigate('/app/admin/related/customerQueuedStatus');
                                }}>
                            </ActionEntry>
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="SyncProcessModuleTables" label="Sincronizza tabelle process module" iconName="SyncOccurence" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.ToLoad)
                                setLoaderMessage("Sincronizzazione tabelle process module in corso, Attendere...");
                                syncBCNProcessTables();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncProcessModuleTranslations" label="Sincronizza traduzioni tabelle process module" iconName="Translate" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.ToLoad)
                                setLoaderMessage("Sincronizzazione traduzioni tabelle process module in corso, Attendere...");
                                syncBCNProcessTranslations();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="SyncKnowledgeArticles" label="Sincronizza knowledge articles" iconName="KnowledgeArticle" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.ToLoad)
                                setLoaderMessage("Sincronizzazione knowledge articles in corso, Attendere...");
                                syncKnowledgeArticles();
                                }}>
                            </ActionEntry>
                            <ActionEntry name="InitKnowledgeArticles" label="Inizializza knowledge articles" iconName="Sort" onClick={(req:any)=>{
                                setDataStatus(SystemDataLoadingStatus.ToLoad);
                                setLoaderMessage("Inizializzazione knowledge articles in corso, Attendere...");
                                initKnowledgeArticles();
                            }}></ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>                    
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry fieldName="primaryKey" name="Chiave primaria" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="appName" name="Nome app" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="grantType" name="Tipo grant" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="clientId" name="Id client" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="clientSecret" name="Secret" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="scope" name="Scope" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="authCode" name="Auth code" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="automationRedirectUrl" name="Automation redirect url" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="automationGrantType" name="Automation grant type" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="automationClientId" name="Automation client id" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="automationClientSecret" name="Automation client secret" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="automationScope" name="Automation scope" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="baseAutomationEndpoint" name="Base automation endpoint" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="automationTemplateCompanyName" name="Automation template company name" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="environmentPollingWaitTime" name="Environment PollingWaitTime" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="baseDemoEndpoint" name="Base demo endpoint" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="demoTenantId" name="Demo tenant id" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="demoEnvironmentName" name="Demo environment name" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="demoCompanyName" name="Demo company name" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="customerNotificationSetupType" name="Tipo setup notifiche" minWidth={100} maxWidth={200} onRender={(item: any, index, column)=>{
                            if (item.customerNotificationSetupType === 0)
                                return "Email"
                            else if (item.customerNotificationSetupType === 1)
                                return "Chat"
                        }} />
                        <ListHeaderEntry fieldName="customerNotificationSetupCode" name="Codice setup notifiche" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="customerNotificationTemplateCode" name="Codice template notifiche" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="customerNotificationGroupCode" name="Codice gruppo notifiche" minWidth={100} maxWidth={200} />
                        <ListHeaderEntry fieldName="adminNotificationName" name="Nome notifiche admin" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="adminNotificationEmail" name="Email notifiche admin" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry fieldName="scheduleCustomerUpdateOnInsertDSYVersion" name="Schedula aggiornamento clienti all'inserimento versione DSY" minWidth={80} maxWidth={100} />
                        <ListHeaderEntry fieldName="operationQueuesDisabled" name="Code aggiornamento clienti disabilitate" minWidth={80} maxWidth={100} />
                        <ListHeaderEntry fieldName="customerUpdateMinHour" name="Ora minima aggiornamento" minWidth={80} maxWidth={100} />
                        <ListHeaderEntry fieldName="customerUpdateContemporaryRecords" name="Clienti contemporanei" minWidth={80} maxWidth={100} />
                        <ListHeaderEntry fieldName="customerUpdateDelaySeconds" name="Ritardo aggiornamento (in secondi)" minWidth={80} maxWidth={100} />
                        <ListHeaderEntry fieldName="environmentUpgradeDelayDays" name="Ritardo aggiornamento ambienti (in giorni)" minWidth={80} maxWidth={100} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminBusinessCentralSetupCard ref={cardRef} />
            </>
        );
}