import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminEmailSetupCard } from "../AdminEmailSetupCard/AdminEmailSetupCard";

export const AdminEmailSetupList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    const navigate = useNavigate();

    return (
        <>
            <PageContainer endpoint="/odata/email/setup" pageType={SystemPageType.List} title="Email Setup" backAllowed insertAllowed updateAllowed deleteAllowed cardRef={cardRef}>               
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry iconName="Mail" name="OpenEmailTemplate" label="Template" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/emailTemplate");
                        }}>
                        </ActionEntry>
                        <ActionEntry iconName="MailTentative" name="OpenEmailLog" label="Log" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/emailLog");
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>                    
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Legal entity" fieldName="legalEntityCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Distributore" fieldName="distributorCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Partner business contract" fieldName="partnerBusinessContractCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Indirizzo email default" fieldName="defaultEmailAddress" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="default password email" fieldName="defaultEmailPassword" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Default Host" fieldName="defaultHost" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Porta di default" fieldName="defaultPort" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="SSL Abilitato" fieldName="enableSsl" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice template password utente" fieldName="userPasswordTemplateCode" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminEmailSetupCard ref={cardRef}/>
        </>
    );
}