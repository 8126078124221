import { useContext, useEffect, useRef, useState } from "react";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";
import { Lead, Partner } from "../../../Model/PartnerModels";
import { SystemActionCategory, SystemDataLoadingStatus, SystemOperation, SystemPageType } from "../../../Model/SystemModels";
import { CommonFunctions } from "../../../ApplicationCode/Common/CommonFunctions";
import { useNavigate } from "react-router-dom";
import { HttpHelper } from "../../../Core/Http/HttpHelper";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { SystemCore } from "../../../Core/System/SystemCore";
import { Loader } from "../../Common/Loader/Loader";
import { ContainerType, PageDefinitionContainer } from "../../Common/Template/PageDefinitionContainer";
import { ActionArea } from "../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../Common/Template/List/ListHeaderEntry";
import { PartnerLeadCard } from "../PartnerLeadCard/PartnerLeadCard";
import { CommonTemporaryLeadDataModal } from "../../Common/CommonTemporaryLeadDataModal/CommonTemporaryLeadDataModal";

export const PartnerLeadList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);
    // const [showBookingModal, setShowBookingModal] = useState(false);
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [message,setMessage] = useState("Stiamo caricando la lista richiesta ...");
    const [endpoint,setEndpoint] = useState<string>("");
    const [listTitle,setListTitle] = useState<string>("");
    const [partner, setPartner] = useState<Partner>(new Partner());
    const [showLeadDialog, setShowLeadDialog] = useState<boolean>(false);
    let sessionStateContext: ISessionStateContext = useContext(SessionStateContext);

    const commonFunctions = new CommonFunctions();
    const navigate = useNavigate();

    const buildListEndpoint = () =>{
        setDataStatus(SystemDataLoadingStatus.Loading)
        const workflowCode = HttpHelper.getParameter('code');
        const workflowCodeName = HttpHelper.getParameter('codeName');
        const leadFilter = HttpHelper.getParameter('leadFilter');
        const activeLeads = HttpHelper.getParameter('activeLeads');
        let buildedEndpoint = "/odata/lead";
        let currentTitle = "Lista lead";
        commonFunctions.getPartnerId(sessionStateContext.currentUser.email).then((resp) => {
            setPartner(resp);
            if(resp.id === 1){
                if(workflowCode != null && workflowCode != ""){
                    currentTitle += " ("+workflowCodeName+")";
                    buildedEndpoint += '?$filter=(email gt \'\') and statusCode eq \''+workflowCode+"\'&$orderby=createdOn desc";
                }
                else if(activeLeads != null && activeLeads != ""){
                    currentTitle += " attive";
                    buildedEndpoint += '?$filter=(email gt \'\') and contains(partnerBusinessContractCode,\'SO\') and (statusCode eq \'80\' or statusCode eq \'60\' or statusCode eq \'50\' or statusCode eq \'20\' or statusCode eq \'02\' or statusCode eq \'00\') &$orderby=createdOn desc';
                }
                else if((leadFilter !== null) && (leadFilter !== "")){
                    switch(leadFilter){
                        case "leadWin":
                            currentTitle += " (Win)";
                            buildedEndpoint += '?$filter=(email gt \'\') and statusCode eq \'95\' or finalOutcome eq \'Win\'&$orderby=systemCreationDate desc';
                        break;
                        case "leadLost":
                            currentTitle += " (Lost)";
                            buildedEndpoint += '?$filter=(email gt \'\') and statusCode eq \'96\' or finalOutcome eq \'Lost\'&$orderby=systemCreationDate desc';
                        break;
                        case "leadUnqualified":
                            currentTitle += " (Unqualified)";
                            buildedEndpoint += '?$filter=(email gt \'\') and statusCode eq \'97\' or finalOutcome eq \'Unqualified\'&$orderby=systemCreationDate desc';
                        break;
                    }
                }
                else{
                    currentTitle += " totale";
                    buildedEndpoint += '?filter=(email gt \'\')&$orderby=systemCreationDate desc';
                }
            }
            else {
                if(workflowCode != null && workflowCode != ""){
                    currentTitle += " ("+workflowCodeName+")";
                    buildedEndpoint += '?$filter=(email gt \'\') and statusCode eq \''+workflowCode+"\' and partnerId eq "+resp.id+"&$orderby=createdOn desc";
                }
                else if(activeLeads != null && activeLeads != ""){
                    currentTitle += " attive";
                    buildedEndpoint += '?$filter=(email gt \'\') and partnerId eq '+resp.id+' and (statusCode eq \'80\' or statusCode eq \'60\' or statusCode eq \'50\' or statusCode eq \'20\' or statusCode eq \'02\' or statusCode eq \'00\')&$orderby=createdOn desc';
                }
                else{
                    currentTitle += " totale";
                    buildedEndpoint += '?$filter=(email gt \'\') and partnerId eq '+resp.id+'&$orderby=systemCreationDate desc';
                }
            }

            setEndpoint(buildedEndpoint);
            setListTitle(currentTitle);
            setDataStatus(SystemDataLoadingStatus.Loaded)
        })
        .catch((resp) => {
            setEndpoint("");
            setListTitle("Lista lead non disponibile");
            setDataStatus(SystemDataLoadingStatus.Loaded)
            ToastService.showMessage(MessageType.Error,"Impossibile visualizzare la lista delle lead, contattare l'assistenza");
        });
    }
 
    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            buildListEndpoint();
        }
    },[dataStatus]);

    const setNewRecord = ()=>{
        let newRecord = new Lead();
        newRecord.partnerId = partner.id;
        newRecord.marketingQualificationCarriedOut = true;
        cardRef.current?.setRecord(newRecord);
        cardRef.current?.openPage(SystemOperation.Insert);
    }

    const sendPartnerReferralLink = (tempLead: Lead) => {
        const promise = commonFunctions.sendPartnerReferralLink(partner.id, tempLead)
        .then(() => {
            setShowLeadDialog(false);
        })
        .catch((err) => {
            console.log(err.message);
        })
        
        ToastService.showPromiseMessage(promise, "Invio mail in corso...", "Mail inviata correttamente all'indirizzo: "+tempLead.email, "C'è stato un errore nell'invio della mail");
    }
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={message}></Loader>;
    else
    return (
        <>
            <PageContainer onNewRecord={setNewRecord} endpoint={endpoint} pageType={SystemPageType.List} title={listTitle} cardRef={cardRef} backAllowed insertAllowed updateAllowed>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry name="OpenShopAddress" label="Configura offerta" iconName="ConfigurationSolid" runOnRec isPromoted onClick={(req:any)=>{
                                window.open(process.env.REACT_APP_SHOP_URL);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenWorkflowList" label="Workflow Log" iconName="BacklogList" runOnRec onClick={(req:any)=>{
                                navigate("/app/partner/datasecurity/log?entityName=Lead&recordId="+req.systemId);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenFrontendLogEntryList" label="Frontend Log Entry" iconName="BacklogList" runOnRec onClick={(req:any)=>{
                                navigate("/app/admin/administrative/demolog?Id="+req.linkUserId+"&leadName="+req.companyName);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenEmailList" label="Email Inviate" iconName="BacklogList" runOnRec onClick={(req: Lead)=>{
                                navigate('/app/partner/administrative/emailLog?email='+req.email +"&name="+req.companyName);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenLossRiskLine" label="Righe Rischio Perdita" iconName="AlertSolid" runOnRec onClick={(req:any)=>{
                                 navigate("/app/admin/related/lossRiskLine?sourceTable=Lead&sourceId="+req.id+"");
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenInteractionList" label="Interazioni CRM" iconName="MessageFill" runOnRec onClick={(req:any)=>{
                                 navigate("/app/admin/related/crmInteraction?leadId="+req.id+"");
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenQualifiedAnswer" label="Risposte qualifica" iconName="SurveyQuestions" runOnRec onClick={(req:any)=>{
                                navigate("/app/admin/related/leadQualifiedAnswer?leadId="+req.id+"&leadName="+req.companyName);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenBookingLeadAvailability" label="Prenota demo cliente" iconName="JoinOnlineMeeting" isPromoted runOnRec onClick={(req:any)=>{
                                navigate("/app/partner/bookingAvailability?source=Partner&mode=lead&leadId="+req.id+"&leadName="+req.companyName);
                            }}>
                        </ActionEntry>
                        <ActionEntry name="OpenBookingInternalAvailability" label="Prenota meeting interno" iconName="AddOnlineMeeting" isPromoted runOnRec onClick={(req:any)=>{
                                navigate("/app/partner/bookingAvailability?source=Partner&mode=internal&leadId="+req.id+"&leadName="+req.companyName);
                            }}>
                        </ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry name="SendReferralLinkToLead" label="Invia link di registrazione via mail alla Lead" iconName="Send" runOnRec onClick={(req:any) => {
                                const promise = commonFunctions.sendReferralLinkToLead(req.id)
                                .then(() => {})
                                .catch((err) => {
                                    console.log(err.message);
                                })
                                
                                ToastService.showPromiseMessage(promise, "Invio mail in corso...", "Mail inviata correttamente all'indirizzo: "+req.email, "C'è stato un errore nell'invio della mail");
                            }}>                            
                        </ActionEntry>
                        <ActionEntry name="SendPartnerReferralLink" label="Invia link di registrazione via mail a nuovo indirizzo email" iconName="Send" isPromoted onClick={(req:any) => {
                                setShowLeadDialog(true);
                            }}>                            
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Nome contatto" fieldName="firstname" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name="Cognome contatto" fieldName="lastname" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name="Nome società" fieldName="companyName" minWidth={200} maxWidth={250} />
                    <ListHeaderEntry name="E-Mail" fieldName="email" minWidth={300} maxWidth={350} />
                    <ListHeaderEntry name="Telefono" fieldName="phone" minWidth={150} maxWidth={200} />
                    <ListHeaderEntry name="Data Creazione" fieldName="createdOn" minWidth={200} maxWidth={250} onRender={(item: any, index, column)=>{
                        if (item.createdOn !== undefined)
                        {
                            const weekday = ["Domenica","Lunedì","Martedì","Mercoledì","Giovedì","Venerdì","Sabato"];
                            let convert: Date = new Date(item.createdOn);
                            let day, month, year, hours, minutes;
                            
                            if (convert.getFullYear() === 1)
                                return undefined;
                                
                            if (convert !== undefined)
                            {
                                day = convert.getDate();
                                month = convert.getMonth() + 1;
                                year = convert.getFullYear();
                                hours = ("0" + convert.getHours()).slice(-2);
                                minutes = ("0" + convert.getMinutes()).slice(-2);
                                return weekday[convert.getDay()] + " " + day + "/" + month + "/" + year + " " + hours + ":" + minutes;
                            }
                        }
                        
                        return undefined;
                    }} />
                    <ListHeaderEntry name="Data meeting interno" fieldName="internalMeetingDate" minWidth={200} maxWidth={250} onRender={(item: any, index, column)=>{
                        if (item.internalMeetingDate !== undefined)
                        {
                           const weekday = ["Domenica","Lunedì","Martedì","Mercoledì","Giovedì","Venerdì","Sabato"];
                           let convert: Date = new Date(item.internalMeetingDate);
                           let day, month, year, hours, minutes;

                           if (convert.getFullYear() === 1)
                               return undefined;
                               
                           if (convert !== undefined)
                           {
                               day = convert.getDate();
                               month = convert.getMonth() + 1;
                               year = convert.getFullYear();
                               hours = ("0" + convert.getHours()).slice(-2);
                               minutes = ("0" + convert.getMinutes()).slice(-2);
                               return weekday[convert.getDay()] + " " + day + "/" + month + "/" + year + " " + hours + ":" + minutes;
                           }
                       }
               
                       return undefined;
                    }} />
                    <ListHeaderEntry name="Data demo cliente" fieldName="demoDate" minWidth={200} maxWidth={250} onRender={(item: any, index, column)=>{
                        if (item.demoDate !== undefined)
                        {
                           const weekday = ["Domenica","Lunedì","Martedì","Mercoledì","Giovedì","Venerdì","Sabato"];
                           let convert: Date = new Date(item.demoDate);
                           let day, month, year, hours, minutes;

                           if (convert.getFullYear() === 1)
                               return undefined;
                               
                           if (convert !== undefined)
                           {
                               day = convert.getDate();
                               month = convert.getMonth() + 1;
                               year = convert.getFullYear();
                               hours = ("0" + convert.getHours()).slice(-2);
                               minutes = ("0" + convert.getMinutes()).slice(-2);
                               return weekday[convert.getDay()] + " " + day + "/" + month + "/" + year + " " + hours + ":" + minutes;
                           }
                       }
               
                       return undefined;
                    }} />
                </PageDefinitionContainer>
            </PageContainer>
            <PartnerLeadCard ref={cardRef} />
            <CommonTemporaryLeadDataModal
                showDialog={showLeadDialog}
                title="Inserisci i dati del cliente"
                onCancelModal={() => setShowLeadDialog(false)}
                onSuccessModal={(lead: Lead) => sendPartnerReferralLink(lead)} />
        </>
    );
}