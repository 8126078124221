import React, { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { CardField } from "../../Common/Template/Card/CardField";
import { CardGroup } from "../../Common/Template/Card/CardGroup";

export const PartnerLeadCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/lead" title={'Scheda Lead'} ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="companyName" type={SystemFieldType.String} label="Nome società"/>
                <CardField name="email" type={SystemFieldType.String} label="E-mail"/>
                <CardField name="firstname" type={SystemFieldType.String} label="Nome contatto" />
                <CardField name="lastname" type={SystemFieldType.String} label="Cognome contatto" />
                <CardField name="phone" type={SystemFieldType.String} label="Telefono" />
                <CardField name="statusCode" type={SystemFieldType.String} label="Stato" hasTableRelation tableRelationEndpoint="/odata/datasecurity/status" tableRelationFilter={"?$filter=entityName eq 'Lead'"} tableRelationKey="code" tableRelationField="description" readonly />
            </CardGroup>
            <CardGroup name="principalQualification" label="Qualifica principale" isOpen={true}>
                <CardField name="salesHeaderTemplateId" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/salesheadertemplate" tableRelationFilter={"?$filter=Source eq 'Industry'"} tableRelationKey="id" tableRelationField="description" label="Industry" />
                <CardField name="full_Users_No" type={SystemFieldType.Number} label="Nr. utenti full" />
                <CardField name="limited_User_No" type={SystemFieldType.Number} label="Nr. utenti limitati" />
                <CardField name="noOfCompany" type={SystemFieldType.Number} label="Numero società"/>
                <CardField name="budget" type={SystemFieldType.Number} label="Bugdet stimato" readonly />
                <CardField name="registerRequest" type={SystemFieldType.String} label="Richiesta registrazione" readonly />
            </CardGroup>
            <CardGroup name="surveyQualification" label="Questionario qualifica" isOpen={true}>
                <CardField name="noOfEmployees" type={SystemFieldType.Number} label="Nr. dipendenti" />
                <CardField name="currentERPName" type={SystemFieldType.String} label="ERP attuale" />
                <CardField name="decisionDate" type={SystemFieldType.Date} label="Data decisione" />
                <CardField name="expectedLiveDate" type={SystemFieldType.Date} label="Data aspettativa live" />
                <CardField name="monthlyBudget" type={SystemFieldType.Number} label="Budget mensile dichiarato" />
                <CardField name="note" type={SystemFieldType.String} label="Note" />
                <CardField name="isAlreadyCustomer" type={SystemFieldType.Boolean} label="È già un mio cliente" />
                <CardField name="activeMicrosoftServices" type={SystemFieldType.String} label="Per questi servizi Microsoft" />
            </CardGroup>
            <CardGroup name="bookInternalMeeting" label="Prenota meeting interno" isOpen={true}>
                <CardField name="internalMeetingDate" type={SystemFieldType.Date} label="Data meeting interno" readonly />
            </CardGroup>
            <CardGroup name="bookCustomerDemo" label="Prenota demo con il cliente" isOpen={true}>
                <CardField name="demoDate" type={SystemFieldType.Date} label="Data demo cliente" readonly />
            </CardGroup>
            <CardGroup name="personalData" label="Dati anagrafici" isOpen={true}>
                <CardField name="address" type={SystemFieldType.String} label="Indirizzo" />
                <CardField name="zipCode" type={SystemFieldType.String} label="C.A.P." />
                <CardField name="city" type={SystemFieldType.String} label="Città" />
                <CardField name="county" type={SystemFieldType.String} label="Provincia" />
                <CardField name="country" type={SystemFieldType.String} label="Paese" />
            </CardGroup>
        </PageContainer>    
    );
});