import { ChoiceGroup, DefaultButton, IChoiceGroupOption, Label, mergeStyles, PrimaryButton, Shimmer, ShimmerElementsGroup, ShimmerElementType, Stack, TextField } from "@fluentui/react";
import { Guid } from "guid-typescript";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { CustomerService } from "../../../ApplicationCode/Customer/CustomerService";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";
import { Ticket } from "../../../Model/SystemModels";
import { CustomAttachmentFile } from "../../Customer/CustomerIncidentCard/CustomAttachmentFIle";
import { CustomAttachment } from "../../Customer/CustomerIncidentCard/CustomCustomerIncidentCard";
import { RichTextEditor } from "../Template/RichTextEditor";

export interface ICommonCreateTicketForm {
    showForm: boolean;
    saveInProgress: boolean;
    record: Ticket;
    fromBC?: boolean;
    customActionEnabled: boolean;
    onClosePage: () => void;
    onCreateTicket: (record:Ticket) => void;
    onSetRecord: (record:Ticket) => void;
}

export const CommonCreateTicketForm : React.FC<ICommonCreateTicketForm> = (props) => {
    const [options, setOptions] = useState<IChoiceGroupOption[]>([]);

    const modalContentClassName = mergeStyles([{
        width: '100%',
        height: '100%',
        padding: 0
    }]);

    const formDescriptionClassName = mergeStyles([{
        marginTop: 30
    }]);

    const formAttachmentsClassName = mergeStyles([{
        width: '100%',
        position: 'relative',
        marginTop: 20
    }]);
    
    useEffect(() => {
        if (props.showForm)
        {
            props.onSetRecord(props.record);

            let customerService = new CustomerService();
            customerService.getToShowIncidentTypes()
                .then((resp)=>{
                    let choices: IChoiceGroupOption[] = [];

                    for (let i = 0; i < resp.length; i++){
                        let choice: IChoiceGroupOption = {
                            key: resp[i].entryNo.toString(),
                            text: resp[i].name
                        };
                        choices.push(choice);
                    }

                    setOptions(choices);
                })
                .catch((err)=>{
                    ToastService.showMessage(MessageType.Error, "Errore: "+err.message);
                });
        }
    }, [props.showForm]);

    const uploadFile = () => {
        if (props.record.ticketInfo.attachments.length < 5){
            const permittedExtensions: string = ".html,.htm,.pdf,.jpg,.jpeg,.png,.xlsx,.xls,.ppt,.pptx,.doc,.docx";
            var input: HTMLInputElement = document.createElement('input');
            input.type = 'file';
            input.accept = permittedExtensions;
            input.onchange = _this => {
                let files =   Array.from(input.files!);
                            
                const exts: string[] = permittedExtensions.toLowerCase().replaceAll(".","").split(",");
                const fileExt: string = files[0].name?.toLowerCase().toString().split(".").pop()!;
                
                if (!exts.includes(fileExt))
                    ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerIncidentList:extNotSupported')!+fileExt);
                else
                {
                    if (files[0].size < 5000000){
                        var reader = new FileReader();
                        reader.readAsDataURL(files[0]);
                        
                        reader.onloadend = function () {
                            let currentAttachments: CustomAttachment[] = JSON.parse(JSON.stringify(props.record.ticketInfo.attachments));
                            
                            const attachment = new CustomAttachment();
                            attachment.id = Guid.create().toString();
                            attachment.fileName = files[0].name;
                            attachment.fileExtension = fileExt;
                            attachment.fileBlob = reader.result?.toString()!;
                            
                            currentAttachments.push(attachment);
                            
                            let newRecord: Ticket = JSON.parse(JSON.stringify(props.record));
                            newRecord.ticketInfo.attachments = currentAttachments;
                            props.onSetRecord(newRecord)
                        };
                        
                        reader.onerror = function (error) {
                            console.log('Error: ', error);
                        };
                    }
                    else{
                        ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerIncidentList:fileMaxWeight')!);
                    }
                }
            };
            input.click();
        }
        else{
            ToastService.showMessage(MessageType.Error, t('customerCenter:CustomerIncidentList:fileMaxCount')!);
        }
    }

    const getCustomAttachmentList = () : JSX.Element[] => {
        let elements: JSX.Element[] = [];
        
        for (let i = 0; i < props.record.ticketInfo.attachments.length; i++)
        {
            if (!props.saveInProgress){
                elements.push(
                    <Stack.Item shrink 
                            align="stretch" 
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <CustomAttachmentFile id={props.record.ticketInfo.attachments[i].id}
                                                      fileName={props.record.ticketInfo.attachments[i].fileName} 
                                                      fileExtension={props.record.ticketInfo.attachments[i].fileExtension} 
                                                      onRemoveClick={(id) => removeAttachment(id)} />
                            </Stack.Item>
                );
            }
            else{
                elements.push(                    
                    <Shimmer shimmerElements={[
                        {
                            type: ShimmerElementType.line,
                            height: 50,
                            width: 180
                        }
                    ]} />
                );
            }
        }

        return elements;
    }

    const removeAttachment = (id: string) =>{       
        let currentAttachments: CustomAttachment[] = [];
        for (let i = 0; i < props.record.ticketInfo.attachments.length; i++)
        {
            if (props.record.ticketInfo.attachments[i].id !== id)
                currentAttachments.push(props.record.ticketInfo.attachments[i]);
        }

        let newRecord: Ticket = JSON.parse(JSON.stringify(props.record));
        newRecord.ticketInfo.attachments = currentAttachments;
        props.onSetRecord(newRecord);
    }

    enum ErrorType {
        Title,
        Description,
        Email
    }

    const getErrorMessage = (errorType: ErrorType): string => {
        switch (errorType)
        {
            case ErrorType.Title:
                if (!props.record.ticketInfo.title)
                    return t('customerCenter:CustomerIncidentList:CustomerIncidentCard:CardGroup:CardField:validation:title')!

                    break;
            case ErrorType.Description:
                if (!props.record.ticketInfo.description)
                    return t('customerCenter:CustomerIncidentList:CustomerIncidentCard:CardGroup:CardField:validation:description')!

                    break;
            case ErrorType.Email:
                if (!props.record.ticketInfo.contactemail)
                    return t('customerCenter:CustomerIncidentList:CustomerIncidentCard:CardGroup:CardField:validation:email')!

                if (props.record.ticketInfo.contactemail.includes('onmicrosoft.com'))
                    return t('customerCenter:CustomerIncidentList:CustomerIncidentCard:CardGroup:CardField:validation:emailOnMicrosoftError')!

                    break;
            default:
                break;
        }
        return "";
    }
    
    return(
        <Stack key="modalBodyContent" verticalFill verticalAlign="stretch" className={modalContentClassName}>
            <Stack.Item>
                <Stack key={"title"} horizontal tokens={{childrenGap: 20}}>
                    <Stack.Item styles={{root: {width: '70%'}}}>
                        {
                            props.saveInProgress ?
                            <Shimmer shimmerElements={[
                                {
                                    type: ShimmerElementType.line,
                                    height: 33,
                                    width: '100%'
                                }
                            ]} />
                            :
                            <TextField errorMessage={getErrorMessage(ErrorType.Title)} underlined placeholder={t('customerCenter:CustomerIncidentList:CustomerIncidentCard:CardGroup:CardField:titlePlaceHolder')!} iconProps={{iconName: "IssueTracking"}} readOnly={props.fromBC} value={props.record.ticketInfo.title} onChange={(e, v)=>{
                                let newRecord: Ticket = JSON.parse(JSON.stringify(props.record));
                                newRecord.ticketInfo.title = v!;
                                props.onSetRecord(newRecord)
                            }} />
                        }
                    </Stack.Item>
                    <Stack.Item styles={{root: {width: '30%'}}}>
                        {
                            props.saveInProgress ?
                            <Shimmer shimmerElements={[
                                {
                                    type: ShimmerElementType.line,
                                    height: 33,
                                    width: '100%'
                                }
                            ]} />
                            :
                            <TextField errorMessage={getErrorMessage(ErrorType.Email)} underlined placeholder="Email" iconProps={{iconName: "Mail"}} value={props.record.ticketInfo.contactemail} onChange={(e, v)=>{
                                let newRecord: Ticket = JSON.parse(JSON.stringify(props.record));
                                newRecord.ticketInfo.contactemail = v!;
                                props.onSetRecord(newRecord)
                            }} />
                        }
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Stack key={"type"} styles={{root:{marginTop:20}}}>
                    <Stack.Item>
                        {
                            props.saveInProgress ?
                            <Shimmer customElementsGroup={
                                <ShimmerElementsGroup flexWrap
                                    shimmerElements={[
                                        { type: ShimmerElementType.line, height: 20, width: '100%' },
                                        { type: ShimmerElementType.line, height: 32, width: '100%' }
                                    ]}
                            />
                            } />
                            :
                            <ChoiceGroup label={t('customerCenter:CustomerIncidentList:CustomerIncidentCard:CardGroup:CardField:ticketType')!} selectedKey={props.record.ticketInfo.ticketType.toString()} options={options} styles={{
                                flexContainer:{
                                    display: 'flex',
                                    flexFlow: 'row',
                                    gridGap: '20px'
                                }
                            }}
                            onChange={(e, opt) => {
                                let newRecord: Ticket = JSON.parse(JSON.stringify(props.record));
                                newRecord.ticketInfo.ticketType = Number(opt!.key);
                                props.onSetRecord(newRecord)
                            }} />
                        }
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Stack key={"description"} className={formDescriptionClassName}>
                    <Stack.Item>
                        {
                            props.saveInProgress ?
                            <Shimmer styles={{root:{height:'calc(100vh - 550px)'}}} shimmerElements={[
                                {
                                    type: ShimmerElementType.line,
                                    height: 400,
                                    width: '100%'
                                }
                            ]} />
                            :
                            <>
                                <Label>Descrizione</Label>
                                <RichTextEditor data={props.record.ticketInfo.description} onChange={(data: any) => {
                                    let newRecord: Ticket = JSON.parse(JSON.stringify(props.record));
                                    newRecord.ticketInfo.description = data;
                                    props.onSetRecord(newRecord)
                                }} />
                            </>
                        }
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item shrink>
                <Stack key={"attachments"} horizontal tokens={{childrenGap: 15}} className={formAttachmentsClassName}>
                    <Stack.Item styles={{root:{width:'200px'}}}>
                        <DefaultButton disabled={props.saveInProgress} text={t('customerCenter:CustomerIncidentList:CustomerIncidentCard:CardGroup:CardField:attachmentButton')!} styles={{root:{width:'200px',marginTop:10}}} iconProps={{
                            iconName: 'Upload'
                        }}
                        onClick={uploadFile} />
                    </Stack.Item>
                    <Stack.Item styles={{
                        root:{
                            width:'60%',
                            overflow: 'auto',
                            display: 'flex',
                            flexFlow: 'row wrap',
                            paddingTop:10
                        }
                    }}>
                        {getCustomAttachmentList()}
                    </Stack.Item>
                    <Stack.Item hidden={!props.customActionEnabled} styles={{root:{width:'200px'}}}>
                        <PrimaryButton disabled={props.saveInProgress} text={t('customerCenter:CustomerHelpPageMultipleMode:customActionText')!} styles={{root:{width:'200px',marginTop:10}}} iconProps={{
                            iconName: 'Ticket'
                        }}
                        onClick={()=>props.onCreateTicket(props.record)} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    )
}
