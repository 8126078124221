import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminPartnerBusinessContractCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda partner business contract"} endpoint="odata/partner/business/contract" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField label="Codice" name="code" type={SystemFieldType.String} />
                <CardField label="Descrizione" name="description" type={SystemFieldType.String} />
                <CardField label="Business line" name="businessLineCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/business/line" tableRelationKey="code" tableRelationField="description" />
                <CardField label="Gestione licenze CSP abilitata" name="cspLicenseManagementEnable" type={SystemFieldType.Boolean} />
            </CardGroup>
            <CardGroup name="generalSynchronization" label="Setup sincronizzazione generale" isOpen={true}>
                <CardField label="Id calendario Booking" name="bookingCalendarId" type={SystemFieldType.String} />
                <CardField label="Id gruppo Sharepoint" name="sharepointGroupId" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="partnerSynchronization" label="Setup sincronizzazione partner" isOpen={true}>
                <CardField label="Sincronizzazione partner account attiva" name="partnerAccountCrmSyncActive" type={SystemFieldType.Boolean} />
                <CardField label="Filtro sincronizzazione partner account CRM" name="partnerAccountCrmOdataFilter" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="lead" label="Lead" isOpen={true}>
                <CardField label="Codice wizard qualifica lead" name="leadQualificationWizardCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/ui/wizard/header" tableRelationKey="code" tableRelationField="description" />
                <CardField label="Codice wizard checklist lead" name="leadChecklistWizardCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/ui/wizard/header" tableRelationKey="code" tableRelationField="description" />
            </CardGroup>
            <CardGroup name="leadSynchronization" label="Setup sincronizzazione lead" isOpen={true}>
                <CardField label="Sincronizzazione lead attiva" name="leadCrmSyncActive" type={SystemFieldType.Boolean} />
                <CardField label="Gestione utente da lead" name="leadManageUser" type={SystemFieldType.Boolean} />
                <CardField label="Filtro sincronizzazione lead CRM" name="leadCrmOdataFilter" type={SystemFieldType.String} />
                <CardField label="Deal category id lead" name="leadDealCategoryId" type={SystemFieldType.String} />
                <CardField label="Data ultima sincronizzazione lead" name="leadLastSyncDate" type={SystemFieldType.Date} />
            </CardGroup>
            <CardGroup name="leadInteractionSynchronization" label="Setup sincronizzazione interazioni lead" isOpen={true}>
                <CardField label="Sincronizzazione interazioni lead attiva" name="leadInteractionCrmSyncActive" type={SystemFieldType.Boolean} />
                <CardField label="Codici stato (divisi con '|')" name="dataSecurityCodes" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="leadAutomation" label="Setup lead automation" isOpen={true}>
                <CardField label="Sospensione Lead automatica" name="suspendedLeadAutomaticEnabled" type={SystemFieldType.Boolean} />
                <CardField label="Perdita Lead automatica" name="lostLeadAutomaticEnabled" type={SystemFieldType.Boolean} />
                <CardField label="Giorni sospensione da creazione" name="suspendedLeadDaysCreation" type={SystemFieldType.Number} />
                <CardField label="Giorni perdita da creazione" name="lostLeadDaysCreation" type={SystemFieldType.Number} />
                <CardField label="Giorni sospensione da ultimo inbound" name="suspendedLeadDaysLastInbound" type={SystemFieldType.Number} />
                <CardField label="Giorni perdita da ultimo inbound" name="lostLeadDaysLastInbound" type={SystemFieldType.Number} />
                <CardField label="Giorni sospensione da data scelta" name="suspendedLeadDaysFromDecisionDate" type={SystemFieldType.Number} />
                <CardField label="Giorni perdita da data scelta" name="lostLeadDaysFromDecisionDate" type={SystemFieldType.Number} />
                <CardField label="Data Security Code sospensione" name="suspendedLeadDataSecurityCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/datasecurity/status" tableRelationKey="code" tableRelationField="description" tableRelationFilter="?$filter=entityName eq 'Lead'" />
                <CardField label="Data Security Code perdita" name="lostLeadDataSecurityCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/datasecurity/status" tableRelationKey="code" tableRelationField="description" tableRelationFilter="?$filter=entityName eq 'Lead'" />
            </CardGroup>
            <CardGroup name="incidentSyncronization" label="Setup sincronizzazione incident" isOpen={true}>
                <CardField label="Sincronizzazione incident attiva" name="incidentCrmSyncActive" type={SystemFieldType.Boolean} />
                <CardField label="Filtro sincronizzazione incident CRM" name="incidentCrmOdataFilter" type={SystemFieldType.String} />
                <CardField label="Filtro sincronizzazione entitlement CRM" name="entitlementCrmOdataFilter" type={SystemFieldType.String} />
                <CardField label="Incident queue Id" name="incidentQueueId" type={SystemFieldType.String} />
                <CardField label="Data ultima sincronizzazione incident" name="incidentLastSyncDate" type={SystemFieldType.Date} />
                <CardField label="Data ultima sincronizzazione entitlement" name="entitlementLastSyncDate" type={SystemFieldType.Date} />
                <CardField label="Incident entitlement name" name="incidentEntitlementName" type={SystemFieldType.String} />
                <CardField label="Incident service scope Id" name="incidentServiceScopeId" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="defaultData" label="Dati di default" isOpen={true}>
                <CardField label="App Id default" name="defaultAppId" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/system/app" tableRelationKey="id" tableRelationField="name" />
                <CardField label="Codice ruolo default" name="defaultRoleCode" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/system/role" tableRelationKey="code" tableRelationField="description" tableRelationFilter="?$filter=appId eq '#defaultAppId#'" />
                <CardField label="ERP channel dimension" name="erpChannelDimension" type={SystemFieldType.String} />
            </CardGroup>
            <CardGroup name="incident" label="Ticket di assistenza" isOpen={true}>
                <CardField label="Articolo richiesto" name="isItemRequired" type={SystemFieldType.Boolean} />
            </CardGroup>
            <CardGroup name="localization" label="Localizzazione" isOpen={false}>
                <CardField label="Legal Entity" name="legalEntityCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name" />
                <CardField label="Distributore" name="distributorCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name" />
            </CardGroup>
        </PageContainer>
    );
});