import React from "react";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";

export const AdminItemRequirementList : React.FC = () => {
    return (
        <>
            <PageContainer endpoint="/odata/ItemRequirement" pageType={SystemPageType.List} title="Lista item requirements" backAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>                    
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id sorgente" fieldName="sourceId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id Destinazione" fieldName="destinationId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Chiedi conferma" fieldName="askConfirm" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="System Id" fieldName="systemId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation User Id" fieldName="systemCreationUserId" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="System Creation Date" fieldName="systemCreationDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Data aggiornamento sistema" fieldName="systemUpdateDate" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id utente aggiornamento sistema " fieldName="systemUpdateUserId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Timestamp" fieldName="timestamp" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
        </>
    );
}