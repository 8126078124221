import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminBCNCustomerProcessModuleCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda modulo processo"} endpoint="/odata/bcn/customer/module" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="environmentName" type={SystemFieldType.String} label="Nome ambiente" readonly />
                <CardField name="code" type={SystemFieldType.String} label="Codice" readonly />
                <CardField name="description" type={SystemFieldType.String} label="Descrizione" readonly />
                <CardField name="enabled" type={SystemFieldType.Boolean} label="Abilitato" readonly />
                <CardField name="presaleVisible" type={SystemFieldType.Boolean} label="Visibile prevendita" readonly />
            </CardGroup>
            <CardGroup name="task" label="Avanzamento" isOpen={false}>
                <CardField name="dataMigrNoTaskComp" type={SystemFieldType.Number} label="DM Nr. task completate" readonly />
                <CardField name="dataMigrNoTaskToBe" type={SystemFieldType.Number} label="DM Nr. task da completare" readonly />
                <CardField name="interviewQuestionCompleted" type={SystemFieldType.Boolean} label="Int. Quest. completato" readonly />
                <CardField name="noTaskCompleted" type={SystemFieldType.Number} label="Nr. task completate" readonly />
                <CardField name="noTaskToBeCompleted" type={SystemFieldType.Number} label="Nr. task da completare" readonly />
                <CardField name="onBoardingPhaseNo" type={SystemFieldType.Number} label="Nr. fase OnBoarding" readonly />
                <CardField name="processCount" type={SystemFieldType.Number} label="Conteggio processo" readonly />
            </CardGroup>
            <CardGroup name="other" label="Altro" isOpen={false}>
                <CardField name="permissionPremium" type={SystemFieldType.String} label="Permesso premium" readonly />
                <CardField name="presaleIcon" type={SystemFieldType.String} label="Icona prevendita" readonly />
                <CardField name="testCompleted" type={SystemFieldType.Boolean} label="Test completato" readonly />
            </CardGroup>
        </PageContainer>
    );
});