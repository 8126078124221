import { useEffect, useRef, useState } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { SystemCore } from "../../../../Core/System/SystemCore";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemDataLoadingStatus, SystemOperation, SystemPageType } from "../../../../Model/SystemModels";
import { Loader } from "../../../Common/Loader/Loader";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { PartnerService } from "../../../../ApplicationCode/Partner/PartnerService";
import { CrmSetup } from "../../../../Model/PartnerModels";
import { AdminLeadCard } from "../../AdminLeadCard/AdminLeadCard";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export const AdminLeadAutomationProspectList : React.FC = () => {
    const [dataStatus, setDataStatus] = useState(SystemDataLoadingStatus.ToLoad);
    const [loaderMessage, setLoaderMessage] = useState(t("adminCenter:leadAutomationProspect:loaderText")!);
    const [crmSetup, setCrmSetup] = useState(new CrmSetup());
    const [errorMessage, setErrorMessage] = useState<string>("");
    
    const navigate = useNavigate();
    const currentPage = useRef<PageContainerBase>(null);
    const leadCardRef = useRef<PageContainerBase>(null);
    let listTitle = "";
    
    const adminService = new AdminService();
    const partnerService = new PartnerService();

    useEffect(() => {
        if(dataStatus === SystemDataLoadingStatus.ToLoad){
            partnerService.getCrmLeadData()
            .then((resp) => {
                setCrmSetup(resp);
                setDataStatus(SystemDataLoadingStatus.Loaded)
                setLoaderMessage("");
            })
            .catch((resp) => {
                setDataStatus(SystemDataLoadingStatus.Loaded)
                setLoaderMessage("");
                ToastService.showMessage(MessageType.Error, t("adminCenter:leadAutomationProspect:text0001Err")!);
            });
        }
    }, [dataStatus])
    
    const buildListEndpoint = () : string =>{
        let endpoint = "/odata/prospect/line?$orderby=leadCreatedOn desc";
        listTitle = t("adminCenter:leadAutomationProspect:title")!;   
        return endpoint;
    }
    
    const registerRow = (item: any) => {
        let instance = HttpHelper.buildAxiosInstance();
        let endpoint = "/api/crm/automation/register/prospect/line";
        
        var promise = new Promise((resolve,reject)=>{
            instance.post(endpoint, item)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                setErrorMessage(resp.response.data.message);
                reject(resp.response.data.message);
            })
            .finally(() =>{
                currentPage.current?.reloadData();
            })
        })
        
        ToastService.showPromiseMessage(promise, t("adminCenter:leadAutomationProspect:text0001Msg")!, t("adminCenter:leadAutomationProspect:text0002Msg")!, t("adminCenter:leadAutomationProspect:text0002Err")!+errorMessage);
    }
    
    const rejectRow = (item: any) => {
        let instance = HttpHelper.buildAxiosInstance();
        let endpoint = "/api/crm/automation/reject/prospect/line";
        
        var promise = new Promise((resolve,reject)=>{
            instance.post(endpoint, item)
            .then((resp)=>{
                resolve(true);
            })
            .catch((resp)=>{
                setErrorMessage(resp.response.data.message);
                reject(resp.response.data.message);
            })
            .finally(() =>{
                currentPage.current?.reloadData();
            })
        })
        
        ToastService.showPromiseMessage(promise, t("adminCenter:leadAutomationProspect:text0003Msg")!, t("adminCenter:leadAutomationProspect:text0004Msg")!, t("adminCenter:leadAutomationProspect:text0003Err")!+errorMessage);
    }
    
    if (!SystemCore.isFinishedDataStatus(dataStatus))
        return <Loader text={loaderMessage}></Loader>;
    else
        return (
            <>
                <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={listTitle} backAllowed ref={currentPage}>
                    <PageDefinitionContainer type={ContainerType.Action}>
                        <ActionArea category={SystemActionCategory.Navigation}>
                            <ActionEntry name="openCrmLead" label={t("adminCenter:leadAutomationProspect:actions:openCrmLead")!} iconName="CRMServices" runOnRec isPromoted onClick={(req:any)=>{
                                window.open(crmSetup.baseAddress+req.crmLeadId);
                            }} />
                            <ActionEntry name="openLead" label={t("adminCenter:leadAutomationProspect:actions:openLead")!} iconName="View" runOnRec isPromoted onClick={(req:any)=>{
                                adminService.getLead(req.leadId)
                                .then((resp) => {
                                    console.log(resp);
                                    let newRecord = resp;
                                    leadCardRef.current?.setRecord(newRecord);
                                    leadCardRef.current?.openPage(SystemOperation.Update);
                                })
                            }} />
                            <ActionEntry name="QualifierPage" label={t("adminCenter:leadAutomationProspect:actions:qualifierPage")!} iconName="AccountManagement" isPromoted onClick={(req:any)=>{
                                    adminService.getLead(req.leadId)
                                    .then((resp) => {
                                        let url = '/app/admin/common/leadQualifier';
                                        if(req)
                                            url += '?bookmark='+btoa(resp.systemId);
                                        
                                        navigate(url)
                                    })
                                }}>
                            </ActionEntry>
                            <ActionEntry name="openProspect" label={t("adminCenter:leadAutomationProspect:actions:openProspect")!} iconName="BulletedList" onClick={() => {
                                navigate("/app/admin/administrative/prospect");
                            }} />
                            <ActionEntry name="openProspectLeadAutomation" label={t("adminCenter:leadAutomationProspect:actions:openProspectLeadAutomation")!} iconName="BulletedList" onClick={() => {
                                navigate("/app/admin/administrative/prospectLeadAutomationSetup");
                            }} />
                        </ActionArea>
                        <ActionArea category={SystemActionCategory.Process}>
                            <ActionEntry name="calculateSuggestedLines" label={t("adminCenter:leadAutomationProspect:actions:calculateSuggestedLines")!} iconName="Sync" isPromoted onClick={() => {
                                setLoaderMessage("Calcolo righe in corso ...");
                                setDataStatus(SystemDataLoadingStatus.Loading);
                                adminService.calculateSuggestedLines()
                                    .then(() => {
                                        setDataStatus(SystemDataLoadingStatus.Loaded);
                                        ToastService.showMessage(MessageType.Success, "Calcolo completato con successo");
                                    })
                                    .catch((err) => {
                                        setDataStatus(SystemDataLoadingStatus.Loaded);
                                        ToastService.showMessage(MessageType.Error, "Errore durante il calcolo: " + err);
                                    })
                            }}>                                
                            </ActionEntry>
                            <ActionEntry name="registerRow" label={t("adminCenter:leadAutomationProspect:actions:registerRow")!} iconName="Accept" runOnRec isPromoted onClick={(req:any) => {
                                registerRow(req);
                            }}>
                            </ActionEntry>
                            <ActionEntry name="rejectRow" label={t("adminCenter:leadAutomationProspect:actions:rejectRow")!} iconName="Cancel" runOnRec isPromoted onClick={(req:any) => {
                                rejectRow(req);
                            }}>
                            </ActionEntry>
                        </ActionArea>
                    </PageDefinitionContainer>
                    <PageDefinitionContainer type={ContainerType.PageStructure}>
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:actionType")!} fieldName="actionType" minWidth={200} maxWidth={300} onRender={(item: any, index, column) => {
                            if (item.actionType === 0)
                                return "Task"
                            else if (item.actionType === 1)
                                return "Email"
                            else if (item.actionType === 2)
                                return "Email CRM"
                            else if (item.actionType === 3)
                                return "Aggiunta contatto a segmento"
                        }} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:reason")!} fieldName="reason" minWidth={250} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:ownerType")!} fieldName="ownerType" minWidth={110} maxWidth={110} onRender={(item: any, index, column) => {
                            if (item.ownerType === 0)
                                return "User"
                            else if (item.ownerType === 1)
                                return "Entity"
                        }} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:actionOwnerName")!} fieldName="actionOwnerName" minWidth={170} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:actionTemplate")!} fieldName="actionTemplate" minWidth={120} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadPartnerBusinessContractCode")!} fieldName="leadPartnerBusinessContractCode" minWidth={170} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadName")!} fieldName="leadName" minWidth={250} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadEmail")!} fieldName="leadEmail" minWidth={250} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadSubject")!} fieldName="leadSubject" minWidth={220} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadCreatedOn")!} fieldName="leadCreatedOn" minWidth={170} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadScore")!} fieldName="leadScore" minWidth={80} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadBudget")!} fieldName="leadBudget" minWidth={80} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadModelScoreName")!} fieldName="leadModelScoreName" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadModelScore")!} fieldName="leadModelScore" minWidth={80} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadRating")!} fieldName="leadRating" minWidth={100} maxWidth={300} onRender={(item: any, index, column) => {
                            if (item.leadRating === 0)
                                return t("adminCenter:leadAutomationProspect:fields:leadRating0")!
                            else if (item.leadRating === 1)
                                return t("adminCenter:leadAutomationProspect:fields:leadRating1")!
                            else if (item.leadRating === 2)
                                return t("adminCenter:leadAutomationProspect:fields:leadRating2")!
                        }} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadId")!} fieldName="leadId" minWidth={80} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:crmLeadId")!} fieldName="crmLeadId" minWidth={250} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadStatusDescription")!} fieldName="leadStatusDescription" minWidth={150} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadOwnerName")!} fieldName="leadOwnerName" minWidth={200} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:demoDate")!} fieldName="demoDate" minWidth={170} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:trialStartDate")!} fieldName="trialStartDate" minWidth={170} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:decisionDate")!} fieldName="decisionDate" minWidth={170} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:expectedLiveDate")!} fieldName="expectedLiveDate" minWidth={170} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:lastInboundEmailDate")!} fieldName="lastInboundEmailDate" minWidth={170} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:lastInboundEmailDate")!} fieldName="lastInboundEmailDate" minWidth={170} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:lastActivityType")!} fieldName="lastActivityType" minWidth={150} maxWidth={300} onRender={(item: any, index, column) => {
                            if (item.lastActivityDate.substring(0,4) === "0001")
                                return ""
                                
                            if (item.lastActivityType === 0)
                                return t("adminCenter:leadAutomationProspect:fields:lastActivityType0")!
                            else if (item.lastActivityType === 1)
                                return t("adminCenter:leadAutomationProspect:fields:lastActivityType1")!
                            else if (item.lastActivityType === 2)
                                return t("adminCenter:leadAutomationProspect:fields:lastActivityType2")!
                            else if (item.lastActivityType === 3)
                                return t("adminCenter:leadAutomationProspect:fields:lastActivityType3")!
                            else if (item.lastActivityType === 4)
                                return t("adminCenter:leadAutomationProspect:fields:lastActivityType4")!
                            else if (item.lastActivityType === 5)
                                return t("adminCenter:leadAutomationProspect:fields:lastActivityType5")!
                        }} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:nextActivityDate")!} fieldName="nextActivityDate" minWidth={170} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:nextActivityType")!} fieldName="nextActivityType" minWidth={170} maxWidth={300} onRender={(item: any, index, column) => {
                            if (item.nextActivityDate.substring(0,4) === "0001")
                                return ""
                                
                            if (item.nextActivityType === 0)
                                return t("adminCenter:leadAutomationProspect:fields:nextActivityType0")!
                            else if (item.nextActivityType === 1)
                                return t("adminCenter:leadAutomationProspect:fields:nextActivityType1")!
                            else if (item.nextActivityType === 2)
                                return t("adminCenter:leadAutomationProspect:fields:nextActivityType2")!
                            else if (item.nextActivityType === 3)
                                return t("adminCenter:leadAutomationProspect:fields:nextActivityType3")!
                            else if (item.nextActivityType === 4)
                                return t("adminCenter:leadAutomationProspect:fields:nextActivityType4")!
                            else if (item.nextActivityType === 5)
                                return t("adminCenter:leadAutomationProspect:fields:nextActivityType5")!
                        }} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:autoAccept")!} fieldName="autoAccept" minWidth={170} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:previousRegisterCount")!} fieldName="previousRegisterCount" minWidth={210} maxWidth={200} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:lastWeekInteractions")!} fieldName="lastWeekInteractions" minWidth={180} maxWidth={200} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:lastMonthInteractions")!} fieldName="lastMonthInteractions" minWidth={150} maxWidth={200} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:lineError")!} fieldName="lineError" minWidth={170} maxWidth={300} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:leadViewCode")!} fieldName="leadViewCode" minWidth={150} maxWidth={250} />
                        <ListHeaderEntry name={t("adminCenter:leadAutomationProspect:fields:lineNo")!} fieldName="lineNo" minWidth={70} maxWidth={300} />
                    </PageDefinitionContainer>
                </PageContainer>
                <AdminLeadCard ref={leadCardRef} />
            </>
        );
}