import React, { forwardRef} from "react";
import {SystemFieldType, SystemPageType} from "../../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import { CardField } from "../../../Common/Template/Card/CardField";

export const PartnerCrmLeadWorkflowSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} 
                       title={"Scheda Workflow Setup "} 
                       endpoint="/odata/crm/lead/workflow/setup"
                       ref={ref}>
            <CardGroup name="Workflow" label="Workflow" isOpen={false}>
                <CardField name="code" type={SystemFieldType.String} label="Codice" readonly/>
                <CardField name="description" type={SystemFieldType.String} label="Descrizione"/>
                <CardField name="weight" type={SystemFieldType.Number} label="Peso"/>
                <CardField name="crmId" type={SystemFieldType.String} label="ID CRM"/>
                <CardField name="isInitial" type={SystemFieldType.Boolean} label="Stato iniziale"/>
                <CardField name="order" type={SystemFieldType.Number} label="Ordine"/>
            </CardGroup> 
        </PageContainer>
    );
});