import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AdminService } from "../../../ApplicationCode/Admin/AdminService";
import { ISessionStateContext, SessionStateContext } from "../../../Core/State/SessionStateContext";

export const AdminApplicationContainer : React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let sessionStateContext: ISessionStateContext = useContext(SessionStateContext);
    let adminService = new AdminService();

    return (
        <Outlet></Outlet>
    );
    
}
