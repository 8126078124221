import React, { useRef } from "react";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import {SystemPageType } from "../../../../Model/SystemModels";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { PartnerCrmLeadWorkflowSetupCard } from "../PartnerCrmLeadWorkflowSetupCard/PartnerCrmLeadWorkflowSetupCard";

export const PartnerCrmLeadWorkflowSetupList : React.FC = () => {
    const cardRef = useRef<PageContainerBase>(null);

    return (
        <>
            <PageContainer endpoint={"/odata/crm/lead/workflow/setup"} cardRef={cardRef} pageType={SystemPageType.List} title={"Lista CRM Lead Workflow Setup"} backAllowed insertAllowed updateAllowed deleteAllowed>               
                <PageDefinitionContainer type={ContainerType.Action}>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Codice" fieldName="code" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Descrizione" fieldName="description" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="CRM Id" fieldName="crmId" minWidth={100} maxWidth={200} />
                </PageDefinitionContainer>
            </PageContainer>
            <PartnerCrmLeadWorkflowSetupCard ref={cardRef} />
        </>
    );
}