import { IComboBoxOption } from "@fluentui/react";
import React from "react";
import { SystemFieldType } from "../../../../Model/SystemModels";

export interface ICardFieldProps {
    name: string;
    label: string;
    type: SystemFieldType;
    required?: boolean;
    hasTableRelation?: boolean;
    tableRelationEndpoint?: string;
    tableRelationFilter?: string;
    tableRelationKey?: string;
    tableRelationField?: string;
    readonly?: boolean;
    cmbOptions?:IComboBoxOption[];
    selectedKey?: number;
    ext?:string;
    multiline?: boolean;
    allowFreeForm?: boolean;
    maxLength?: number;
    defaultSelectedKey?: any;
    valueWithoutBlobType?: boolean;
    hidden?: boolean;
    onGetIsVisible?: (record: any) => boolean;
    onValidate?: (fieldName: string, record: any, oldValue: any, newValue: any) => any;
}

export const CardField: React.FC<ICardFieldProps> = (props) => {
    return (
        <></>
    );
}