import { DefaultButton, DialogFooter, FontWeights, IButtonStyles, IconButton, Label, Modal, PrimaryButton, ResponsiveMode, Separator, Stack, TextField, mergeStyleSets } from "@fluentui/react";
import { Lead } from "../../../Model/PartnerModels";
import { useEffect, useState } from "react";
import { MessageType, ToastService } from "../../../Core/Toast/ToastService";

const ContentStyles = mergeStyleSets({
    Container: {
        width:'calc(688px)'
    },
    Header:{
        borderTop: `4px solid #0078d4`,
        color: "black",
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
    },
    Body: {
        padding: '0 10px 10px 10px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        }
    }
});

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: "black",
        marginLeft: 'auto',
        marginRight: '2px',
    },
    rootHovered: {
        color: "gray",
    }
};

export interface ICommonTemporaryLeadDataModal {
    showDialog: boolean;
    title: string;
    onSuccessModal: (temporaryLead: Lead) => void;
    onCancelModal: () => void;
}

export const CommonTemporaryLeadDataModal : React.FC<ICommonTemporaryLeadDataModal> = (props) => {

    const [lead, setLead] = useState<Lead>(new Lead());

    useEffect(() => {
        if (props.showDialog)
            setLead(new Lead());
    }, [props.showDialog]);
    
    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const handleOnSuccess = () => {
        if (lead.companyName.length > 0) {
            if (lead.email.length > 0)
                if (validateEmail(lead.email))
                    props.onSuccessModal(lead);
                else
                    ToastService.showMessage(MessageType.Error, "La mail non è valida");
            else
                ToastService.showMessage(MessageType.Error, "La mail è obbligatoria");
        }
        else
            ToastService.showMessage(MessageType.Error, "Il nome azienda è obbligatoria");
    }

    return (
        <Modal
            titleAriaId={"TemporaryLeadDataModal"}
            isOpen={props.showDialog}
            onDismiss={props.onCancelModal}
            isBlocking={true}
            containerClassName={ContentStyles.Container}
            responsiveMode={ResponsiveMode.small}>
                <Stack className={ContentStyles.Header} horizontal horizontalAlign="space-between">
                    <Stack.Item>
                        <Label>{props.title}</Label>
                        <Separator styles={{root:{width:'400px'}}}/>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={{ iconName: 'Cancel' }}
                            ariaLabel="Close popup modal"
                            onClick={props.onCancelModal} />
                    </Stack.Item>
                </Stack>
                <Stack key="modalContent" className={ContentStyles.Body}>
                    <Stack.Item key="modalBodyContent" className={ContentStyles.Body}>
                        <TextField label={"Nome Azienda"}  onChange={((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                            let currentLead = lead;
                            currentLead.companyName = newValue!;
                            setLead(currentLead);
                        })} />
                        <TextField label={"Email"} onChange={((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
                            let currentLead = lead;
                            currentLead.email = newValue!;
                            setLead(currentLead);
                        })} />
                    </Stack.Item>
                    <Stack.Item key="modalFooterContent" className={ContentStyles.Body}>
                        <DialogFooter>
                            <DefaultButton text={"Annulla"} onClick={props.onCancelModal} />
                            <PrimaryButton text={"Procedi"} onClick={handleOnSuccess} />
                        </DialogFooter>
                    </Stack.Item>
                </Stack>
        </Modal>
    )
}