import React, {forwardRef} from "react";
import {SystemFieldType,SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export interface IAdminEmailTemplateProps {
    title: string;
}

export const AdminEmailSetupCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/email/setup" title={"Setup email"} ref={ref}>
            <CardGroup name="localization" label="Localizzazione" isOpen={true}>
                <CardField name="legalEntityCode" label="Legal entity" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="distributorCode" label="Distributore" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="partnerBusinessContractCode" label="Partner business contract" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner/business/contract" tableRelationKey="code" tableRelationField="description"/>
                <CardField name="languageCode" label="Lingua" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/language" tableRelationKey="code" tableRelationField="name"/>
            </CardGroup>
            <CardGroup name="engineSetup" label="Setup" isOpen={true}>
                <CardField name="defaultEmailAddress" label="Indirizzo email default" type={SystemFieldType.String} />
                <CardField name="defaultEmailPassword" label="default password email" type={SystemFieldType.String} />
                <CardField name="defaultHost" label="Default Host" type={SystemFieldType.String} />
                <CardField name="defaultPort" label="Porta di default" type={SystemFieldType.Number} />
                <CardField name="enableSsl" label="SSL Abilitato" type={SystemFieldType.Boolean} />
                <CardField name="userPasswordTemplateCode" label="Codice template password utente" type={SystemFieldType.String} />
            </CardGroup>
        </PageContainer>
    );
});