import React, { useEffect, useState } from "react";
import { AdminService } from "../../../../ApplicationCode/Admin/AdminService";
import { HttpHelper } from "../../../../Core/Http/HttpHelper";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";
import { SystemActionCategory, SystemDataLoadingStatus, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";


export const AdminCustomerAppList : React.FC = () => {
    const [dataStatus,setDataStatus] = useState(SystemDataLoadingStatus.Loaded);
    const [showReloadExtensionAction,setShowReloadExtensionAction] = useState<boolean>(false);
    const adminService = new AdminService();
    const listType = HttpHelper.getParameter('listFilter');
    let listTitle="Lista App ed Extensions";

    useEffect(()=>{
        if(dataStatus === SystemDataLoadingStatus.Loaded)
            if(listType === "extension"){
                setShowReloadExtensionAction(true);
                setDataStatus(SystemDataLoadingStatus.ToLoad);
            }
    },[dataStatus])

    const buildListEndpoint = () : string => {
        const customerId = HttpHelper.getParameter('customerId');
        const environmentName = HttpHelper.getParameter('environmentName');
        const companyName = HttpHelper.getParameter('name');
        let endpoint = "odata/customer/environment/app";
        endpoint += "?$filter=customerId eq " + customerId;
        listTitle = "App cliente " + companyName;

        if (!! environmentName)
        {
            endpoint += " and environmentName eq '" + environmentName + "'";
            listTitle = "App ambiente " + environmentName;
        }
        endpoint += "&$orderby=name";

        return endpoint;
    }

    const buildReloadExtensionAction = () : JSX.Element => {
        return(
            <ActionArea category={SystemActionCategory.Process}>
                <ActionEntry name="ReloadExtension" label="Aggiorna stato estensioni"  isPromoted iconName="FabricSyncFolder" onClick={(req:any)=>{
                    reloadExtensionStatus();
                }}>
                </ActionEntry>
            </ActionArea>
        )
    }

    const reloadExtensionStatus = () => {
        const customerId = HttpHelper.getParameter('customerId');
        adminService.reloadExtensionStatus(customerId).then((result)=>{
            ToastService.showMessage(MessageType.Success,"Aggiornamento stato estensioni completato");
        })
        .catch(()=>{
            ToastService.showMessage(MessageType.Error,"Errore durante l'aggiornamento dello stato delle estensioni");
        })
    }

    return (
        <PageContainer endpoint={buildListEndpoint()} pageType={SystemPageType.List} title={listTitle} backAllowed>               
            <PageDefinitionContainer type={ContainerType.Action}>
                {showReloadExtensionAction && buildReloadExtensionAction()}
            </PageDefinitionContainer>
            <PageDefinitionContainer type={ContainerType.PageStructure}>
                <ListHeaderEntry name="Nome" fieldName="name" minWidth={100} maxWidth={200} />
                <ListHeaderEntry name="Versione" fieldName="version" minWidth={150} maxWidth={250} />
                <ListHeaderEntry name="Nuova Versione" fieldName="newVersion" minWidth={150} maxWidth={250} />
                <ListHeaderEntry name="Publisher" fieldName="publisher" minWidth={150} maxWidth={250} />
                <ListHeaderEntry name="Stato" fieldName="status" minWidth={150} maxWidth={250} onRender={(item: any, index, column) => {
                    if (item.status === 0)
                        return "Waiting"
                    else if (item.status === 1)
                        return "Publishing"
                    else if (item.status === 2)
                        return "Updating"
                    else if (item.status === 3)
                        return "Installing"
                    else if (item.status === 4)
                        return "Published"
                    else if (item.status === 5)
                        return "Installed"
                    else if (item.status === 6)
                        return "To update"
                    else if (item.status === 7)
                        return "Error"
                }}/>
                <ListHeaderEntry name="Messaggio di errore" fieldName="errorMessage" minWidth={150} maxWidth={250} />
                <ListHeaderEntry name="Nome Ambiente" fieldName="environmentName" minWidth={100} maxWidth={200} />
            </PageDefinitionContainer>
        </PageContainer>
    );
}