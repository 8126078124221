import React, {forwardRef} from "react";
import {SystemFieldType,SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export interface IAdminEmailTemplateProps {
    title: string;
}

export const AdminEmailTemplateLanguageCard = forwardRef<PageContainerBase, React.PropsWithChildren<IAdminEmailTemplateProps>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} endpoint="/odata/email/template/language" title={props.title} ref={ref}>
            <CardGroup name="general" label="General" isOpen={true}>
                <CardField name="emailTemplateCode" label="Codice Template" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/email/template" tableRelationKey="emailTemplateCode" tableRelationField="description"/>
                <CardField name="description" label="Descrizione" type={SystemFieldType.String} />
                <CardField name="emailTest" label="Test Email" type={SystemFieldType.String} />
                <CardField name="subject" label="Soggetto" type={SystemFieldType.String} />
                <CardField name="fileName" label="Nome File" type={SystemFieldType.String} />
                <CardField name="packageBlob" label="File" type={SystemFieldType.Blob} ext=".html,.htm" />
                <CardField name="useDynamics365Marketing" label="Usa Dynamics 365 Marketing" type={SystemFieldType.Boolean} />
                <CardField name="ignoreAttachments" label="Ignora allegati" type={SystemFieldType.Boolean} />
                <CardField name="doNotTracking" label="Non tracciare" type={SystemFieldType.Boolean} />
            </CardGroup>
            <CardGroup name="localization" label="Localizzazione" isOpen={true}>
                <CardField name="legalEntityCode" label="Legal entity" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="distributorCode" label="Distributore" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name"/>
                <CardField name="partnerBusinessContractCode" label="Partner business contract" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner/business/contract" tableRelationKey="code" tableRelationField="description"/>
                <CardField name="languageCode" label="Lingua" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/language" tableRelationKey="code" tableRelationField="name"/>
            </CardGroup>
        </PageContainer>
    );
});