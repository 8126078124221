import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { flow } from "lodash-es";
import { FrontEndLogEntry } from "./Model/DemoModels";

//#region Test traduzioni

i18next
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
        debug:true,
        fallbackLng:'en',
        resources:{
            en:{
                common:{
                    ApllicationNavBar:{
                        Text000001Err:"Error while loading supported languages. The language will be used.",
                        Text000001Action:"Language",
                        Text000002Action:"Logout",
                        Text000003Action:"Guide",
                        Text000004Action:"Find",
                        Text000005Action:"Comments & suggestions",
                        Text000006Action:"Demo center",
                        Text000007Action:"Navigate",
                        Text000008Action:"Actions",
                    },
                    loadingData: "Loading data...",
                    question: {
                        yes: "Yes",
                        no: "No"
                    },
                    noInformation: "No information",
                    PageContainer:{
                        Loader:{
                            messageContent1:"We are loading the requested page..."
                        },
                        SearchBox:{
                            placeholder:"Search a value"
                        },
                        CommonShareDialog:{
                            title:"Sharing content",
                            Dialog:{
                                TooltipHost:{
                                    content1:"Selecting this option the content sharing will open the card of the selected record in VIEW",
                                    content2:"Selecting this option the content sharing will open the card in INSERTION",
                                    content3:"Selecting this option the content sharing will open the card of the record selected in EDIT",
                                    content4:"By clicking on share the link will be copied to the clipboard, it will be enough to share it with anyone you want or paste it in a browser window"
                                },
                                sharePageText:"Share page",
                                shareVideoText:"Share video"
                            }
                        },
                        ConfirmDialog:{
                            subText:"Are you sure you want to proceed with the cancellation?",
                            cancelButtonText:"Cancel",
                            confirmButtonText:"Confirm"
                        },
                        card:{
                            primaryButtonText:"Save",
                            defaultButtonText:"Cancel",
                        },
                        Text00001Lbl:"Saving completed"
                    },
                    CommonTemplateBuilder:{
                        CrudAction:{
                            back:"Back",
                            view:"View",
                            new:"New",
                            edit:"Edit",
                            delete:"Delete",
                            refresh:"Refresh",
                            share:"Share"
                        }
                    },
                    ApplicationContainer:{
                        dataStatusText1:"We are loading user settings...",
                        dataStatusText2:"We are loading the user features...",
                        errorDialogText:"Some technical problems have occurred"
                    },
                    LoginBox:{
                        primaryButtonText:"Sign in with Microsoft",
                        separatorText:"Or",
                        linkText:"Insert your password and",
                        linkText2:"sign in with initial registration credentials",
                        helpText:"Can't Log In?",
                        emailText:"E-mail",
                        passwordText:"Password",
                        passwordText2:" (The password you entered during registration)",
                        primaryButtonText2:"Sign in",
                        primaryButtonText3:"Continue",
                        forgotPasswordText:"Forgot password?",
                        forgotPasswordLinkText:"Click here",
                        accountNeeded:"Don't have an account yet?",
                        register:"Sign In",
                        registerText:"There is no So Smart account associated with this email, register by clicking on the link below.",
                        loader:{
                            text:"Authentication in progress..."
                        },
                        LoginForgotPasswordModal:{
                            Dialog:{
                                title:"Forgot password?",
                                label:"Enter here the email linked to your account. \nWe will send you a link with which you can reset your password.",
                                primaryButtonText:"Send Link",
                                defaultButtonText:"Cancel"
                            }
                        },
                        Text00001lbl:"We sent you the email with the link to the email indicated"
                    },
                    ForgotPasswordBox:{
                        loaderMessage:"We are verifying the validity of the provided address",
                        passwordMessage:"Insert and confirm here your new password",
                        passwordMandatory:"The password is mandatory",
                        passwordValidation:"The passwords do not match",
                        passwordConfirmation:"Password reset succesfully",
                        passwordLabel:"Password",
                        confirmPasswordLabel:"Confirm password",
                        confirmButtonLabel:"Update Password",
                        Err00001Text:"The link you are trying to access is incorrect or no longer valid. Please repeat the procedure."
                    },
                    SmartLeadCard:{
                        RegisterContent:{
                            title:"Start your free trial of So Smart",
                            subtitle1:"and receive the white paper of the 10 steps with which",
                            subtitle2:"improves the well-being of your company!",
                            CardField:{
                                companyName:"Company name",
                                email:"E-mail",
                                password:"Password",
                                phone:"Phone",
                                promoCode:"Promo code",
                                registerRequest:"How can So Smart help you?",
                                dataNote:"By submitting this form, you confirm that you consent to the processing of personal data by So Smart, as described in ",
                                privacyPolicy:"Privacy Policy",
                                existingAccount:"Do you already have a So Smart account?",
                                login:"Login",
                            },
                            primaryButtonText:"Continue",
                            thankYouMessage:"Thank you for your request. You will receive a link with access to the environment to the email you have indicated",
                            Err00001Text:"Enter a value in the Company field",
                            Err00002Text:"Enter a value in the E-mail field",
                            Err00003Text:"Enter a value in the Phone field",
                            Err00004Text:"Enter a value in the Password field",
                            Err00005Text:"Tell us how we can help you",
                        }
                    },
                    GuidePanel:{
                        waitingMessage:"Wait...",
                        waitingDescription:"Loading data",
                        headerText:"Chatbot",
                        searchBoxPlaceholder:"Search the guide",
                        helperText:"Enter a topic and click ''Enter'' to search",
                        relatedContents:"Related contents",
                        emptyResults:"No results obtained",
                        errorMessage:"An error occurred while searching for articles",
                        emptyTextBox:"Enter a search criteria",
                        footerButton:"Open full guide"
                    },
                    qualification:{
                        Text0001Lbl: "Description",
                        Text0002Lbl: "Note",
                        Text0003Lbl: "Enter any notes to remember for the migration here",
                        Text0004Lbl: "Scenario",
                        Text0005Lbl: "Operative mode",
                        Text0006Lbl: "Recommended to",
                        Text0007Lbl: "Business Model",
                        Text0008Lbl: "Design build, Make to stock, Distribution, Services",
                        Text0009Lbl: "Description",
                        Text0010Lbl: "Ex. facilities/infrastructure, food, consulting",
                        Text0011Lbl: "Sales channel",
                        Text0012Lbl: "Ex. e-commerce, distributors, agents, retail",
                        Text0013Lbl: "Revenue ($ M)",
                        Text0014Lbl: "Immediate Go-Live!",
                        Text0015Lbl: "Buy now with a go-live within a few days",
                        Text0016Lbl: "50% discount for 6 months",
                        Text0017Lbl: "This plan is best for new businesses, start-ups, or those that don't currently have a business management system, and therefore have no (or very little) data to migrate into the new system.",
                        Text0018Lbl: "Standard Project Timeline",
                        Text0019Lbl: "Go-live within 2-4 weeks",
                        Text0020Lbl: "1 month free + 50% discount for 2 months",
                        Text0021Lbl: "Get the first month free while you are evaluating the system and getting everything setup and ready. No commitment and you can cancel before you have to pay anything.",
                        Text0022Lbl: "Extended Project Timeline",
                        Text0023Lbl: "Go-live within the first 2-4 months",
                        Text0024Lbl: "1 month free + 20% discount for 6 months (min. 2 users)",
                        Text0025Lbl: "This plan is better for companies that have multiple business models and/or would like to take more time to review and train on the new system before scheduling the go-live.",
                        Text0026Lbl: "Long term Engagement",
                        Text0027Lbl: "30 days free",
                        Text0028Lbl: "This is a good option if you are interested in So Smart and how it could potentially transform your business, but would like an evaluation period to learn more before you commit to a project timeline and go-live date.",
                        Text0029Lbl: "Migration of customer records",
                        Text0030Lbl: "Migration of vendor records",
                        Text0031Lbl: "Migration of open customer and vendor accounts",
                        Text0032Lbl: "Migration of accounting balances and assets",
                        Text0033Lbl: "Item master data migration",
                        Text0034Lbl: "Warehouse items migration",
                        Text0035Lbl: "Migration of items with bill of materials",
                        Text0036Lbl: "Company data",
                        Text0037Lbl: "What type of business are you?",
                        Text0038Lbl: "What features are you looking for?",
                        Text0039Lbl: "Do you have data to import from the current system?",
                        Text0040Lbl: "Have you set a deadline for the new system?",
                        Text0041Lbl: "Set up your free trial",
                        Text0042Lbl: "Not all fields have been filled in correctly",
                        Text0043Lbl: "So Smart lead qualification",
                        Text0044Lbl: "Lead qualification",
                        Text0045Lbl: "Project production",
                        Text0046Lbl: "Standard production",
                        Text0047Lbl: "Trade",
                        Text0048Lbl: "Service",
                        Text0049Lbl: "Not selected",
                        Text0050Lbl: "Cost per user first 6 months",
                        Text0051Lbl: "Savings per user in the first 6 months",
                        Text0052Lbl: "Setup your company",
                        Text0053Lbl: "Before filling out the questionnaire complete this short guided tour to see in detail all the information requested",
                        Text0054Lbl: "Enter the required data below so that we can help you design the solution that is best suited to for you.",
                        Text0055Lbl: "Please let us know what types of products or services your company provides to customers.  This will help us tailor our solution to best fit your individual needs.",
                        Text0056Lbl: "Select all the features your company needs to best carry out your business",
                        Text0057Lbl: "What are the three main objectives of implementing a new business management system.  Our coaches will work with you to make sure that we stay focused on achieving your goals. ",
                        Text0058Lbl: "Select which data you want imported from your old management system",
                        Text0059Lbl: "Our system will automatically estimate the amount of time you need to dedicate each day in order to achieve your go-live date.",
                        Text0060Lbl: "Enter how many end users you will need with your system, and your preferred deployment mode.",
                        Text0061Lbl: 'Enter here your current system you use to manage business processes',
                        Text0062Lbl: 'more details and/or questions.',
                        Text0063Lbl: 'At least one app must be selected',
                        Text0064Lbl: 'You must select one of the options in the list'
                    },
                    bookingList: {
                        loaderText: "We are loading the requested list...",
                        loaderText1: "Booking in progress...",
                        loaderText2: "Booking successful",
                        loaderText3: "Booking error: ",
                        title0: "Availability list ",
                        title1: "internal meeting ",
                        title2: "customer demo ",
                        title3: "for the Lead ",
                        actions: {
                          text0001lbl: "Book"
                        },
                        field: {
                          serviceName: "Service",
                          status: "State",
                          startDateTime: "Date hour",
                          serviceDuration: "Duration (min)",
                          Sunday: "Sunday",
                          Monday: "Monday",
                          Tuesday: "Tuesday",
                          Wednesday: "Wednesday",
                          Thursday: "Thursday",
                          Friday: "Friday",
                          Saturday: "Saturday"
                        }
                      }
                },
                demoCenter:{
                    Err00001Text:"Could not activate trial. Contact support",
                    Err00002Text:"You have already requested the activation of the free trial once.",
                    Text00001Lbl:"Trial successfully activated",
                    TeachingGuide:{
                        stepLabel:" of ",
                        entry:{
                            1:{
                                title:"Measure performance and grow your business!",
                                description:" Click on the report you are interested in to view the video explanation",
                                primaryButtonText:"Next",
                                secondaryButtonText:"close"
                            },
                            2:{
                                title:"Select the app you prefer to digitize your company",
                                description:"Click on the SoSmart app that interests you to discover its features and view the explanation videos",
                                primaryButtonText:"Next",
                                secondaryButtonText:"close"
                            },
                            3:{
                                title:"Improve your employees' productivity with a fully integrated system",
                                description:"Here on the left are the main Microsoft integrations you need to increase productivity. Click on the one you are interested in to view the explanation video",
                                primaryButtonText:"Next",
                                secondaryButtonText:"close"
                            },
                            4:{
                                title:"Flexible, no initial cost and easy to activate",
                                description:"Here you can find the steps to start with So-Smart. Click on the one that interests you to view the video explanation",
                                primaryButtonText:"Next",
                                secondaryButtonText:"close"
                            },
                            5:{
                                title:"Safe, reliable, always up to date",
                                description:"Here you can find the main advantages of the solution totally in Cloud",
                                primaryButtonText:"Next",
                                secondaryButtonText:"close"
                            },
                            6:{
                                title:"Check out our virtual assistant",
                                description:"Consult our virtual assistant to quickly find answers to your questions",
                                primaryButtonText:"Next",
                                secondaryButtonText:"close"
                            },
                            7:{
                                title:"Want to try SoSmart for 30 days?",
                                description:"Click on the pane to start your 30-day SoSmart trial",
                                primaryButtonText:"Complete",
                                secondaryButtonText:""
                            }
                        }
                    },
                    ErrorDialog:{
                        title:"Service currently unavailable",
                        subText:"We apologize for the inconvenience, unfortunately the service seems to be temporarily unavailable. Click on Update to try again or contact us at helpdesk@so-smart.it",
                        primaryButtonText:"Reload",
                    },
                    DemoContainer:{
                        showcaseProcessTitle:"Measure performance and grow your business!",
                        featuresOverviewTitle:"Select the app you prefer to digitize your company",
                        showcaseIntegratedSystemTitle:"Improve your employees' productivity with a fully integrated system",
                        timelineSoSmartTitle:"Flexible, no initial cost and easy to activate",
                        msFeaturesTitle:"Safe, reliable, always up to date",
                        Stack:{
                            bookingLabelContent:"Do you need a follow-up?",
                            bookingLabelLink:"Book a consultants",
                            featureOverviewContent:"The most voted features by our customers"
                        }
                    },
                    DemoTeachingQuestion:{
                        title:"Welcome",
                        subText:"Start the guided tour!",
                        primaryButtonText:"Ok",
                        defaultButtonText:"Skip"
                    },
                    DemoSalePopup:{
                        primaryButtonText:"Activate the free trial!"
                    },
                    ChatBot:{
                        title:"Ask me a question!",
                        BotFramework:{
                            watchVideoText:"The video will open on this page. Wait a few seconds!",
                            readWikiText:"The documentation URL will open in a new tab. \r\nWait a few seconds!"
                        },
                        Err00001Text:"Unable to open required video! Try again or if error persists contact support",
                        Err00002Text:"Error while loading the chatbot",
                    },
                    TrialModal:{
                        Dialog:{
                            title:"Customer information",
                            subText:"Enter your information here to activate your free SoSmart trial",
                            messageContent1:"Saving in progress...",
                            Content:{
                                companyName:"Company name",
                                name:"First name",
                                lastName:"Last name",
                                fullUsersNo:"Number of users",
                                password:"Password",
                                confirmPassword:"Confirm password",
                                address:"Address",
                                foreignAddress:"Foreign address",
                                city:"City",
                                country:"Country",
                                industry:"Industry",
                                privateEmail:"Email",
                                zipCode:"Zip code",
                                county:"County",
                                zipCodePlaceHolder:"Insert ZIP code to get City, County & Country datas"
                            },
                            FieldError:{
                                Err00001Text:"Specify a value for the first name field",
                                Err00002Text:"Specify a value for the last name field",
                                Err00003Text:"Specify a value for the company name",
                                Err00004Text:"Specify a value",
                                Err00005Text:"The two passwords do not coincide",
                                Err00006Text:"Select a company type",
                                Err00007Text:"Select an evaluation mode",
                            },
                            defaultIndustryType:"Select a sector",
                            primaryButtonText:"Save",
                            defaultButtonText:"Cancel"
                        }
                    },
                    BookingModal:{
                        title:"Book one of our consultants",
                        primaryButtonText:"Ok"
                    },
                    Loader:{
                        messageContent:"We're uploading data for your demo..."
                    }
                },
                customerCenter:{
                    CustomerApplicationContainer:{
                        dataStatusText1:"We are loading the customer configuration..."
                    },
                    UserProfileCard:{
                        welcomeTitle:"Welcome {{name}}",
                        welcomeDescription:"This is your personal dashboard! From here you can explore the features at your disposal",
                        progressIndicatorLabel:"Your score"
                    },
                    DashboardCommandBar:{
                        manageBillingData:"Manage billing data",
                        manageUsers:"Manage users",
                        manageSubscription:"Manage subscription",
                        viewIncidents:"View incidents",
                        purchase:"Buy"
                    },
                    DashboardMessageCard:{
                        completeTitle:"Congratulations you completed all the tasks",
                        completeContent:"In case there are new activities to do you will find them again here",
                        waitAdminTitle:"Administrator user waiting for task to complete",
                        waitAdminContent1:"We are waiting for the {{taskDescription}} task to be completed by an administrator user.",
                        waitAdminContent2:"We are waiting for the completion of the {{taskDescription}} task by the User: {{username}}"
                    },
                    TaskProgressCard:{
                        stepSubtext:"of"
                    },
                    DashboardContentContainer:{
                        envTitle:"Your environment",
                        appTitle:"Your applications",
                        adviceTitle:"Your OnBoarding Pathway",
                        friendContainer:{
                            adviceContentTitle:"Do you know anyone interested in So Smart?",
                            adviceContentDescription:"If you know someone who is or might be interested in So Smart, please let us know. You can refer them here:",
                            advicePrimaryButtonTitle:"Refer a friend"
                        },
                        CustomerSocialSlide:{
                            socialTitle:"Follow our social channels!",
                            socialDescription:"To keep up to date on our news and our new offers follow us on our social channels or go to our website!"
                        }
                    },
                    CustomerEnvironmentList:{
                        envButtonDescription:"Click here to access the environment",
                        noEnvFoundLabel:"Currently there is no environment ready to be used...",
                        Text00001Info:"Environment not yet active"
                    },
                    CommonGuideTourDialog:{
                        title:"Welcome, would you like to start the guided tour?",
                        subtext:"Click on 'Get Started' to discover the features of So Smart",
                        primaryButtonText:"Get started",
                        defaultButtonText:"Skip",
                        tourDefaultButtonText:"Close",
                        tourNextButtonText:"Next",
                        tourFinishButtonText:"Finish",
                        tourStepText:" of ",
                        tourFooterAction:"Further information",
                        guideTourEntry:{
                            1:{
                                title: "Your banner",
                                description: "Here you find the tasks assigned to you. Use the keys to run them.",
                                primaryButtonText:"Inizia",
                                defaultButtonText:"Salta"
                            },
                            2:{
                                title: "Your subscription",
                                description: "Click here to activate or modify your So Smart plan.",
                                primaryButtonText:"Inizia",
                                defaultButtonText:"Salta"
                            }
                        }
                    },
                    CustomerCreateLeadCard:{
                        title:"Refer a friend and get a discount on your subscription forever!",
                        note:"The discounted value is equal to 10% of the value of the customer's subscription that you will notify us until his subscription is active and will be deducted from your subscription. Hurry don't miss the opportunity. Valid until 31.8",
                        companyName:"Company name",
                        email:"E-Mail",
                        phone:"Phone",
                        primaryButtonText:"Save",
                        defaultButtonText:"Cancel"
                    },
                    CustomerBillingInfoCard:{
                        title:"Billing informations",
                        CardGroup:{
                            label:"General",
                            CardField:{
                                companyName:"Company name",
                                firstName:"First name",
                                lastName:"Last name",
                                phoneNo:"Phone",
                                streetAddress:"Street address",
                                zipCode:"Zip code",
                                city:"City",
                                county:"County",
                                country:"Country",
                                vat:"VAT",
                                sdi:"SDI",
                                pecEmail:"PEC",
                                foreignAddress: "Foreign address",
                                email:"E-mail",
                                legalRepresentativeFirstName: "Legal representative first name",
                                legalRepresentativeLastName: "Legal representative last name"
                            }
                        }
                    },
                    CustomerTenantSetupBox:{
                        dialogTitle:"My own Microsoft organization",
                        choiceLabel:"Do you have a Microsoft account?",
                        answer3Text:"Not sure if you have a Microsoft tenant? Click the button below to find out! You will be prompted to sign in with your corporate Microsoft account. If you don't have a Microsoft account then answer No to the question. If you have any doubts, please contact support",
                        answer1Text: "If you already have a Microsoft account, we ask you to log in with your corporate account so that we can acquire the information to identify your tenant",
                        messageBar:{
                            messageContent1:"Saving",
                            messageContent2:"Data acquisition of your organization in progress...",
                            messageContent3:"We have successfully acquired the data of your organization. Click on Ok to continue",
                        },
                        options:{
                            opt1:"Yes",
                            opt2:"No",
                            opt3:"I don't know"
                        },
                        primaryButtonText:"Ok",
                        Err00001Text:"The tenant data acquisition process failed. It's possible that you don't have a Microsoft tenant, you can continue with the procedure by selecting No as a response or contact support",
                        Err00002Text:"You must answer Yes or No in order to proceed.",
                        Err00003Text:"You must first complete the login with your Microsoft account by clicking on Sign In with Microsoft"
                    },
                    CustomerTenantDetailBox:{
                        MessageBar:{
                            messageContent1:"Saving",
                            messageContent2:"Reseller partner authorization not granted",
                            messageContent3:"Checking reseller authorization grant...",
                            messageContent4:"Reseller authorization successfully granted! Click Ok to continue.",
                            messageBarButtonText:"Update"
                        },
                        GDAPMessageBar:{
                            messageBarButtonText:"Update",
                            notAccepted:"Environment permissions not granted",
                            checking:"Checking environment permissions...",
                            notFound:"GDAP relationship not found",
                            accepted:"Environment permissions granted successfully! Click Ok to continue.",
                        },
                        Loader:{
                            text:"We are loading the domains connected to your tenant"
                        },
                        Dialog:{
                            title:"Organization info",
                            ComboBox:{
                                label:"Select the domain you want to assign to new users you will create via SoSmart"
                            },
                            partnerLabel:"Click here and authorize us as a reseller partner",
                            partnerLabelAccepted:"Step completed",
                            gdapLabel:"Click here and authorize us to work on your Business Central environments",
                            gdapLabelAccepted:"Step completed",
                            domainLabel:"Enter the domain you want to be used to create your Microsoft users",
                            sendEmailLabel:"Not a Microsoft organization administrator? Send an email to an administrator",
                            sendEmailAction:" by clicking here.",
                            sendEmailBtn:"Send",
                            sendEmailTxtPlaceholder:"Enter the email address of the administrator user here",
                            successLabel:"Message sent!",
                            errorMailLabel:"Address must be set",
                            partnerDescription:"We need you to add us as a reseller in order to get Business Central Trial licenses, click the link below to proceed:",
                            gdapDescription:"We need you to grant us permissions to work on your Business Central environments, manage users and related So Smart licenses, click on the link below to proceed:"
                        },
                        Err00001Text:"You must choose a domain for your Microsoft organization"
                    },
                    CustomerSetupCompanyList:{
                        MessageBar:{
                            messageContent1:"Saving"
                        },
                        Dialog:{
                            title:"Company info",
                            DetailList:{
                                name:"Name",
                                TextField:{
                                    placeholder:"Enter the company name (maximum 30 characters)"
                                }
                            }
                        }
                    },
                    CustomerUserSetupList:{
                        MessageBar:{
                            messageContent1:"Saving",
                        },
                        Dialog:{
                            title:"User management",
                            primaryButtonText:"Save"
                        },
                        CustomerUserList:{
                            CustomerUserListActions:{
                                new:{
                                    text:"New",
                                    cardTitle:"Create user"
                                },
                                edit:{
                                    text:"Edit",
                                    cardTitle:"Edit - {{name}}"
                                },
                                update:{
                                    text:"Update",
                                    cardTitle:"Update"
                                },
                                LicenseAssign:{
                                    text:"Assign licenses",
                                    cardTitle:"Manage licenses - {{name}}"
                                },
                                delete:{
                                    text:"Delete",
                                    ConfirmDialog:{
                                        title:"Data wiping",
                                        subText:"Are you sure you want to proceed with user deletion {{name}}?",
                                        cancelButtonText:"Cancel",
                                        confirmButtonText:"Confirm",
                                        LoaderMessage:{
                                            messageContent1:"User cancellation in progress ...",
                                            messageContent2:"Loading user data in progress ...",
                                            Text00001Lbl:"User successfully deleted",
                                            Err00001Text:"Error in cancellation of customer"
                                        }
                                    },
                                    LoaderMessage:{
                                        messageContent1:"Loading user data in progress ..."
                                    }
                                },
                                PasswordReset:{
                                    text:"Reset password",
                                    cardTitle:"Reset password - {{name}}"
                                }
                            },
                            listTitle:"Users",
                            DetailList:{
                                columns:{
                                    firstName:"First name",
                                    lastName:"Last name",
                                    displayName:"Dislay name",
                                    userPrincipleName:"Account Microsoft name",
                                    mainUser:"So Smart Administrator",
                                }
                            },
                            CustomerUserCard:{
                                MessageBar:{
                                    messageContent1:"Saving"
                                },
                                Dialog:{
                                    CardGroup:{
                                        label:"General",
                                        CardField:{
                                            firstName:"First name",
                                            lastName:"Last name",
                                            displayName:"Display name",
                                            phone:"Phone",
                                            userPrincipleName:"Account Microsoft name",
                                            mainUser:"So Smart Administrator",
                                        }
                                    },
                                    primaryButtonText:"Save",
                                    defaultButtonText:"Cancel"
                                }
                            },
                            CustomerLicenseCard:{
                                Dialog:{
                                    DetailList:{
                                        columns:{
                                            product:"Product",
                                            availableLicences:"Available licenses"
                                        }
                                    },
                                    primaryButtonText:"Save",
                                    defaultButtonText:"Cancel"
                                }
                            },
                            CustomerResetPasswordCard:{
                                Dialog:{
                                    labelAutomaticEmail:"The user \"{{email}}\" will be sent an email with the new temporary password that must be changed at the first access.",
                                    labelAdditionalAddress:"If you would like to notify another email address of the password change, enter it below.",
                                    labelNotificationEmail:"Notification Email:",
                                    title:"Reset password for \"{{name}}\"",
                                },
                                primaryButtonText:"Request password",
                                defaultButtonText:"Cancel"
                            },
                            Text00001lbl:"Saved successfully",
                            LoaderMessage:{
                                loadingUserLicenses:"Loading available licenses, wait please ...",
                                savingUser:"Saving user ...",
                                savingLicenses:"Saving licenses ...",
                                resettingPassword:"Resetting password ...",
                                resettedPassword:"Password resetted succesfully"
                            }
                        }
                    },
                    CustomerMainUserSetupCard:{
                        MessageBar:{
                            messageContent1:"Saving"
                        },
                        Loader:{
                            text:"We are loading the domains connected to your tenant",
                            textSyncUser:"We are syncing users from your tenant, please wait ..."
                        },
                        Dialog:{
                            title:"Administrator user",
                            ComboBox:{
                                label:"Select the administrator user"
                            }
                        },
                        CardGroup:{
                            label:"General",
                            CardField:{
                                firstName:"First name",
                                lastName:"Last name",
                                displayName:"Display name",
                                userPrincipleName:"Account Microsoft name",
                                phone: "Phone number"
                            }
                        },
                        Err00001Text:"You must select a user to continue",
                        Err00002Text:"Set the field Name",
                        Err00003Text:"Set the field Last name",
                        Err00004Text:"Set the field Display name",
                        Err00005Text:"Set the Microsoft Account Name field",
                        Err00006Text:"The permissions to synchronize users are not yet active, close the tab and try again in a few minutes."
                    },
                    CustomerVideoModal:{
                        Err00001Text:"Could not load video",
                        Err00002Text:"It would seem that the shared video does not exist or is not working, contact support for more information",
                    },
                    CustomerIncidentList:{
                        title:"Ticket list",
                        columns:{
                            userDisplayName:"Username",
                            itemDescription:"App",
                            title:"Title",
                            description:"Description",
                            createdOn:"Created on",
                            stateDescription:"State",
                            crmStatusDescription:"Progress",
                            crmIncidentTypeName:"Type",
                            uploadState:{
                                description:"CRM upload state",
                                onQueue:"On queue",
                                uploaded:"Uploaded",
                                inError:"In Error"
                            }
                        },
                        CustomerIncidentCard:{
                            title:"Create a ticket",
                            CardGroup:{
                                label:"General",
                                CardField:{
                                    title:"Title",
                                    titlePlaceHolder:"Insert a title",
                                    contactEmail:"Email",
                                    description:"Description",
                                    ticketType:"Ticket type",
                                    sourceTitle:"Source title",
                                    sourceAddress:"Source address",
                                    lastError:"Last error",
                                    sourceApplicationOrigin:"Source application origin",
                                    attachment:"Attachment",
                                    attachmentButton:"Add attachment",
                                    validation:{
                                        title:"Title is mandatory",
                                        description:"Description is mandatory",
                                        email:"Email is mandatory",
                                        descriptionLength:"Description too large, if you added an image, delete it and add it as an attachment",
                                        itemRequired:"App is mandatory",
                                        emailOnMicrosoftError:"Invalid domain (onmicrosoft.com), enter a valid contact email"
                                    }
                                }
                            }
                        },
                        Text00001lbl:"Creating tickets in progress, the operation may take a few seconds",
                        Err00001lbl:"User not allowed to create tickets. Contact support",
                        Err00002lbl:"Unable to open ticket creation page",
                        extNotSupported:"You can't attach files with this extension: ",
                        fileMaxWeight:"Every attachment can weigh a maximum of 5 MB",
                        fileMaxCount:"You can attach up to 5 files",
                        ticketSuccesfullyCreated:"Ticket succesfully created"
                    },
                    CustomerHelpPageMultipleMode:{
                        title:"SUPPORT REQUEST",
                        subTitle:"Need help? Ask our virtual agent! If you don't find an answer to your questions you can open a support ticket from the form below.",
                        primaryButtonText:"Save",
                        defaultButtonText:"Cancel",
                        customActionText:"Create a ticket",
                    },
                    CustomerHelpPageSingleMode:{
                        title:"SUPPORT REQUEST",
                        subTitle:"Need help? Ask our virtual agent!",
                        primaryButtonText:"Save",
                        defaultButtonText:"Cancel",
                        customActionText:"Create a ticket",
                    },
                    BusinessCentralTelemetry:{
                        taskTitle:"Completed tasks",
                        feedbackTitle:"Positive feedback",
                        waitTitle:"Ops...",
                        waitSubTitle:"Statistics will be displayed as soon as your environments are ready, come back later."
                    },
                    Err00001Text:"Error: Unable to load content",
                    Err00002Text:"Your user must be an administrator to perform this action",
                    Err00003Text:"Warning: You must verify your billing details before continuing",
                    Err00004Text:"Fill in all the fields of the page",
                    Err00005Text:"VAT not valid",
                    Text00001Lbl:"We are redirecting you to the shop, please wait...",
                    Text00002Lbl:"Saving completed",
                    Text00003Lbl:"Incorrect or missing data",
                    messageContent1:"We are loading your dashboard..."
                },
                demoRoleCenter:{
                    banner:{
                        title:"Welcome to So Smart!",
                        subTitle:"This is a demonstration dashboard. To start a free trial with your company information complete the tasks on the right. We hope you enjoy browsing",
                        rightContent:{
                            title:"Here are some useful features for discovering So Smart",
                        },
                        gtTitle:"Start guided tour"
                    },
                    cueBox:{
                        actionText:"Other informations"
                    },
                    bookingModal:{
                        title:"Book a consultant",
                    },
                    embeddedHelp:{
                        demoButtonText:"Back to the demo experience",
                        defaultButtonText:"Close"
                    },
                    loadingDescription:"We're loading your dashboard...",
                    defaultVideoDescription:"How it works",
                    findOutMore:"Find out more"
                },
                docs:{
                    banner: {
                      subTitle: "Welcome! Explore our help portal",
                      buttonText: "Explore the site"
                    },
                    AnswerContainer: {
                      Text000001Lbl: "Has it responded to your support request?",
                      Text000002Lbl: "You did not find what you were looking for? ",
                      Text000003Lbl: "by clicking here",
                      Text000001Err: "Feature not supported."
                    },
                    Site: {
                      Text000001Lbl: "SEARCH RESULTS",
                      Text000002Lbl: "Search by title...",
                      Text000003Lbl: "Updating the page...",
                      Text000004Lbl: "PLAY",
                      Text000001Err: "The portal is not available",
                      Text000002Err: "Refresh the page if the problem persists",
                      Text000003Err: "contact us here"
                    },
                    KnownledgeRenderer: {
                      Text000001Lbl: "Page loading...",
                      Text000001Err: "The page is not available!",
                      Text000002Err: "Try accessing other content"
                    },
                    FeedbackDialog: {
                      Text000001Lbl: "Rate it from 1 to 5",
                      Text000002Lbl: "Share more information",
                      Text000001Btn: "Submit",
                      title:"Was this page helpful?"
                    }
                },
                chatbot: {
                    chat:{
                        title:"Start chatting",
                        subTitle:"This chatbot is configured to answer your questions",
                        error:"Error",
                        errorMessage:"An error occurred. Please try again. If the problem persists, please contact the site administrator.",
                        supportError:"Sorry we could not find any information about it",
                        generateText:"Generating answer...",
                        stopGenerating:"Stop generating",
                        questionInput:"Type a question...",
                        citationDialog:"Citations",
                        assistantEmailMessage:"Ok let's start. Which is your e-mail address?",
                        assistantEmailError:"E-mail {{email}} is not valid, please check and insert a valid e-mail",
                        assistantOtherTxt:"If you have other questions, write them down on this chat",
                        clearChatTxt:"Clear chat",
                        supportTxt:"Open support request",
                        welcomeMessage: "### Hello, how can I help you? \n Write your question in chat and I will answer it as soon as possible. \n\n If my answer is not satisfactory, you can write **[support](\"\")** in chat or click on the \"**?**\" button in the lower left corner to open a support request.",
                        ticketCreationErrorMessage: "Sorry, it's impossible to create a support request now",
                        notFoundResponse: "I'm sorry, I have not found any content to your request"
                    },
                    layout:{
                        shareAction:"Share",
                        shareDialog:{
                            title:"Share the web app",
                            copyText:"Copy URL",
                            copiedText:"Copied URL"
                        }
                    },
                    answer: {
                        aiDisclamer: "AI-generated content may be incorrect",
                        like: "Right answer",
                        dislike: "Wrong answer",
                        citationCount:" references",
                        citationOne:"1 reference",
                        citationPart: "Part",
                        citationCaption: "Citation",
                        followupQuestions: " follow up questions"
                    },
                    openSupportRequest: {
                        title: "Open a support request",
                        btnTxt: "Open",
                        messageTxt: "Do you want to open a support request on this topic?",
                        sessionInformation: "Session information",
                        supportQuestion: "Do you need support for this error?",
                        ticketCreationQuestion: "Do I proceed to send your request to the support team?",
                        ticketCreationError: "I'm sorry but I was unable to create the support request. Wait a few minutes and try again.",
                        updateSupportEntryError: "I'm sorry but there are connection problems with our services. Wait a few minutes and try again.",
                        feedbackMessage: "Thanks for the feedback! If you have other questions, write in the chat!",
                        dataRequestText: "Enter the data requested below relating to the support request",
                        area: "Area",
                        process: "Process",
                        action: "Action",
                        selectAreaError: "Choose an area",
                        satisfied: "I am satisfied",
                        openTicket: "Open support request",
                        page: "Inside the page",
                        useActionLC: "action",
                        useActionUC: "Action",
                        useProcessLC: "in the process",
                        useProcessUC: "Processo",
                        useModuleLC: "of module",
                        useModuleUC: "Module",
                        listQuestion: "I have the following question:",
                        listError: "I have the following error:",
                        tenantId: "Tenant ID",
                        environment: "Environment",
                        company: "Company",
                        pageInfo: "Page",
                        license: "License",
                        successTitle: "Support request created",
                        successText: "Our support team will reach you out by e-mail.",
                        successSubText: "Can I help you with anything else?",
                        flowCommercial: "Commercial",
                        flowTechnical: "Technical",
                        alreadyInstalledApp: "Have you already installed the app ", 
                    },
                    supportTypeForm:{
                        title:"Select the type of support you need",
                        configuration:"Configuration",
                        training:"Training",
                        customization:"Customization",
                        bug:"Bug"
                    },
                    customerIdForm:{
                        title:"Insert your customer id",
                        subTitle:"If you need more information",
                        link:"click here"
                    },
                    installationTypeForm:{
                        title:"Select your Business Central installation type"
                    },
                    lastErrorConfirmText:{
                        title: "Would you like to open a request based on this error?"
                    },
                    panel: {
                        headline: "Do you need further information? Try it out our chatbot!",
                        subText: "Our chatbot will answer to all your questions, you need only to type them into the chat."
                    },
                    testConfirmText:{
                        title: "Are you in this context?"
                    },
                    questionText:{
                        title: "Describe your issue"
                    },
                    subscriptionTypeForm:{
                        title: "Select your subscription type",
                        full:"Full",
                        free:"Free"
                    },
                    appVersionForm:{
                        title:"Insert your app version"
                    },
                    supportRequestTextForm: {
                        title: "Insert a description of your request"
                    },
                    successForm: {
                        title: "Your support request has been sent successfully!"
                    },
                    eosAppForm: {
                        title:"Search and click on the app related to your request",
                        placeholder:"Search your app..."
                    },
                    eosAppWelcome: {
                        header: "👋 Welcome!",
                        selection: "👍 You have selected #APPDESCRIPTION#, please write in chat your questions",
                        title:"Select for which apps do you need support, if you want to make a generic question please write me on chat",
                        placeholder:"Search your app...",
                        prefix: "I need support for #APP#, my question is: #QUESTION#"
                    }
                },
                leadDashboard: {
                    banner:{
                        title:"Welcome to So Smart!",
                        subTitle:"The world's only business management software solution that allows you to pay per month with no setup or implementation costs, and no long term commitment.  Powered by Microsoft's award winning Dynamics 365 Business Central ERP solution, considered the best business management solution for small businesses.  You can start using the solution for as little as $104/user/month."
                    },
                    checklist:{
                        contentEntryTitle: 'Congratulations!',
                        contentEntryText: 'You have completed all the assigned tasks, now you can use So Smart products to the fullest!',
                        nextButtonText: 'Next'
                    },
                    leadModal: {
                        title: 'Set up your company',
                        subTitle: 'Enter your information below to begin the So Smart experience',
                        primaryButtonText: 'Next',
                        saveButtonText: 'Save',
                        defaultButtonText: 'Cancel',
                        field:{
                            companyName: 'Company name',
                            industry: 'Industry',
                            firstname: 'First name',
                            lastname: 'Last name',
                            mail: 'E-Mail',
                            fullUsersNo: 'Users no.',
                            foreignAddress: 'Foreign address',
                            address: 'Address',
                            zipCode: 'Zip code',
                            city: 'City',
                            county: 'County',
                            country: 'Country',
                            decisionDate: 'Decision date',
                            decisionDatePlaceHolder: 'Insert a date by which you intend to decide to adopt So Smart for your company',
                            expectedLiveDate: 'Live Date',
                            expectedLiveDatePlaceHolder: 'Insert a date by which you intend to go live with So Smart',
                            rating: 'Temperature',
                            currentSystem: 'Current system',
                            revenue: 'Revenue',
                            evaluationMode:{
                                title:'Your So Smart',
                                label:'Choose your preferred mode of evaluation',
                                text00001Lbl: 'Self service',
                                text00002Lbl: 'With a consultant',
                                tooltipText1: 'You will use the system without expert consultant support',
                                tooltipText2: 'One of our expert consultants will be available to help you in the process of evaluating our solution, proceed to book the first appointment in the next step',
                            },
                            businessNeeds:{
                                title: 'Your goals',
                                subTitle: 'Enter the functionality needed to launch your solution here',
                                text00001Lbl: 'Goal 1 (E.g. Make my users more productive, Post-sales management, Cash flow analysis)',
                                text00002Lbl: 'Goal 2 (E.g. Job management, E-commerce integration)',
                                text00003Lbl: 'Goal 3 (E.g. Automatic procurement proposal, Resource planning, Move everything to the cloud)'
                            }
                        }
                    },
                    dashboardLink:{
                        title: 'Useful Links',
                        extendedTitle: 'Use these links to navigate through the different features available to you'
                    },
                    errors:{
                        text00001Err: 'Mandatory value',
                        text00002Err: 'Unable to save lead configuration',
                        text00003Err: 'Configuration mode not allowed',
                        text00004Err: 'Unable to upload zip codes',
                        text00005Err: 'Unable to create shop link',
                        text00006Err: 'Unable to find onboarding worksheet for this lead'
                    },
                    messages:{
                        text00001msg: 'Loading in progress...',
                        text00002msg: 'Link creation in progress...',
                        text00003msg: 'Link created correctly, you will be redirected in a few moments'
                    },
                    buttonText: 'Next'
                },
                adminCenter: {
                    loaderText: "We are loading your dashboard...",
                    newTrial: "New trials",
                    newTrialDescription: "In the last week the following were created: {{value}} new trials",
                    newCustomer: "New customers",
                    newCustomerDescription: "In the last 30 days they have been acquired {{value}} new customers",
                    revenue: "Revenue",
                    revenueDescription: "In the last 30 days the revenue was {{value}} €",
                    subscription: "Subscriptions: {{value}} €",
                    subscriptionDescription: "New subscriptions have been created in the last 30 days {{value}} €",
                    cueCustomer: "Customer",
                    cueIndicatorRisk: "Risk indicators",
                    cueLeadLost: "Lost leads",
                    cueNotQualifiedLead: "Unqualified leads",
                    cueTrialLost: "Trial lost",
                    cueCustomerRisk: "Customers at risk",
                    cueCustomerLost: "Lost customers",
                    generalIndicators: "General indicators",
                    cueLeadWin: "Leads won",
                    cueActiveLeads: "Active leads",
                    cueLeadParked: "Lead Parked",
                    cueActiveCustomer: "Active customers",
                    cueSubscription: "Subscriptions",
                    cueInvoice: "Invoices",
                    cueBaseUnsignedContracts: "Basic contracts to sign",
                    cueSendNamirialFeContract: "FE contracts to send to Namirial",
                    cueFeUnsignedContracts: "FE contracts to sign",
                    cueCustomerSignedContract: "Signed contracts",
                    leadList: {
                        loaderText: "We are loading the requested list...",
                        listErrorText: "Lead list not available",
                        title: "Lead list",
                        title1: " active",
                        title2: " Park",
                        title3: " total",
                        text0001Err: "Unable to view the lead list, contact support",
                        text0002Err: "Warning: CRM data could not be retrieved, actions based on this may not work",
                        text0001Msg: "We are synchronizing the requested data ...",
                        text0002Msg: "Synchronization completed",
                        text0003Err: "Warning: Data could not be synchronized: ",
                        text0003Msg: "We are syncing the data required for the lead ",
                        text0004Msg: "We are syncing the lead ",
                        text0005Msg: "We are syncing all leads...",
                        text0006Msg: "Synchronizing interactions...",
                        text0007Msg: "Interaction synchronization complete",
                        text0004Err: "Warning: There were some problems syncing interactions",
                        text0008Msg: "Sending login email for {{mail}} in progress",
                        text0009Msg: "Login email sent successfully",
                        text0010Msg: "Saving active leads...",
                        text0011Msg: "Saving Active Leads completed",
                        text0005Err: "Warning: error saving active leads: ",
                        actions: {
                            leadQualification: "Qualify leads",
                            qualifierPage: "OnBoarding worksheet",
                            openShopAddress: "Configure offer",
                            openQuoteOffer: "Open qualification offer",
                            openLeadIndustry: "Lead business lines",
                            openEmailList: "Emails Sent",
                            openLossRiskLine: "Loss Risk Lines",
                            openInteractionList: "CRM interactions",
                            openQualifiedAnswer: "Qualification answers",
                            openAutomatioProspect: "Automation Prospect",
                            renegerateLeadToken: "Send login email",
                            openBooking: "Book a consultant",
                            syncCalculatedData: "Update KPIs",
                            syncSpecificCalculatedData: "Update KPIs for specific Lead",
                            syncCRMData: "Sync All Leads",
                            syncSpecificCRMData: "Sync Specific Lead",
                            syncCRMInteractionAll: "Sync Interactions for all Leads",
                            syncCRMInteractionLead: "Sync Interaction for specific Lead",
                            saveActiveLeads: "Save Active Leads",
                            legalEntityFilter: "Toggle legal entity filter",
                        },
                        fields: {
                            statusCode: "DS status",
                            firstname: "First name",
                            lastname: "Last name",
                            companyName: "Company name",
                            email: "E-mail",
                            partnerId: "Partner",
                            liveMode: "Live mode",
                            liveMode0: "Not selected",
                            liveMode1: "Live in a few days",
                            liveMode2: "Short-term change",
                            liveMode3: "Evaluation of all features",
                            liveMode4: "Evaluation without clear objectives",
                            partnerBusinessContractCode: "Partner business contract",
                            businessLineCode: "Business lines",
                            origin: "Origin",
                            campaignCode: "Campaign code",
                            score: "Scores",
                            budget: "Budget",
                            logEntry: "View log entry",
                            createdOn: "Creation date",
                            marketingQualificationCarriedOut: "Marketing qualification completed",
                            lastInboundEmailDate: "Last inbound email date",
                            lastActivityDate: "Last interaction date",
                            lastActivityType: "Last interaction type",
                            lastActivityType0: "E-mail",
                            lastActivityType1: "Note",
                            lastActivityType2: "Appointment",
                            lastActivityType3: "Post",
                            lastActivityType4: "Tasks",
                            lastActivityType5: "Phone call",
                            lastWeekInteractions: "Interactions last week",
                            lastMonthInteractions: "Interactions last month",
                            demoBooked: "Demo booked",
                            demoDate: "Demo date",
                            demoCarriedOut: "Demo done",
                            demoCarriedOutBy: "Demo done by",
                            demoFeedbackRequired: "Demo feedback requested",
                            demoFeedbackReceived: "Demo feedback received",
                            trialRequestedDate: "Trial requested date",
                            trialStartDate: "Trial start date",
                            riskLineExists: "Presence of loss risks",
                            riskLineHandled: "Loss risks managed",
                            offerLink: "Offer link",
                            salesHeaderTemplateCode: "Offer code",
                            owningUserDisplayName: "Owner User",
                            bookingModalTitle: "Book one of our consultants"
                        }
                    },
                    leadCard: {
                        title: "Leads card",
                        general: "General",
                        companyName: "Company name",
                        email: "E-mail",
                        firstname: "First name",
                        lastname: "Last name",
                        phone: "Telephone",
                        tokenForDemo: "Demo tokens",
                        statusCode: "DS status",
                        score: "Scores",
                        subject: "Object",
                        owningUserId: "Owner user",
                        localization: "Location",
                        legalEntityCode: "Legal entity",
                        distributorCode: "Distributor",
                        partnerId: "Partner",
                        partnerBusinessContractCode: "Partner business contract",
                        businessLineCode: "Business lines",
                        languageCode: "Language",
                        address: "Address",
                        zipCode: "POSTAL CODE",
                        city: "City",
                        county: "Province/State",
                        country: "Village",
                        marketingQualification: "Marketing qualification",
                        salesHeaderTemplateId: "Sales Header Template",
                        origin: "Origin",
                        campaignCode: "Campaing code",
                        originName: "Source name",
                        noOfCompany: "Company No",
                        full_Users_No: "No. of full users",
                        limited_User_No: "Limited number of users",
                        note: "Note",
                        marketingQualificationCarriedOut: "Marketing qualification completed",
                        monthlyBudget: "Monthly budget",
                        noOfEmployees: "Number of employees",
                        digitalLevel: "Level of digitalisation",
                        currentERPName: "Current ERP",
                        otherConnectedSystems: "Other interfaced systems",
                        decisionDate: "Decision date",
                        expectedLiveDate: "Live expectation date",
                        ourAgentRole: "SoSmart agent role",
                        demoPartecipants: "Demo participants",
                        decisionMakerPath: "Decision path",
                        freeTrialInterest: "Interested in the free trial",
                        isAlreadyCustomer: "He is already a customer",
                        activeMicrosoftServices: "MS services already active",
                        registerRequest: "Registration request",
                        interactionsDetail: "Interaction detail",
                        demoBooked: "Demo booked",
                        demoDate: "Demo date",
                        internalMeetingBooked: "Internal meeting booked",
                        internalMeetingDate: "Internal meeting date",
                        demoCarriedOut: "Demo done",
                        demoCarriedOutBy: "Demo done by",
                        notManagedGAPEmerged: "Unmanaged GAPs emerged",
                        negotiationState: "Negotiation status",
                        rating: "Rating",
                        rating0: "Cold",
                        rating1: "Lukewarm",
                        rating2: "Hot",
                        evaluationMode: "Evaluation methods",
                        evaluationMode0: "Not indicated",
                        evaluationMode1: "Self service",
                        evaluationMode2: "With a consultant",
                        liveMode: "Live mode",
                        liveMode0: "Not selected",
                        liveMode1: "Live in a few days",
                        liveMode2: "Short-term change",
                        liveMode3: "Evaluation of all features",
                        liveMode4: "Evaluation without clear objectives",
                        leadAutomation: "Lead automation",
                        lastActivityDate: "Last interaction date",
                        lastActivityType: "Last interaction type",
                        lastActivityType0: "E-mail",
                        lastActivityType1: "Note",
                        lastActivityType2: "Appointment",
                        lastActivityType3: "Post",
                        lastActivityType4: "Tasks",
                        lastActivityType5: "Phone call",
                        lastInboundEmailDate: "Last inbound email date",
                        lastWeekInteractions: "Interactions last week",
                        lastMonthInteractions: "Interactions last month",
                        demoFeedbackRequired: "Demo feedback requested",
                        demoFeedbackReceived: "Demo feedback received",
                        demoFeedbackRating: "Rating feedback demo",
                        riskLineExists: "Presence of loss risks",
                        riskLineHandled: "Loss risks managed",
                        offer: "Offer",
                        offerLink: "Offer link",
                        offerSent: "Offer sent",
                        promoCode: "Promo code",
                        mailCodeTemplate: "Mail template code",
                        salesHeaderTemplateCode: "Offer code",
                        customer: "Customer",
                        isTrial: "Trial customer",
                        trialRequestedDate: "Trial requested date",
                        trialStartDate: "Trial start date",
                        createdOn: "Creation date",
                        finalOutcome: "Final result",
                        finalOutcome1: "Won",
                        finalOutcome2: "Lost",
                        finalOutcome3: "Not qualified",
                        lossCauseCode: "Cause loss",
                        crmStatusCode: "Cause of disqualification",
                        interestConfirmed: "Interest confirmed",
                        firstLoginCompleted: "First login completed",
                        setupCompanyCompleted: "Setup company completed",
                        shopOpened: "Shop opened",
                        parked: "Parked",
                    },
                    salesHeaderList: {
                        title: "Order Template",
                        loaderText: "We are loading the requested list, please wait...",
                        dialogTitle: "Choose the Customer",
                        dialogSubtext: "You must enter the Customer to continue",
                        dialogTxtLabel: "Customer:",
                        text0001Err: "Error recovering customers",
                        text0001Msg: "Subscription Creation for {{customerName}} in progress ...",
                        text0002Msg: "Subscription creation completed successfully",
                        text0002Err: "Error creating Subscription: ",
                        text0003Err: "You must enter a valid Customer",
                        text0004Err: "You must select a template to continue",
                        actions: {
                            openLineTemplateList: "Order template detail",
                            systemCaption: "Translations",
                            createCustomerSubscriptionFromTemplate: "Create customer subscription from template"
                        },
                        fields: {
                            id: "ID",
                            createdAt: "Created on",
                            expirationDate: "Expiry date",
                            source: "Source",
                            source0: "Web",
                            source1: "Industry",
                            source2: "Qualification",
                            code: "Code",
                            description: "Description",
                            budgetPerFullUser: "Full user budget",
                            budgetPerLimitedUser: "Limited user budget"
                        }
                    },
                    leadIndustryList: {
                        title: "Business lines of: ",
                        fields: {
                            salesChannel: "Sales channel",
                            description: "Description",
                            typologyId: "Typology",
                            typologyId0: "Not selected",
                            typologyId1: "Project production",
                            typologyId2: "Standard production",
                            typologyId3: "Trade",
                            typologyId4: "Service",
                            revenue: "Sales"
                        }
                    },
                    leadIndustryCard: {
                        title: "Business line card",
                        general: "General",
                        salesChannel: "Sales channel",
                        description: "Description",
                        typologyId: "Typology",
                        typologyId0: "Not selected",
                        typologyId1: "Project production",
                        typologyId2: "Standard production",
                        typologyId3: "Trade",
                        typologyId4: "Service",
                        revenue: "Sales"
                    },
                    frontEndLogEntryList: {
                        title: "Log Dashboard Demo ",
                        fields: {
                            appId: "App ID",
                            description: "Description",
                            referenceId: "Reference ID",
                            userId: "User ID",
                            systemCreationDate: "System Creation Date"
                        }
                    },
                    emailLogList: {
                        title: "Log email list - leads: ",
                        loaderText: "We are loading the requested list...",
                        fields: {
                            templateCode: "Template code",
                            legalEntityCode: "Legal entity",
                            distributorCode: "Distributor",
                            partnerBusinessContractCode: "Partner business contract",
                            languageCode: "Language",
                            toAddress: "Sent to",
                            subject: "Subject",
                            body: "Body",
                            userId: "User ID",
                            id: "ID",
                            systemCreationDate: "System Creation Date"
                        }
                    },
                    lossRiskLineList: {
                        title: "Loss Risk Line List",
                        fields: {
                            lossCauseDescription: "Cause Loss",
                            description: "Description",
                            notes: "Note",
                            processModule: "Process Module",
                            processCode: "Process Code",
                            lineScore: "Score Row",
                            handled: "Managed",
                            handleDate: "Date Management",
                            sourceTable: "Source Table",
                            sourceId: "Source ID",
                            sourceExternalId: "External Source ID",
                            entryNo: "Entry no"
                        }
                    },
                    lossRiskLineCard: {
                        title: "Loss Risk lines card",
                        fields: {
                            lossCauseDescription: "Cause Loss",
                            description: "Description",
                            notes: "Note",
                            processModule: "Process Module",
                            processCode: "Process Code",
                            lineScore: "Score Row",
                            handled: "Managed",
                            handleDate: "Date Management",
                            sourceTable: "Source Table",
                            sourceId: "Source ID",
                            sourceExternalId: "External Source ID",
                            entryNo: "Movement no"
                        }
                    },
                    crmInteractionList: {
                        title: "CRM Lead Interactions List",
                        fields: {
                            leadId: "Lead ID",
                            type: "Typology",
                            type0: "E-mail",
                            type1: "Note",
                            type2: "Appointment",
                            type3: "Post",
                            type4: "Tasks",
                            type5: "Phone call",
                            title: "Title",
                            description: "Description",
                            crmReferenceStartDate: "Start date",
                            crmReferenceEndDate: "End Date",
                            interactionSource: "Origin",
                            interactionSource0: "Customer",
                            interactionSource1: "Helpdesk",
                            createdOn: "Creation date",
                            percentComplete: "Percent Completion",
                            durationMinutes: "Duration (Minutes)",
                            phoneNumber: "Telephone number",
                            userDisplayName: "User"
                        }
                    },
                    crmInteractionCard: {
                        title: "CRM Lead Interactions tab",
                        fields: {
                            leadId: "Lead ID",
                            type: "Typology",
                            type0: "E-mail",
                            type1: "Note",
                            type2: "Appointment",
                            type3: "Post",
                            type4: "Tasks",
                            type5: "Phone call",
                            title: "Title",
                            description: "Description",
                            crmReferenceStartDate: "Start date",
                            crmReferenceEndDate: "End Date",
                            interactionSource: "Origin",
                            interactionSource0: "Customer",
                            interactionSource1: "Helpdesk",
                            createdOn: "Creation date",
                            percentComplete: "Percent Completion",
                            durationMinutes: "Duration (Minutes)",
                            phoneNumber: "Telephone number",
                            userDisplayName: "User"
                        }
                    },
                    leadQualifiedAnswerList: {
                        title: "Answers Qualify Lead ",
                        loaderText: "We are loading the requested data...",
                        fields: {
                            answerCode: "Response code",
                            answerType: "Response type",
                            description: "Description",
                            score: "Scores",
                            note: "Note",
                            wizardCode: "Note"
                        }
                    },
                    leadAutomationProspect: {
                        title: "Lead automation prospect",
                        loaderText: "Loading page...",
                        text0001Err: "Error loading page",
                        text0001Msg: "Accepting message",
                        text0002Msg: "Message acceptance completed successfully",
                        text0002Err: "Error accepting message: ",
                        text0003Msg: "Accepting message",
                        text0004Msg: "Message acceptance completed successfully",
                        text0003Err: "Error accepting message: ",
                        actions: {
                            openCrmLead: "CRM Leads",
                            openLead: "Leads card",
                            qualifierPage: "Worksheet leads",
                            openProspect: "Prospect setup",
                            openProspectLeadAutomation: "Setup prospect lead automation",
                            calculateSuggestedLines: "Calculate suggested rows",
                            registerRow: "Accept message",
                            rejectRow: "Reject message"
                        },
                        fields: {
                            actionType: "Action type",
                            reason: "Reason",
                            ownerType: "Owner type",
                            actionOwnerName: "Action owner name",
                            actionTemplate: "Action template",
                            leadPartnerBusinessContractCode: "Partner business contract",
                            leadName: "Leads",
                            leadEmail: "Email Leads",
                            leadSubject: "Lead object",
                            leadCreatedOn: "Lead creation date",
                            leadScore: "Score Leads",
                            leadBudget: "Budget Leads",
                            leadModelScoreName: "Model score name",
                            leadModelScore: "Model scores",
                            leadRating: "Rating Leads",
                            leadRating0: "Cold",
                            leadRating1: "Lukewarm",
                            leadRating2: "Hot",
                            leadId: "Lead Id",
                            crmLeadId: "Lead CRM Id",
                            leadStatusDescription: "DS Lead",
                            leadOwnerName: "Owner Name Lead",
                            demoDate: "Demo date",
                            trialStartDate: "Trial start date",
                            decisionDate: "Decision date",
                            expectedLiveDate: "Live expectation date",
                            lastInboundEmailDate: "Last inbound email date",
                            lastActivityDate: "Last interaction date",
                            lastActivityType: "Last interaction type",
                            lastActivityType0: "E-mail",
                            lastActivityType1: "Note",
                            lastActivityType2: "Appointment",
                            lastActivityType3: "Post",
                            lastActivityType4: "Tasks",
                            lastActivityType5: "Phone call",
                            nextActivityDate: "Next interaction date",
                            nextActivityType: "Like next interaction",
                            nextActivityType0: "E-mail",
                            nextActivityType1: "Note",
                            nextActivityType2: "Appointment",
                            nextActivityType3: "Post",
                            nextActivityType4: "Tasks",
                            nextActivityType5: "Phone call",
                            autoAccept: "Automatic acceptance",
                            previousRegisterCount: "Number of previous registrations",
                            lastWeekInteractions: "Interactions last week",
                            lastMonthInteractions: "Interactions last month",
                            lineError: "Mistake",
                            leadViewCode: "Lead Report Code",
                            lineNo: "Line No."
                        }
                    }
                }
            },
            it:{
                common:{
                    ApllicationNavBar:{
                        Text000001Err:"Errore durante il caricamento delle lingue supportate. Verrà utilizzata la lingua di default.",
                        Text000001Action:"Lingua",
                        Text000002Action:"Esci",
                        Text000003Action:"Guida",
                        Text000004Action:"Cerca",
                        Text000005Action:"Commenti e suggerimenti",
                        Text000006Action:"Portale demo",
                        Text000007Action:"Naviga",
                        Text000008Action:"Azioni",
                    },
                    loadingData: "Stiamo caricando i dati...",
                    question: {
                        yes: "Sì",
                        no: "No"
                    },
                    noInformation: "Nessuna informazione",
                    PageContainer:{
                        Loader:{
                            messageContent1:"Stiamo caricando la pagina richiesta..."
                        },
                        SearchBox:{
                            placeholder:"Ricerca un valore"
                        },
                        CommonShareDialog:{
                            title:"Condivisione contenuto",
                            Dialog:{
                                TooltipHost:{
                                    content1:"Selezionando questa opzione la condivisione del contenuto aprirà la card del record selezionato in VISUALIZZAZIONE",
                                    content2:"Selezionando questa opzione la condivisione del contenuto aprirà la card in INSERIMENTO",
                                    content3:"Selezionando questa opzione la condivisione del contenuto aprirà la card del record selezionato in MODIFICA",
                                    content4:"Cliccando su condividi il link verrà copiato negli appunti,sarà sufficente condividerlo con chiunque si voglia oppure incollarlo in una finestra del browser"
                                },
                                sharePageText:"Condividi pagina",
                                shareVideoText:"Condividi video"
                            }
                        },
                        ConfirmDialog:{
                            subText:"Sei sicuro di voler procedere con la cancellazione?",
                            cancelButtonText:"Annulla",
                            confirmButtonText:"Conferma"
                        },
                        card:{
                            primaryButtonText:"Salva",
                            defaultButtonText:"Annulla",
                        },
                        Text00001Lbl:"Salvataggio completato"
                    },
                    CommonTemplateBuilder:{
                        CrudAction:{
                            back:"Indietro",
                            view:"Visualizza",
                            new:"Nuovo",
                            edit:"Modifica",
                            delete:"Cancella",
                            refresh:"Aggiorna",
                            share:"Condividi"
                        }
                    },
                    ApplicationContainer:{
                        dataStatusText1:"Stiamo caricando le impostazioni utente...",
                        dataStatusText2:"Stiamo caricando le funzionalità utente...",
                        errorDialogText:"Si sono verifica alcuni problemi tecnici"
                    },
                    LoginBox:{
                        primaryButtonText:"Accedi con Microsoft",
                        separatorText:"Oppure",
                        linkText:"Inserisci la tua password e",
                        linkText2:"accedi con le credenziali iniziali di registrazione",
                        helpText:"Non riesci ad accedere?",
                        emailText:"E-mail",
                        passwordText:"Password",
                        passwordText2:" (La password che hai inserito in fase di registrazione)",
                        primaryButtonText2:"Accedi",
                        primaryButtonText3:"Continua",
                        forgotPasswordText:"Hai dimenticato la password?",
                        forgotPasswordLinkText:"Clicca qui",
                        accountNeeded:"Non hai ancora un account?",
                        register:"Registrati",
                        registerText:"Non risulta un account So Smart con questa email, registrati cliccando sul link qui sotto.",
                        loader:{
                            text:"Autenticazione in corso..."
                        },
                        LoginForgotPasswordModal:{
                            Dialog:{
                                title:"Password dimenticata?",
                                label:"Inserisci qua l'email collegata alla tua utenza. \nTi invieremo un link con il quale potrai reimpostare la tua password.",
                                primaryButtonText:"Invia link",
                                defaultButtonText:"Annulla"
                            }
                        },
                        Text00001lbl:"Ti abbiamo inviato l'email con il link all'email indicata"
                    },
                    ForgotPasswordBox:{
                        loaderMessage:"Stiamo verificando la validità dell'indirizzo fornito",
                        passwordMessage:"Inserisci e conferma qui la tua nuova password",
                        passwordMandatory:"La password è obbligatoria",
                        passwordValidation:"Le password non coincidono",
                        passwordConfirmation:"Password reimpostata correttamente",
                        passwordLabel:"Password",
                        confirmPasswordLabel:"Conferma password",
                        confirmButtonLabel:"Conferma",
                        Err00001Text:"Il link a cui stai accedendo non è corretto o non è più valido. Rieseguire la procedura"
                    },
                    SmartLeadCard:{
                        RegisterContent:{
                            title:"Avvia la tua prova gratuita di So Smart",
                            subtitle1:"e ricevi il white paper dei 10 passi con cui",
                            subtitle2:"migliora il benessere della tua azienda!",
                            CardField:{
                                companyName:"Nome società",
                                email:"E-mail",
                                password:"Password",
                                phone:"Telefono",
                                promoCode:"Codice promo",
                                registerRequest:"Come può aiutarti So Smart?",
                                dataNote:"Inviando questo modulo, confermi di acconsentire al trattamento dei dati personali da parte di So Smart, come descritto nella ",
                                privacyPolicy:"Privacy Policy",
                                existingAccount:"Hai già un account So Smart?",
                                login:"Accedi",
                            },
                            primaryButtonText:"Continua",
                            thankYouMessage:"Grazie per la tua richiesta. Riceverai un link con l'accesso all'ambiente alla mail che ci hai indicato",
                            Err00001Text:"Inserisci il nome della Società",
                            Err00002Text:"Inserisci una E-mail valida",
                            Err00003Text:"Inserisci un numero di telefono",
                            Err00004Text:"Inserisci una password",
                            Err00005Text:"Indica come ti possiamo aiutare",
                        }
                    },
                    GuidePanel:{
                        waitingMessage:"Attendere...",
                        waitingDescription:"Caricamento dati in corso",
                        headerText:"Chatbot",
                        searchBoxPlaceholder:"Cerca nella guida",
                        helperText:"Inserisci un argomento e clicca ''Invio'' per ricercare",
                        relatedContents:"Contenuti correlati",
                        emptyResults:"Nessun risultato ottenuto",
                        errorMessage:"Si è verificato un errore nella ricerca degli articoli",
                        emptyTextBox:"Inserire un criterio di ricerca",
                        footerButton:"Apri guida completa"
                    },
                    qualification:{
                        Text0001Lbl: "Descrizione",
                        Text0002Lbl: "Note",
                        Text0003Lbl: "Inserisci qui le note da ricordare per l'importazione",
                        Text0004Lbl: "Scenario",
                        Text0005Lbl: "Modalità operativa",
                        Text0006Lbl: "Consigliato a",
                        Text0007Lbl: "Tipologia di prodotto",
                        Text0008Lbl: "Produzione a progetto, Produzione standard, Commercio, Servizio",
                        Text0009Lbl: "Descrizione",
                        Text0010Lbl: "Es. impianti/infrastrutture, alimentari, consulenza",
                        Text0011Lbl: "Canale di vendita",
                        Text0012Lbl: "Es. e-commerce, distributori, agenti, dettaglio",
                        Text0013Lbl: "Fatturato (€ M)",
                        Text0014Lbl: "Live immediato",
                        Text0015Lbl: "Acquisto subito con live in pochi giorni",
                        Text0016Lbl: "50% sconto per 6 mesi",
                        Text0017Lbl: "chi non ha un gestionale, chi ha appena costituito una nuova azienda, chi ha un software gestionale obsoleto in dismissione, chi vuole attuare un cambio radicale e crede fortemente nel digitale",
                        Text0018Lbl: "Necessità di un cambiamento in breve termine",
                        Text0019Lbl: "1 mese gratis + 2 mesi",
                        Text0020Lbl: "1 mese gratis + 50% sconto per 2 mesi",
                        Text0021Lbl: "chi crede nell'evoluzione digitale di tutti i processi aziendali, chi necessita di un cambiamento",
                        Text0022Lbl: "Valutazione di tutte le funzionalità con calma",
                        Text0023Lbl: "1 mese gratis + 6 mesi",
                        Text0024Lbl: "1 mese gratis + 20% sconto per 4 mesi (min. 2 utenti)",
                        Text0025Lbl: "chi vuole cambiare, ma è spaventato dell'impatto di questo sugli utenti, chi vuole valutare tutti i processi gestiti",
                        Text0026Lbl: "Valutazione senza obiettivi chiari",
                        Text0027Lbl: "30 giorni gratis",
                        Text0028Lbl: "chi crede nel digitale e vuole provare la soluzione in autonomia",
                        Text0029Lbl: "Migrazione anagrafiche clienti",
                        Text0030Lbl: "Migrazione anagrafiche fornitori",
                        Text0031Lbl: "Migrazione partite aperte clienti e fornitori",
                        Text0032Lbl: "Migrazione saldi contabili e cespiti",
                        Text0033Lbl: "Migrazione anagrafiche articoli",
                        Text0034Lbl: "Migrazione giacenze di magazzino",
                        Text0035Lbl: "Migrazione articoli con distinta base",
                        Text0036Lbl: "Dati aziendali",
                        Text0037Lbl: "In quale settore opera la tua azienda?",
                        Text0038Lbl: "Quali funzionalità stai cercando?",
                        Text0039Lbl: "Hai dati da importare dall'attuale sistema?",
                        Text0040Lbl: "Ti sei dato una scadenza?",
                        Text0041Lbl: "Configura la tua prova gratuita",
                        Text0042Lbl: "Non tutti i campi sono stati compilati correttamente",
                        Text0043Lbl: "Qualifica lead So Smart",
                        Text0044Lbl: "Qualifica lead",
                        Text0045Lbl: "Produzione a progetto",
                        Text0046Lbl: "Produzione standard",
                        Text0047Lbl: "Commercio",
                        Text0048Lbl: "Servizio",
                        Text0049Lbl: "Non selezionato",
                        Text0050Lbl: "Costo a utente primi 6 mesi",
                        Text0051Lbl: "Risparmio a utente nei primi 6 mesi",
                        Text0052Lbl: "Configura la tua azienda",
                        Text0053Lbl: "Prima di compilare il questionario completa questo breve tour guidato per vedere nel dettaglio tutte le informazioni richieste",
                        Text0054Lbl: "Inserisci tutti i dati richiesti per scoprire la soluzione più adatta a te",
                        Text0055Lbl: "Indica quali sono i prodotti o i servizi forniti dalla tua azienda per consentirci di classificare il tuo business e guidarti nel tuo progetto So Smart. Sono previste più righe per chi lavora con più modelli di business.",
                        Text0056Lbl: "Seleziona tutte le funzionalità di cui la tua azienda necessita per svolgere al meglio il tuo business",
                        Text0057Lbl: "Spiegaci quali sono i tre principali obiettivi che vorresti raggiungere con l'utilizzo del nuovo gestionale",
                        Text0058Lbl: "Seleziona quali dati vuoi vengano importati dal tuo vecchio gestionale",
                        Text0059Lbl: "Indica quali sono le tempistiche che hai previsto per il cambio gestionale. ",
                        Text0060Lbl: "Indica quanti utenti e in quale modalità vuoi attivare la tua prova gratuita So Smart",
                        Text0061Lbl: 'Inserisci qui il tuo attuale sistema che utilizzi per gestire i processi aziendali',
                        Text0062Lbl: 'maggiori dettagli e/o domande.',
                        Text0063Lbl: 'Selezionare almeno un\'app dalla lista',
                        Text0064Lbl: 'Selezionare una delle opzioni della lista'
                    },
                    bookingList: {
                        loaderText: "Stiamo caricando la lista richiesta ...",
                        loaderText1: "Prenotazione in corso...",
                        loaderText2: "Prenotazione avvenuta con successo",
                        loaderText3: "Errore nella prenotazione: ",
                        title0: "Lista disponibilità ",
                        title1: "meeting interno ",
                        title2: "demo cliente ",
                        title3: "per la Lead ",
                        actions: {
                            text0001lbl: "Prenota"
                        },
                        field: {
                            serviceName: "Servizio",
                            status: "Stato",
                            startDateTime: "Data / ora",
                            serviceDuration: "Durata (min)",
                            Sunday: "Domenica", 
                            Monday: "Lunedì", 
                            Tuesday: "Martedì", 
                            Wednesday: "Mercoledì", 
                            Thursday: "Giovedì", 
                            Friday: "Venerdì", 
                            Saturday: "Sabato"
                        }
                    }
                },
                demoCenter:{
                    Err00001Text:"Impossibile attivare la trial. Contattare l'assistenza",
                    Err00002Text:"Hai già richiesto una volta l'attivazione della prova gratuita.",
                    Text00001Lbl:"Trial attivata con successo",
                    TeachingGuide:{
                        stepLabel:" di ",
                        entry:{
                            1:{
                                title:"Misura le performance e fai crescere il tuo business!",
                                description:" Clicca sul report che ti interessa per visualizzare il video di spiegazione",
                                primaryButtonText:"Avanti",
                                secondaryButtonText:"Chiudi"
                            },
                            2:{
                                title:"Seleziona l'app che preferisci per digitalizzare la tua azienda",
                                description:"Clicca sull'app SoSmart che ti interessa per scoprire le sue funzioni e visualizzare i video di spiegazione",
                                primaryButtonText:"Avanti",
                                secondaryButtonText:"Chiudi"
                            },
                            3:{
                                title:"Migliora la produttività dei tuoi dipendenti grazie a un sistema completamente integrato",
                                description:"Qui a sinistra trovi le principali integrazioni Microsoft che ti servono per aumentare la produttività. Clicca su quella che ti interessa per visualizzare il video di spiegazione",
                                primaryButtonText:"Avanti",
                                secondaryButtonText:"Chiudi"
                            },
                            4:{
                                title:"Flessibile, nessun costo iniziale e facile da attivare",
                                description:"Qui trovi i passaggi previsti per partire con So-Smart. Clicca su quello che ti interessa per visualizzare il video di spiegazione",
                                primaryButtonText:"Avanti",
                                secondaryButtonText:"Chiudi"
                            },
                            5:{
                                title:"Sicuro, affidabile, sempre aggiornato",
                                description:"Qui trovi i principali vantaggi della soluzione totalmente in Cloud",
                                primaryButtonText:"Avanti",
                                secondaryButtonText:"Chiudi"
                            },
                            6:{
                                title:"Scopri il nostro assistente virtuale",
                                description:"Consultando il nostro assistente virtuale potrai trovare velocemente le risposte alle tue domande",
                                primaryButtonText:"Avanti",
                                secondaryButtonText:"Chiudi"
                            },
                            7:{
                                title:"Vuoi provare SoSmart per 30 giorni?",
                                description:"Clicca sul riquadro per iniziare la tua prova di SoSmart di 30 giorni",
                                primaryButtonText:"Completa",
                                secondaryButtonText:""
                            }
                        }
                    },
                    ErrorDialog:{
                        title:"Servizio attualmente indisponibile",
                        subText:"Ci scusiamo per il disagio, purtroppo il servizio sembra essere temporaneamente indisponibile. Cliccare su Aggiorna per riprovare oppure contattare l\'assitenza scrivendoci all\'indirizzo helpdesk@so-smart.it",
                        primaryButtonText:"Aggiorna",
                    },
                    DemoContainer:{
                        showcaseProcessTitle:"Misura le performance e fai crescere il tuo business!",
                        featuresOverviewTitle:"Seleziona l'app che preferisci per digitalizzare la tua azienda",
                        showcaseIntegratedSystemTitle:"Migliora la produttività dei tuoi dipendenti grazie a un sistema completamente integrato",
                        timelineSoSmartTitle:"Flessibile, nessun costo iniziale e facile da attivare",
                        msFeaturesTitle:"Sicuro, affidabile, sempre aggiornato",
                        Stack:{
                            bookingLabelContent:"Hai bisogno di un approfondimento?",
                            bookingLabelLink:"Prenota un nostro consulente",
                            featureOverviewContent:"Le funzionalità più votate dai nostri clienti"
                        }
                    },
                    DemoTeachingQuestion:{
                        title:"Benvenuto",
                        subText:"Inizia il tour guidato!",
                        primaryButtonText:"Ok",
                        defaultButtonText:"Salta"
                    },
                    DemoSalePopup:{
                        primaryButtonText:"Attiva la prova gratuita!"
                    },
                    ChatBot:{
                        title:"Fai una domanda!",
                        BotFramework:{
                            watchVideoText:"Il video verrà aperto in questa pagina. Attendi qualche secondo!",
                            readWikiText:"L'URL relativo alla documentazione verrà aperto in una nuova scheda. \r\nAttendi qualche secondo!"
                        },
                        Err00001Text:"Impossibile aprire il video richiesto! Riprovare o se l'errore persiste contattare l'assistenza",
                        Err00002Text:"Errore durante il caricamento del chatbot"
                    },
                    TrialModal:{
                        Dialog:{
                            title:"Informazioni cliente",
                            subText:"Inserisci qui le tue informazioni per attivare la tua prova gratuita di SoSmart",
                            messageContent1:"Salvataggio in corso...",
                            Content:{
                                companyName:"Ragione sociale",
                                name:"Nome",
                                lastName:"Cognome",
                                fullUsersNo:"Numero utenti",
                                password:"Password",
                                confirmPassword:"Conferma password",
                                address:"Indirizzo",
                                foreignAddress:"Indirizzo estero",
                                city:"Città",
                                country:"Stato",
                                industry:"Settore",
                                privateEmail:"Email",
                                zipCode:"CAP",
                                county:"Provincia",
                                zipCodePlaceHolder:"Inserisci il CAP per i dati di Comune, Provincia e Nazione"
                            },
                            FieldError:{
                                Err00001Text:"Specificare un valore per il campo Nome",
                                Err00002Text:"Specificare un valore per il campo Cognome",
                                Err00003Text:"Specificare un valore per la ragione sociale",
                                Err00004Text:"Specificare un valore",
                                Err00005Text:"Le due password non coincidino",
                                Err00006Text:"Selezionare una tipologia di azienda",
                                Err00007Text:"Selezionare una modalità di valutazione",
                            },
                            defaultIndustryType:"Seleziona un settore",
                            primaryButtonText:"Salva",
                            defaultButtonText:"Annulla"
                        }
                    },
                    BookingModal:{
                        title:"Prenota un nostro consulente",
                        primaryButtonText:"Ok"
                    },
                    Loader:{
                        messageContent:"Stiamo caricando i dati per la tua demo..."
                    }
                },
                customerCenter:{
                    CustomerApplicationContainer:{
                        dataStatusText1:"Stiamo caricando la configurazione cliente..."
                    },
                    UserProfileCard:{
                        welcomeTitle:"Salve {{name}}",
                        welcomeDescription:"Questa è la tua dashboard personale! Da qui puoi esplorare le funzionalità a tua disposizione",
                        progressIndicatorLabel:"Il tuo punteggio"
                    },
                    DashboardCommandBar:{
                        manageBillingData:"Gestisci dati di fatturazione",
                        manageUsers:"Gestisci utenti",
                        manageSubscription:"Gestisci sottoscrizione",
                        viewIncidents:"Ticket assistenza",
                        purchase:"Acquista"
                    },
                    DashboardMessageCard:{
                        completeTitle:"Complimenti hai completato tutte le attività",
                        completeContent:"Nel caso ci fossero nuove attività da svolgere le troverai nuovamente qui",
                        waitAdminTitle:"Attesa completamento di un'attività da parte dell'utente amministratore",
                        waitAdminContent1:"Siamo in attesa del completamento del task {{taskDescription}} da parte di un utente amministratore.",
                        waitAdminContent2:"Siamo in attesa del completamento del task {{taskDescription}} da parte dell'utente: {{username}}"
                    },
                    TaskProgressCard:{
                        stepSubtext:"di"
                    },
                    DashboardContentContainer:{
                        envTitle:"I tuoi ambienti",
                        appTitle:"Le tue app",
                        adviceTitle:"Il tuo percorso di OnBoarding",
                        friendContainer:{
                            adviceContentTitle:"Conosci qualcuno interessato a So Smart?",
                            adviceContentDescription:"Se conosci qualcuno che è interessato a So Smart o potrebbe esserlo, segnalacelo! Puoi eseguire la segnalazione da qui:",
                            advicePrimaryButtonTitle:"Segnalaci un amico"
                        },
                        CustomerSocialSlide:{
                            socialTitle:"Segui i nostri canali social!",
                            socialDescription:"Per essere sempre aggiornato sulle novità e le nostre nuove offerte seguici sui nostri canali Social oppure vai sul nostro sito web!"
                        }
                    },
                    CustomerEnvironmentList:{
                        envButtonDescription:"Clicca qui per accedere all'ambiente",
                        noEnvFoundLabel:"Attualmente non c'è alcun ambiente pronto per essere utilizzato...",
                        Text00001Info:"Ambiente non ancora attivo"
                    },
                    CommonGuideTourDialog:{
                        title:"Benvenuto, vuoi iniziare il tour guidato?",
                        subtext:"Clicca su 'Inizia' per scoprire le funzionalità di So Smart",
                        primaryButtonText:"Inizia",
                        defaultButtonText:"Salta",
                        tourDefaultButtonText:"Chiudi",
                        tourNextButtonText:"Avanti",
                        tourFinishButtonText:"Fine",
                        tourStepText:" di ",
                        tourFooterAction:"Ulteriori informazioni",
                        guideTourEntry:{
                            1:{
                                title: "Il tuo banner",
                                description: "Qui trovi le attività a te assegnate. Utilizza i tasti per eseguirle.",
                                primaryButtonText:"Inizia",
                                defaultButtonText:"Salta",
                            },
                            2:{
                                title: "Il tuo abbonamento",
                                description: "Clicca qui per attivare o modificare il tuo piano So Smart.",
                                primaryButtonText:"Inizia",
                                defaultButtonText:"Salta",
                            }
                        }
                    },
                    CustomerCreateLeadCard:{
                        title:"Segnalaci un amico e ricevi uno sconto sulla tua sottoscrizione per sempre!",
                        note:"Il valore scontato è pari al 10% del valore della sottoscrizione del cliente che ci segnalerai fino a che sarà attivo il suo abbonamento e verrà scalato dal tuo abbonamento. Affrettati non perdere l'occasione. Valida fino al 31.8",
                        companyName:"Nome società",
                        email:"E-Mail",
                        phone:"Telefono",
                        primaryButtonText:"Salva",
                        defaultButtonText:"Annulla"
                    },
                    CustomerBillingInfoCard:{
                        title:"Dati di fatturazione",
                        CardGroup:{
                            label:"Generale",
                            CardField:{
                                companyName:"Ragione sociale",
                                firstName:"Nome",
                                lastName:"Cognome",
                                phoneNo:"Telefono",
                                streetAddress:"Indirizzo",
                                zipCode:"C.A.P.",
                                city:"Città",
                                county:"Provincia",
                                country:"Nazione",
                                vat:"Partita IVA",
                                sdi:"SDI",
                                pecEmail:"PEC",
                                foreignAddress: "Indirizzo estero",
                                email:"E-mail",
                                legalRepresentativeFirstName: "Nome legale rappresentante",
                                legalRepresentativeLastName: "Cognome legale rappresentante"
                            }
                        }
                    },
                    CustomerTenantSetupBox:{
                        dialogTitle:"La mia organizzazione Microsoft",
                        choiceLabel:"Possiedi un account Microsoft?",
                        answer3Text:"Non sai se possiedi un tenant Microsoft? Per scoprirlo clicca sul pulsante qua sotto! Ti verrà richiesto di effettuare un accesso con il tuo account Microsoft aziendale. Nel caso non avessi alcun account Microsoft allora rispondi No alla domanda. Nel caso avessi dubbi ti inviatiamo a contattare l'assistenza",
                        answer1Text: "Se possiedi già un account Microsoft ti chiediamo di eseguire l'accesso con il tuo account aziendale in modo da poter acquisire le informazioni per indentificare il tuo tenant",
                        messageBar:{
                            messageContent1:"Salvataggio in corso",
                            messageContent2:"Acquisizione dei dati della tua organizzazione in corso...",
                            messageContent3:"Abbiamo acquisito correttamente i dati della tua organizzazione. Clicca su Ok per continuare",
                        },
                        options:{
                            opt1:"Si",
                            opt2:"No",
                            opt3:"Non so"
                        },
                        primaryButtonText:"Ok",
                        Err00001Text:"La procedura di acquisizioni dati del tenant non è andata a buon fine. E' possibile che tu non possieda una tenant Microsoft, puoi continuare con la procedura selezionando No come risposta o contatta l'assistenza",
                        Err00002Text:"Devi rispondere Sì oppure No per poter procedere.",
                        Err00003Text:"Devi prima completare l'accesso con il tuo account Microsoft cliccando su Sign In with Microsoft"
                    },
                    CustomerTenantDetailBox:{
                        MessageBar:{
                            messageContent1:"Salvataggio in corso",
                            messageContent2:"Autorizzazione partner rivenditore non concessa",
                            messageContent3:"Controllo concessione autorizzazione rivenditore in corso...",
                            messageContent4:"Autorizzazione rivenditore concessa correttamente! Clicca su Ok per continuare.",
                            messageBarButtonText:"Aggiorna"
                        },
                        GDAPMessageBar:{
                            messageBarButtonText:"Aggiorna",
                            notAccepted:"Autorizzazioni ambienti non concesse",
                            checking:"Controllo autorizzazioni ambienti in corso...",
                            notFound:"Relazione GDAP non trovata",
                            accepted:"Autorizzazioni ambienti concesse correttamente! Clicca su Ok per continuare.",
                        },
                        Loader:{
                            text:"Stiamo caricando i domini collegati al tuo tenant"
                        },
                        Dialog:{
                            title:"Informazioni organizzazione",
                            ComboBox:{
                                label:"Seleziona il dominio che vuoi assegnare ai nuovi utenti che creerai tramite SoSmart"
                            },
                            partnerLabel:"Clicca qui e autorizzaci come partner rivenditore",
                            partnerLabelAccepted:"Step completato",
                            gdapLabel:"Clicca qui e autorizzaci a lavorare sui tuoi ambienti",
                            gdapLabelAccepted:"Step completato",
                            domainLabel:"Inserisci il dominio che vuoi che venga usato per la creazione dei tuoi utenti Microsoft",
                            sendEmailLabel:"Non sei amministratore dell'organizzazione Microsoft? Invia una mail a un amministratore",
                            sendEmailAction:" cliccando qui.",
                            sendEmailBtn:"Invia",
                            sendEmailTxtPlaceholder:"Inserisci qui l'indirizzo mail dell'utente amministratore",
                            successLabel:"Messaggio inviato!",
                            errorMailLabel:"Indirizzo mail non valido",
                            partnerDescription:"Abbiamo bisogno che tu ci aggiunga come rivenditore per poterti procurare le licenze Trial di Business Central, clicca sul link qui sotto per procedere:",
                            gdapDescription:"Abbiamo bisogno che tu ci conceda i permessi per lavorare sui tuoi ambienti Business Central, gestire gli utenti e le relative licenze So Smart, clicca sul link qui sotto per procedere:"
                        },
                        Err00001Text:"Devi scegliere un dominio per la tua organizzazione Microsoft"
                    },
                    CustomerSetupCompanyList:{
                        MessageBar:{
                            messageContent1:"Salvataggio in corso"
                        },
                        Dialog:{
                            title:"Informazioni società",
                            DetailList:{
                                name:"Nome",
                                TextField:{
                                    placeholder:"Inserisci il nome della società (massimo 30 caratteri)"
                                }
                            }
                        }
                    },
                    CustomerUserSetupList:{
                        MessageBar:{
                            messageContent1:"Salvataggio in corso",
                        },
                        Dialog:{
                            title:"Gestione utenti",
                            primaryButtonText:"Salva"
                        },
                        CustomerUserList:{
                            CustomerUserListActions:{
                                new:{
                                    text:"Nuovo",
                                    cardTitle:"Creazione utente"
                                },
                                edit:{
                                    text:"Modifica",
                                    cardTitle:"Modifica utente - {{name}}"
                                },
                                update:{
                                    text:"Aggiorna",
                                    cardTitle:"Aggiorna"
                                },
                                LicenseAssign:{
                                    text:"Assegna licenze",
                                    cardTitle:"Gestisci licenze - {{name}}"
                                },
                                delete:{
                                    text:"Elimina",
                                    ConfirmDialog:{
                                        title:"Cancellazione dati",
                                        subText:"Sei sicuro di voler procedere con la cancellazione dell'utente {{name}}?",
                                        cancelButtonText:"Annulla",
                                        confirmButtonText:"Conferma",
                                        LoaderMessage:{
                                            messageContent1:"Cancellazione utente in corso ...",
                                            messageContent2:"Caricamento dati utenti in corso ...",
                                            Text00001Lbl:"Utente eliminato correttamente",
                                            Err00001Text:"Errore nella cancellazione del cliente"
                                        }
                                    },
                                    LoaderMessage:{
                                        messageContent1:"Caricamento dati utenti in corso ..."
                                    }
                                },
                                PasswordReset:{
                                    text:"Reset password",
                                    cardTitle:"Reset password - {{name}}"
                                }
                            },
                            listTitle:"Utenti",
                            DetailList:{
                                columns:{
                                    firstName:"Nome",
                                    lastName:"Cognome",
                                    displayName:"Nome visualizzato",
                                    userPrincipleName:"Nome account microsoft",
                                    mainUser:"Amministratore So Smart",
                                }
                            },
                            CustomerUserCard:{
                                MessageBar:{
                                    messageContent1:"Salvataggio in corso"
                                },
                                Dialog:{
                                    CardGroup:{
                                        label:"Generale",
                                        CardField:{
                                            firstName:"Nome",
                                            lastName:"Cognome",
                                            displayName:"Nome visualizzato",
                                            phone:"Telefono",
                                            userPrincipleName:"Nome account Microsoft",
                                            mainUser:"Amministratore So Smart",
                                        }
                                    },
                                    primaryButtonText:"Salva",
                                    defaultButtonText:"Annulla"
                                }
                            },
                            CustomerLicenseCard:{
                                Dialog:{
                                    DetailList:{
                                        columns:{
                                            product:"Prodotto",
                                            availableLicences:"Licenze disponibili"
                                        }
                                    },
                                    primaryButtonText:"Salva",
                                    defaultButtonText:"Annulla"
                                }
                            },
                            CustomerResetPasswordCard:{
                                Dialog:{
                                    labelAutomaticEmail:"All'utente \"{{email}}\" verrà inviata una mail con la nuova password temporanea che dovrà essere modificata al primo accesso.",
                                    labelAdditionalAddress:"Se desideri notificare il cambio password ad un altro indirizzo email, inseriscilo qui sotto.",
                                    labelNotificationEmail:"Email di notifica:",
                                    title:"Reset password per \"{{name}}\"",
                                },
                                primaryButtonText:"Richiedi password",
                                defaultButtonText:"Annulla"
                            },
                            Text00001lbl:"Salvataggio completato",
                            LoaderMessage:{
                                loadingUserLicenses:"Caricamento licenze disponibili in corso, attendere prego ...",
                                savingUser:"Salvataggio utente in corso ...",
                                savingLicenses:"Salvataggio licenze in corso ...",
                                resettingPassword:"Reset password in corso ...",
                                resettedPassword:"Reset password effettuato con successo"
                            }
                        }
                    },
                    CustomerMainUserSetupCard:{
                        MessageBar:{
                            messageContent1:"Salvataggio in corso"
                        },
                        Loader:{
                            text:"Stiamo caricando i domini collegati al tuo tenant",
                            textSyncUser:"Stiamo sincronizzando gli utenti del tuo tenant, attendere prego ..."
                        },
                        Dialog:{
                            title:"Utente amministratore",
                            ComboBox:{
                                label:"Seleziona l'utente amministratore"
                            }
                        },
                        CardGroup:{
                            label:"Generale",
                            CardField:{
                                firstName:"Nome",
                                lastName:"Cognome",
                                displayName:"Nome visualizzato",
                                userPrincipleName:"Nome account microsoft",
                                phone: "Numero di telefono"
                            }
                        },
                        Err00001Text:"Devi selezionare un utente per poter continuare",
                        Err00002Text:"Impostare il campo Nome",
                        Err00003Text:"Impostare il campo Cognome",
                        Err00004Text:"Impostare il campo Nome visualizzato",
                        Err00005Text:"Impostare il campo Nome account Microsoft",
                        Err00006Text:"I permessi per sincronizzare gli utenti non sono ancora attivi, chiudere la scheda e riprovare tra qualche minuto."
                    },
                    CustomerVideoModal:{
                        Err00001Text:"Impossibile caricare il video",
                        Err00002Text:"Sembrerebbe che il video condiviso non esista o non sia funzionante, contatta l'assistenza per maggiori informazioni",
                    },
                    CustomerIncidentList:{
                        title:"Lista ticket assistenza",
                        columns:{
                            userDisplayName:"Nome Utente",
                            itemDescription:"App",
                            title:"Titolo",
                            description:"Descrizione",
                            createdOn:"Data Creazione",
                            stateDescription:"Stato",
                            crmStatusDescription:"Avanzamento",
                            crmIncidentTypeName:"Tipo",
                            uploadState:{
                                description:"Stato upload CRM",
                                onQueue:"In coda",
                                uploaded:"Caricato",
                                inError:"In errore"
                            }
                        },
                        CustomerIncidentCard:{
                            title:"Creazione ticket assistenza",
                            CardGroup:{
                                label:"Generale",
                                CardField:{
                                    title:"Titolo",
                                    titlePlaceHolder:"Inserisci un titolo",
                                    contactEmail:"Email",
                                    Description:"Descrizione",
                                    ticketType:"Tipo ticket",
                                    sourceTitle:"Titolo di origine",
                                    sourceAddress:"Url di origine",
                                    lastError:"Ultimo errore",
                                    sourceApplicationOrigin:"Applicazione di origine",
                                    attachment:"Allegato",
                                    attachmentButton:"Aggiungi un allegato",
                                    validation:{
                                        title:"Il titolo è obbligatorio",
                                        description:"La descrizione è obbligatoria",
                                        email:"La mail è obbligatoria",
                                        descriptionLength:"Descrizione troppo grande, se hai aggiunto un immagine, eliminala ed aggiungila come allegato",
                                        itemRequired:"L'App è obbligatoria",
                                        emailOnMicrosoftError:"Dominio non valido (onmicrosoft.com), inserisci una email di contatto valida"
                                    }
                                }
                            }
                        },
                        Text00001lbl:"Creazione ticket in corso, l'operazione potrebbe richiedere qualche secondo",
                        Err00001lbl:"Utente non abilitato alla creazione dei ticket. Contattare l'assistenza",
                        Err00002lbl:"Impossibile aprire la pagina di creazione ticket",
                        extNotSupported:"Non puoi allegare file con questa estensione: ",
                        fileMaxWeight:"Ogni allegato può pesare al massimo 5 MB",
                        fileMaxCount:"Puoi allegare al massimo 5 file",
                        ticketSuccesfullyCreated:"Ticket creato correttamente"
                    },
                    CustomerHelpPageMultipleMode:{
                        title:"RICHIESTA DI SUPPORTO",
                        subTitle:"Hai bisogno di aiuto? Chiedi al nostro agente virtuale! Se non trovi una risposta alle tue domande puoi aprire un ticket di supporto dal modulo sottostante.",
                        primaryButtonText:"Salva",
                        defaultButtonText:"Cancella",
                        customActionText:"Crea ticket",
                    },
                    CustomerHelpPageSingleMode:{
                        title:"RICHIESTA DI SUPPORTO",
                        subTitle:"Hai bisogno di aiuto? Chiedi al nostro agente virtuale!",
                        primaryButtonText:"Salva",
                        defaultButtonText:"Cancella",
                        customActionText:"Crea ticket",
                    },
                    BusinessCentralTelemetry:{
                        taskTitle:"Task completati",
                        feedbackTitle:"Feedback positivi",
                        waitTitle:"Ops...",
                        waitSubTitle:"Le statistiche verranno visualizzate non appena i tuoi ambienti saranno pronti, torna più tardi."
                    },
                    Err00001Text:"Errore: impossibile caricare il contenuto",
                    Err00002Text:"Il tuo utente deve essere amministratore per poter eseguire questa azione",
                    Text00001Lbl:"Ti stiamo reindirizzando allo shop, attendere prego...",
                    Err00003Text:"Attenzione: Prima di continuare è necessario verificare i dati di fatturazione",
                    Err00004Text:"Compilare tutti i campi della pagina",
                    Err00005Text:"P.IVA non valida",
                    Text00002Lbl:"Salvataggio completato",
                    Text00003Lbl:"Dati non corretti o mancanti",
                    messageContent1:"Stiamo caricando la tua dashboard..."
                },
                demoRoleCenter:{
                    banner:{
                        title:"Benvenuto in So Smart!",
                        subTitle:"Questa è una dashboard dimostrativa. Per avviare una prova gratuita con i dati della tua azienda completa i task sulla destra. Ti auguriamo una buona navigazione",
                        rightContent:{
                            title:"Ecco alcune funzioni utili per scoprire So Smart",
                        },
                        gtTitle:"Inizia il tour guidato"
                    },
                    cueBox:{
                        actionText:"Ulteriori informazioni"
                    },
                    bookingModal:{
                        title:"Prenota un consulente",
                    },
                    embeddedHelp:{
                        demoButtonText:"Torna all'esperienza demo",
                        defaultButtonText:"Chiudi"
                    },
                    loadingDescription:"Stiamo caricando la tua dashboard...",
                    defaultVideoDescription:"Come funziona",
                    findOutMore:"Scopri di più"
                },
                docs:{
                    banner:{
                        subTitle: "Benvenuto! Esplora il nostro portale di help",
                        buttonText: "Esplora il sito"
                    },
                    AnswerContainer:{
                        Text000001Lbl:"Ha risposto alla tua richiesta di supporto?",
                        Text000002Lbl:"Non hai trovato quello che cercavi? Apri un ticket",
                        Text000003Lbl:"cliccando qui",
                        Text000001Err:"Funzionalità non supportata.",
                    },
                    Site:{
                        Text000001Lbl:"RISULTATI RICERCA",
                        Text000002Lbl:"Ricerca per titolo...",
                        Text000003Lbl: "Caricamento pagina in corso...",
                        Text000004Lbl: "AVVIA",
                        Text000001Err:"Il portale non è disponbile",
                        Text000002Err:"Riaggiorna la pagina, se il problema persiste",
                        Text000003Err:"contattaci qui",
                    },
                    KnownledgeRenderer:{
                        Text000001Lbl:"Caricamento della pagina...",
                        Text000001Err:"La pagina non è disponbile!",
                        Text000002Err:"Prova ad accedere ad un altro contenuto",
                    },
                    FeedbackDialog:{
                        Text000001Lbl:"Esprimi un giudizio da 1 a 5",
                        Text000002Lbl:"Condividi più informazioni",
                        Text000001Btn:"Invia",
                        title:"Questa pagina è stata utile?"
                    }
                },
                chatbot: {
                    chat:{
                        title:"Inizia a chattare",
                        welcomeMessage: "### Ciao, come posso aiutarti? \n Scrivi la tua domanda in chat e ti risponderò il prima possibile. \n\n Se la mia risposta non è soddisfacente, puoi scrivere **[support](\"\")** in chat o cliccare sul pulsante \"**?**\" in basso a sinistra per aprire una richiesta di supporto.",
                        subTitle:"Questo chatbot è configurato per rispondere alle tue domande",
                        error:"Errore",
                        errorMessage:"Si è verificato un errore. Si prega di riprovare. Se il problema persiste, contattare l'amministratore del sito.",
                        supportError:"Mi dispiace non ho trovato alcuna informazione in merito",
                        generateText:"Generazione della risposta...",
                        stopGenerating:"Ferma generazione",
                        questionInput:"Scrivi una domanda...",
                        citationDialog:"Citazioni",
                        assistantEmailMessage:"Ok, cominciamo. Qual è il tuo indirizzo e-mail?",
                        assistantEmailError:"L'e-mail {{email}} non è valida, per favore inserire un indirizzo email valido.",
                        assistantOtherTxt:"Se hai altre domande, scrivile su questa chat",
                        clearChatTxt:"Pulisci chat",
                        supportTxt:"Apri una richiesta di supporto",
                        ticketCreationErrorMessage: "Ci spiace, non è possibile creare una richiesta di supporto in questo momento",
                        notFoundResponse: "Mi dispiace, non ho trovato nessun contenuto per rispondere alla tua domanda"
                    },
                    layout:{
                        shareAction:"Condividi",
                        shareDialog:{
                            title:"Condividi la web app",
                            copyText:"Copia URL",
                            copiedText:"URL copiato"
                        }
                    },
                    answer: {
                        aiDisclamer: "I contenuti dell'IA possono essere errati",
                        like: "Risposta corretta",
                        dislike: "Risposta errata",
                        citationCount:" referenze",
                        citationOne:"1 referenza",
                        citationPart: "Parte",
                        citationCaption: "Citazione",
                        followupQuestions: " domande suggerite"
                    },
                    openSupportRequest: {
                        title: "Apri una richiesta di supporto",
                        btnTxt: "Apri",
                        messageTxt: "Vuoi aprire una richiesta di assistenza su questo argomento?",
                        sessionInformation: "Informazioni sessione",
                        supportQuestion: "Hai bisogno di supporto per questo errore?",
                        ticketCreationQuestion: "Procedo alla richiesta di supporto al team di assistenza?",
                        ticketCreationError: "Mi scuso ma non sono riuscito a creare la richiesta di supporto. Attendi qualche minuto e riprova.",
                        updateSupportEntryError: "Mi scuso ma ci sono dei problemi di connessione con i nostri servizi. Attendi qualche minuto e riprova.",
                        feedbackMessage: "Grazie per il feedback! Se hai altre domande scrivi pure in chat!",
                        dataRequestText: "Inserisci i dati sotto richiesti inerenti alla richiesta di supporto",
                        area: "Area",
                        process: "Processo",
                        action: "Azione",
                        selectAreaError: "Seleziona un area",
                        satisfied: "Sono soddisfatto",
                        openTicket: "Apri richiesta assistenza",
                        page: "All'interno della pagina",
                        useActionLC: "azione",
                        useActionUC: "Azione",
                        useProcessLC: "Del processo",
                        useProcessUC: "Processo",
                        useModuleLC: "del modulo",
                        useModuleUC: "Modulo",
                        listQuestion: "Ho la seguente domanda:",
                        listError: "Ho il seguente errore:",
                        tenantId: "ID tenant",
                        environment: "Ambiente",
                        company: "Società",
                        pageInfo: "Pagina",
                        license: "Licenza",
                        successTitle: "Richiesta di supporto creata",
                        successText: "Il nostro team di supporto ti contatterà via e-mail.",
                        successSubText: "Posso aiutarti in altro?",
                        flowCommercial: "Commerciale",
                        flowTechnical: "Tecnico",
                        alreadyInstalledApp: "Hai già installato l'app "
                    },
                    supportTypeForm:{
                        title:"Selezionare il tipo di supporto di cui si ha bisogno",
                        configuration:"Configurazione",
                        training:"Formazione",
                        customization:"Personalizzazione",
                        bug:"Bug"
                    },
                    customerIdForm:{
                        title:"Inserisci il tuo customer id",
                        subTitle:"Se hai bisogno di più informazioni",
                        link:"clicca qui"
                    },
                    installationTypeForm:{
                        title:"Seleziona il tipo di installazione di Business Central"
                    },
                    lastErrorConfirmText:{
                        title: "Vuoi aprire una richiesta su questo errore?"
                    },
                    testConfirmText:{
                        title: "Dai tuoi dati abbiamo identificato che stai eseguendo questa operazione, se così non fosse ti chiediamo di selezionare quella corretta"
                    },
                    questionText:{
                        title: "Descrivimi il problema"
                    },
                    panel: {
                        headline: "Hai bisogno di altre informazioni? Chiedi al nostro chatbot!",
                        subText: "Il chatbot risponderà a qualsiasi tua domanda, basta che tu scriva le tue domande nella chat."
                    },
                    subscriptionTypeForm:{
                        title: "Seleziona il tipo di sottoscrizione",
                        full:"Completa",
                        free:"Gratuita"
                    },
                    appVersionForm:{
                        title:"Inserisci la versione dell'app"
                    },
                    supportRequestTextForm: {
                        title: "Inserisci una descrizione per la tua richiesta"
                    },
                    successForm: {
                        title: "La tua richiesta di supporto è stata inviata correttamente!"
                    },
                    eosAppForm: {
                        title:"Cerca e clicca sull'applicazione relativa alla vostra richiesta.",
                        placeholder:"Cerca la tua app..."
                    },
                    eosAppWelcome: {
                        header: "👋 Benvenuto!",
                        selection: "👍 Hai selezionato #APPDESCRIPTION#, scrivimi pure in chat le tue domande",
                        title:"Seleziona l'app su cui ha bisogno di supporto, se invece vuoi fare una domanda generica scrivila in chat",
                        placeholder:"Cerca l'app...",
                        prefix: "Ho bisogno di support per l'app #APP#, la mia domanda è: #QUESTION#"
                    }
                },
                leadDashboard: {
                    banner:{
                        title:"Benvenuto in So Smart!",
                        subTitle:"L'unico gestionale al mondo con pagamento mensile senza costi di attivazione basato su Microsoft Dynamics 365 Business Central, pluripremiato come il miglior software gestionale per le PMI al mondo. Scopri l'offerta a partire da 80€/mese",
                    },
                    checklist:{
                        contentEntryTitle: 'Complimenti!',
                        contentEntryText: 'Hai completato tutte le attività assegnate, ora puoi utilizzare i prodotti So Smart al meglio',
                        nextButtonText: 'Avanti'
                    },
                    leadModal: {
                        title:'Configura la tua azienda',
                        subTitle: "Inserisci qui sotto le tue informazioni per iniziare l'esperienza So Smart",
                        primaryButtonText: 'Avanti',
                        saveButtonText: 'Salva',
                        defaultButtonText: 'Cancella',
                        field:{
                            companyName: 'Nome società',
                            industry: 'Settore',
                            firstname: 'Nome',
                            lastname: 'Cognome',
                            mail: 'E-Mail',
                            fullUsersNo: 'Nr. utenti',
                            foreignAddress: 'Indirizzo estero',
                            address: 'Indirizzo',
                            zipCode: 'Cap',
                            city: 'Città',
                            county: 'Provincia',
                            country: 'Paese',
                            decisionDate: 'Data decisione',
                            decisionDatePlaceHolder: 'Inserisci una data entro la quale intendi decidere di adottare So Smart per la tua azienda',
                            expectedLiveDate: 'Data live',
                            expectedLiveDatePlaceHolder: 'Inserisci una data entro la quale intendi andare live con So Smart',
                            rating: 'Temperatura',
                            currentSystem: 'Attuale sistema',
                            revenue: 'Fatturato',
                            evaluationMode:{
                                title:'Il tuo So Smart',
                                label:'Scegli la modalità di valutazione che preferisci',
                                text00001Lbl: 'Self service',
                                text00002Lbl: 'Con un consulente',
                                tooltipText1: 'Utilizzerai il sistema senza il supporto di un consulente esperto',
                                tooltipText2: 'Un nostro consulente esperto sarà a tua disposizione per aiutarti nel processo di valutazione della nostra soluzione, procedi a prenotare il primo appuntamento nel prossimo step',
                            },
                            businessNeeds:{
                                title: 'Quali sono i tuoi obiettivi?',
                                subTitle: 'Inserisci qui le funzionalità necessarie per avviare la tua soluzione',
                                text00001Lbl: 'Obiettivo 1 (Es. Rendere i miei utenti più produttivi, Gestione post-vendita, Analisi flussi di cassa)',
                                text00002Lbl: 'Obiettivo 2 (Es. Gestione commesse, Integrazione E-Commerce)',
                                text00003Lbl: 'Obiettivo 3 (Es. Proposta approvigionamenti automatici, Pianficazione risorse, Spostare tutto in cloud)'
                            }
                        }
                    },
                    dashboardLink:{
                        title: 'Link utili',
                        extendedTitle: 'Usa questi link per navigare tra le differenti funzionalità a tua disposizione'
                    },
                    errors:{
                        text00001Err: 'Valore obbligatorio',
                        text00002Err: 'Impossibile salvare la configurazione della lead',
                        text00003Err: 'Modalità di configurazione non consentita',
                        text00004Err: 'Impossibile caricare i codici postali',
                        text00005Err: 'Impossibile creare il link allo shop',
                        text00006Err: 'Impossibile trovare un onboarding worksheet per questa lead'
                    },
                    messages:{
                        text00001msg: 'Caricamento in corso...',
                        text00002msg: 'Creazione link in corso...',
                        text00003msg: 'Link creato correttamente, sarai reindirizzato tra pochi istanti'
                    },
                    buttonText: 'Avanti'
                },
                adminCenter: {
                    loaderText: "Stiamo caricando la tua dashboard...",
                    newTrial: "Nuove trial",
                    newTrialDescription: "L'ultima settimana sono state create: {{value}} nuove trial",
                    newCustomer: "Nuovi clienti",
                    newCustomerDescription: "Negli ultimi 30 giorni sono stati acquisiti {{value}} nuovi clienti",
                    revenue: "Fatturato",
                    revenueDescription: "Negli ultimi 30 giorni il fatturato è stato di {{value}} €",
                    subscription: "Sottoscrizioni: {{value}} €",
                    subscriptionDescription: "Negli ultimi 30 giorni sono state create nuove sottoscrizioni di {{value}} €",
                    cueCustomer: "Clienti",
                    cueIndicatorRisk: "Indicatori di rischio",
                    cueLeadLost: "Lead perse",
                    cueNotQualifiedLead: "Lead non qualificate",
                    cueTrialLost: "Trial perse",
                    cueCustomerRisk: "Clienti a rischio",
                    cueCustomerLost: "Clienti persi",
                    generalIndicators: "Indicatori generali",
                    cueLeadWin: "Lead vinte",
                    cueActiveLeads: "Lead attive",
                    cueLeadParked: "Lead Parcheggiate",
                    cueActiveCustomer: "Clienti attivi",
                    cueSubscription: "Sottoscrizioni",
                    cueInvoice: "Fatture",
                    cueBaseUnsignedContracts: "Contratti base da firmare",
                    cueSendNamirialFeContract: "Contratti FE da inviare a Namirial",
                    cueFeUnsignedContracts: "Contratti FE da firmare",
                    cueCustomerSignedContract: "Contratti firmati",
                    leadList: {
                        loaderText: "Stiamo caricando la lista richiesta ...",
                        listErrorText: "Lista lead non disponibile",
                        title: "Lista lead",
                        title1: " attive",
                        title2: " Parcheggiate",
                        title3: " totale",
                        text0001Err: "Impossibile visualizzare la lista delle lead, contattare l'assistenza",
                        text0002Err: "Attenzione: non è stato possibile recuperare i dati del CRM, le azioni basate su ciò potrebbero non funzionare",
                        text0001Msg: "Stiamo sincronizzando i dati richiesti ...",
                        text0002Msg: "Sincronizzazione completata",
                        text0003Err: "Attenzione: non è stato possibile sincronizzare i dati: ",
                        text0003Msg: "Stiamo sincronizzando i dati richiesti per la lead ",
                        text0004Msg: "Stiamo sincronizzando la lead ",
                        text0005Msg: "Stiamo sincronizzando tutte le lead ...",
                        text0006Msg: "Sincronizzazione interazioni in corso...",
                        text0007Msg: "Sincronizzazione interazioni completata",
                        text0004Err: "Attenzione: Si sono verificati alcuni problemi durante la sincronizzazione delle interazioni",
                        text0008Msg: "Invio mail di accesso per {{mail}} in corso",
                        text0009Msg: "Mail di accesso inviata con successo",
                        text0010Msg: "Salvataggio Lead attive in corso ...",
                        text0011Msg: "Salvataggio Lead attive completato",
                        text0005Err: "Attenzione: errore nel salvataggio Lead attive: ",
                        actions: {
                            leadQualification: "Qualifica lead",
                            qualifierPage: "OnBoarding worksheet",
                            openShopAddress: "Configura offerta",
                            openQuoteOffer: "Apri offerta di qualifica",
                            openLeadIndustry: "Lead business line",
                            openEmailList: "Email Inviate",
                            openLossRiskLine: "Righe Rischio Perdita",
                            openInteractionList: "Interazioni CRM",
                            openQualifiedAnswer: "Risposte qualifica",
                            openAutomatioProspect: "Automation Prospect",
                            renegerateLeadToken: "Invia mail di accesso",
                            openBooking: "Prenota un consulente",
                            syncCalculatedData: "ggiorna indicatori KPI",
                            syncSpecificCalculatedData: "Aggiorna indicatori KPI per Lead specifica",
                            syncCRMData: "Sincronizza Tutte le Lead",
                            syncSpecificCRMData: "Sincronizza Lead specifica",
                            syncCRMInteractionAll: "Sincronizza Interaction per tutte le Lead",
                            syncCRMInteractionLead: "Sincronizza Interaction per Lead specifica",
                            saveActiveLeads: "Salva Lead attive",
                            legalEntityFilter: "Toggle filtro legal entity",
                        },
                        fields: {
                            statusCode: "Stato DS",
                            firstname: "Nome",
                            lastname: "Cognome",
                            companyName: "Nome società",
                            email: "E-Mail",
                            partnerId: "Partner",
                            liveMode: "Modalità live",
                            liveMode0: "Non selezionata",
                            liveMode1: "Live in pochi giorni",
                            liveMode2: "Cambiamento in breve termine",
                            liveMode3: "Valutazione di tutte le funzionalità",
                            liveMode4: "Valutazione senza obiettivi chiari",
                            partnerBusinessContractCode: "Partner Business Contract",
                            businessLineCode: "Business line",
                            origin: "Origine",
                            campaignCode: "Campagna",
                            score: "Score",
                            budget: "Budget",
                            logEntry: "Visione log entry",
                            createdOn: "Data creazione",
                            marketingQualificationCarriedOut: "Qualifica marketing effettuata",
                            lastInboundEmailDate: "Data ultima email inbound",
                            lastActivityDate: "Data ultima interazione",
                            lastActivityType: "Tipo ultima interazione",
                            lastActivityType0: "Email",
                            lastActivityType1: "Note",
                            lastActivityType2: "Appuntamento",
                            lastActivityType3: "Post",
                            lastActivityType4: "Task",
                            lastActivityType5: "Telefonata",
                            lastWeekInteractions: "Interazioni ultima settimana",
                            lastMonthInteractions: "Interazioni ultimo mese",
                            demoBooked: "Demo prenotata",
                            demoDate: "Data demo",
                            demoCarriedOut: "Demo effettuata",
                            demoCarriedOutBy: "Demo effettuata da",
                            demoFeedbackRequired: "Feedback demo richiesto",
                            demoFeedbackReceived: "Feedback demo ricevuto",
                            trialRequestedDate: "Data richiesta Trial",
                            trialStartDate: "Data partenza Trial",
                            riskLineExists: "Presenza rischi perdita",
                            riskLineHandled: "Rischi perdita gestiti",
                            offerLink: "Link offerta",
                            salesHeaderTemplateCode: "Codice offerta",
                            owningUserDisplayName: "Utente Proprietario",
                            bookingModalTitle: "Prenota un nostro consulente"
                        }
                    },
                    leadCard: {
                        title: "Scheda lead",
                        general: "Generale",
                        companyName: "Nome società",
                        email: "E-Mail",
                        firstname: "Nome",
                        lastname: "Cognome",
                        phone: "Telefono",
                        tokenForDemo: "Token demo",
                        statusCode: "Stato DS",
                        score: "Score",
                        subject: "Oggetto",
                        owningUserId: "Utente proprietario",
                        localization: "Localizzazione",
                        legalEntityCode: "Legal entity",
                        distributorCode: "Distributore",
                        partnerId: "Partner",
                        partnerBusinessContractCode: "Partner business contract",
                        businessLineCode: "Business line",
                        languageCode: "Lingua",
                        address: "Indirizzo",
                        zipCode: "C.A.P",
                        city: "Città",
                        county: "Provincia/Stato",
                        country: "Paese",
                        marketingQualification: "Qualifica marketing",
                        salesHeaderTemplateId: "Sales Header Template",
                        origin: "Origine",
                        campaignCode: "Campagna",
                        originName: "Nome origine",
                        noOfCompany: "Nr. di società",
                        full_Users_No: "Nr. utenti full",
                        limited_User_No: "Nr. utenti limited",
                        note: "Note",
                        marketingQualificationCarriedOut: "Qualifica marketing effettuata",
                        monthlyBudget: "Budget mensile",
                        noOfEmployees: "Numero di impiegati",
                        digitalLevel: "Livello di digitalizzazione",
                        currentERPName: "ERP attuale",
                        otherConnectedSystems: "Altri sistemi interfacciati",
                        decisionDate: "Data decisione",
                        expectedLiveDate: "Data aspettativa live",
                        ourAgentRole: "Ruolo agente SoSmart",
                        demoPartecipants: "Partecipanti alla demo",
                        decisionMakerPath: "Path di decisione",
                        freeTrialInterest: "Interessati alla free trial",
                        isAlreadyCustomer: "È già cliente",
                        activeMicrosoftServices: "Servizi MS già attivi",
                        registerRequest: "Richiesta registrazione",
                        interactionsDetail: "Dettaglio interazione",
                        demoBooked: "Demo prenotata",
                        demoDate: "Data demo",
                        internalMeetingBooked: "Meeting interno prenotato",
                        internalMeetingDate: "Data meeting interno",
                        demoCarriedOut: "Demo effettuata",
                        demoCarriedOutBy: "Demo effettuata da",
                        notManagedGAPEmerged: "Emersi GAP non gestiti",
                        negotiationState: "Stato negoziazione",
                        rating: "Rating",
                        rating0: "Fredda",
                        rating1: "Tiepida",
                        rating2: "Calda",
                        evaluationMode: "Modalità di valutazione",
                        evaluationMode0: "Non indicata",
                        evaluationMode1: "Self Service",
                        evaluationMode2: "Con un consulente",
                        liveMode: "Modalità live",
                        liveMode0: "Non selezionata",
                        liveMode1: "Live in pochi giorni",
                        liveMode2: "Cambiamento in breve termine",
                        liveMode3: "Valutazione di tutte le funzionalità",
                        liveMode4: "Valutazione senza obiettivi chiari",
                        leadAutomation: "Lead automation",
                        lastActivityDate: "Data ultima interazione",
                        lastActivityType: "Tipo ultima interazione",
                        lastActivityType0: "Email",
                        lastActivityType1: "Note",
                        lastActivityType2: "Appuntamento",
                        lastActivityType3: "Post",
                        lastActivityType4: "Task",
                        lastActivityType5: "Telefonata",
                        lastInboundEmailDate: "Data ultima email inbound",
                        lastWeekInteractions: "Interazioni ultima settimana",
                        lastMonthInteractions: "Interazioni ultimo mese",
                        demoFeedbackRequired: "Feedback demo richiesto",
                        demoFeedbackReceived: "Feedback demo ricevuto",
                        demoFeedbackRating: "Rating feedback demo",
                        riskLineExists: "Presenza rischi perdita",
                        riskLineHandled: "Rischi perdita gestiti",
                        offer: "Offerta",
                        offerLink: "Link offerta",
                        offerSent: "Offerta inviata",
                        promoCode: "Codice promo",
                        mailCodeTemplate: "Mail template code",
                        salesHeaderTemplateCode: "Codice offerta",
                        customer: "Cliente",
                        isTrial: "Cliente Trial",
                        trialRequestedDate: "Data richiesta Trial",
                        trialStartDate: "Data partenza Trial",
                        createdOn: "Data Creazione",
                        finalOutcome: "Esito finale",
                        finalOutcome1: "Vinta",
                        finalOutcome2: "Persa",
                        finalOutcome3: "Non qualificata",
                        lossCauseCode: "Causa perdita",
                        crmStatusCode: "Causa squalifica",
                        interestConfirmed: "Interesse confermato",
                        firstLoginCompleted: "Primo login completato",
                        setupCompanyCompleted: "Setup società completato",
                        shopOpened: "Accesso allo shop",
                        parked: "Parcheggiata",
                    },
                    salesHeaderList: {
                        title: "Template Ordini",
                        loaderText: "Stiamo caricando la lista richiesta, attendere...",
                        dialogTitle: "Scegli il Cliente",
                        dialogSubtext: "Devi inserire il Cliente per poter proseguire",
                        dialogTxtLabel: "Cliente:",
                        text0001Err: "Errore nel recupero dei clienti",
                        text0001Msg: "Creazione Sottoscrizione per {{customerName}} in corso ...",
                        text0002Msg: "Creazione Sottoscrizione completata con successo",
                        text0002Err: "Errore durante la creazione Sottoscrizione: ",
                        text0003Err: "Devi inserire un Cliente valido",
                        text0004Err: "Devi selezionare un template per proseguire",
                        actions: {
                            openLineTemplateList: "Dettaglio template ordine",
                            systemCaption: "Traduzioni",
                            createCustomerSubscriptionFromTemplate: "Crea sottoscrizione cliente da template"
                        },
                        fields: {
                            id: "Id",
                            createdAt: "Creato il",
                            expirationDate: "Data scadenza",
                            source: "Sorgente",
                            source0: "Web",
                            source1: "Industry",
                            source2: "Qualifica",
                            code: "Codice",
                            description: "Descrizione",
                            budgetPerFullUser: "Budget per utente completo",
                            budgetPerLimitedUser: "Budget per utente limitato"
                        }
                    },
                    leadIndustryList: {
                        title: "Business line di: ",
                        fields: {
                            salesChannel: "Canale di vendita",
                            description: "Descrizione",
                            typologyId: "Tipologia",
                            typologyId0: "Non selezionata",
                            typologyId1: "Produzione a progetto",
                            typologyId2: "Produzione standard",
                            typologyId3: "Commercio",
                            typologyId4: "Servizio",
                            revenue: "Fatturato"
                        }
                    },
                    leadIndustryCard: {
                        title: "Scheda business line",
                        general: "Generale",
                        salesChannel: "Canale di vendita",
                        description: "Descrizione",
                        typologyId: "Tipologia",
                        typologyId0: "Non selezionata",
                        typologyId1: "Produzione a progetto",
                        typologyId2: "Produzione standard",
                        typologyId3: "Commercio",
                        typologyId4: "Servizio",
                        revenue: "Fatturato"
                    },
                    frontEndLogEntryList: {
                        title: "Log Dashboard Demo ",
                        fields:{
                            appId: "Id app",
                            description: "Descrizione",
                            referenceId: "Id referenza",
                            userId: "Id utente",
                            systemCreationDate: "System Creation Date"
                        }
                    },
                    emailLogList:{
                        title: "Lista email log - lead: ",
                        loaderText: "Stiamo caricando la lista richiesta...",
                        fields: {
                            templateCode: "Cod. template",
                            legalEntityCode: "Legal entity",
                            distributor: "Distributore",
                            partnerBusinessContractCode: "Partner business contract",
                            languageCode: "Lingua",
                            toAddress: "Inviato a",
                            subject: "Soggetto",
                            body: "Corpo",
                            userId: "Id utente",
                            id: "Id",
                            systemCreationDate: "System Creation Date"
                        }
                    },
                    lossRiskLineList: {
                        title: "Lista Righe Rischio Perdita",
                        fields: {
                            lossCauseDescription: "Causa Perdita",
                            description: "Descrizione",
                            notes: "Note",
                            processModule: "Process Module",
                            processCode: "Process Code",
                            lineScore: "Score Riga",
                            handled: "Gestito",
                            handleDate: "Data Gestione",
                            sourceTable: "Tabella Origine",
                            sourceId: "Id Origine",
                            sourceExternalId: "Id Origine esterno",
                            entryNo: "Nr. movimento"
                        }
                    },
                    lossRiskLineCard: {
                        title: "Scheda Righe Rischio Perdita",
                        fields: {
                            lossCauseDescription: "Causa Perdita",
                            description: "Descrizione",
                            notes: "Note",
                            processModule: "Process Module",
                            processCode: "Process Code",
                            lineScore: "Score Riga",
                            handled: "Gestito",
                            handleDate: "Data Gestione",
                            sourceTable: "Tabella Origine",
                            sourceId: "Id Origine",
                            sourceExternalId: "Id Origine esterno",
                            entryNo: "Nr. movimento"
                        }
                    },
                    crmInteractionList: {
                        title: "Lista Interazioni CRM Lead",
                        fields: {
                            leadId: "Id Lead",
                            type: "Tipologia",
                            type0: "Email",
                            type1: "Note",
                            type2: "Appuntamento",
                            type3: "Post",
                            type4: "Task",
                            type5: "Telefonata",
                            title: "Titolo",
                            description: "Descrizione",
                            crmReferenceStartDate: "Data Inizio",
                            crmReferenceEndDate: "Data Fine",
                            interactionSource: "Origine",
                            interactionSource0: "Cliente",
                            interactionSource1: "Helpdesk",
                            createdOn: "Data Creazione",
                            percentComplete: "Percentuale Completamento",
                            durationMinutes: "Durata (Minuti)",
                            phoneNumber: "Numero di Telefono",
                            userDisplayName: "Utente"
                        }
                    },
                    crmInteractionCard: {
                        title: "Scheda Interazioni CRM Lead",
                        fields: {
                            leadId: "Id Lead",
                            type: "Tipologia",
                            type0: "Email",
                            type1: "Note",
                            type2: "Appuntamento",
                            type3: "Post",
                            type4: "Task",
                            type5: "Telefonata",
                            title: "Titolo",
                            description: "Descrizione",
                            crmReferenceStartDate: "Data Inizio",
                            crmReferenceEndDate: "Data Fine",
                            interactionSource: "Origine",
                            interactionSource0: "Cliente",
                            interactionSource1: "Helpdesk",
                            createdOn: "Data Creazione",
                            percentComplete: "Percentuale Completamento",
                            durationMinutes: "Durata (Minuti)",
                            phoneNumber: "Numero di Telefono",
                            userDisplayName: "Utente"
                        }
                    },
                    leadQualifiedAnswerList: {
                        title: "Riposte qualifica Lead ",
                        loaderText: "Stiamo caricando i dati richiesti...",
                        fields: {
                            answerCode: "Codice risposta",
                            answerType: "Tipo risposta",
                            description: "Descrizione",
                            score: "Score",
                            note: "Note",
                            wizardCode: "Note"
                        }
                    },
                    leadAutomationProspect: {
                        title: "",
                        loaderText: "Caricamento pagina in corso ...",
                        text0001Err: "Errore nel caricamento della pagina",
                        text0001Msg: "Accettazione messaggio in corso",
                        text0002Msg: "Accettazione messaggio completato con successo",
                        text0002Err: "Errore durante l'accettazione del messaggio: ",
                        text0003Msg: "Accettazione messaggio in corso",
                        text0004Msg: "Accettazione messaggio completato con successo",
                        text0003Err: "Errore durante l'accettazione del messaggio: ",
                        actions: {
                            openCrmLead: "Lead CRM",
                            openLead: "Scheda lead",
                            qualifierPage: "Worksheet lead",
                            openProspect: "Setup prospect",
                            openProspectLeadAutomation: "Setup prospect lead automation",
                            calculateSuggestedLines: "Calcola righe suggerite",
                            registerRow: "Accetta messaggio",
                            rejectRow: "Rifiuta messaggio"
                        },
                        fields: {
                            actionType :"Tipo azione",
                            reason: "Motivo",
                            ownerType: "Tipo proprietario",
                            actionOwnerName: "Nome proprietario azione",
                            actionTemplate: "Template azione",
                            leadPartnerBusinessContractCode: "Partner business contract",
                            leadName: "Lead",
                            leadEmail: "Email Lead",
                            leadSubject: "Oggetto Lead",
                            leadCreatedOn: "Data creazione Lead",
                            leadScore: "Score Lead",
                            leadBudget: "Budget Lead",
                            leadModelScoreName: "Nome model score",
                            leadModelScore: "Model score",
                            leadRating: "Rating Lead",
                            leadRating0: "Fredda",
                            leadRating1: "Tiepida",
                            leadRating2: "Calda",
                            leadId: "Lead Id",
                            crmLeadId: "Lead CRM Id",
                            leadStatusDescription :"DS Lead",
                            leadOwnerName: "Nome proprietario Lead",
                            demoDate: "Data demo",
                            trialStartDate: "Data partenza trial",
                            decisionDate: "Data decisione",
                            expectedLiveDate: "Data aspettativa live",
                            lastInboundEmailDate: "Data ultima email inbound",
                            lastActivityDate: "Data ultima interazione",
                            lastActivityType: "Tipo ultima interazione",
                            lastActivityType0: "Email",
                            lastActivityType1: "Note",
                            lastActivityType2: "Appuntamento",
                            lastActivityType3: "Post",
                            lastActivityType4: "Task",
                            lastActivityType5: "Telefonata",
                            nextActivityDate: "Data prossima interazione",
                            nextActivityType: "Tipo prossima interazione",
                            nextActivityType0: "Email",
                            nextActivityType1: "Note",
                            nextActivityType2: "Appuntamento",
                            nextActivityType3: "Post",
                            nextActivityType4: "Task",
                            nextActivityType5: "Telefonata",
                            autoAccept: "Accettazione automatica",
                            previousRegisterCount: "Numero registrazioni precedenti",
                            lastWeekInteractions: "Interazioni ultima settimana",
                            lastMonthInteractions: "Interazioni ultimo mese",
                            lineError: "Errore",
                            leadViewCode: "Codice prospetto Lead",
                            lineNo: "Line No."
                        }
                    }
                }
            }
        }
    });

//#endregion