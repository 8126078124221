import { forwardRef } from "react";
import { SystemFieldType, SystemPageType } from "../../../../Model/SystemModels";
import { CardField } from "../../../Common/Template/Card/CardField";
import { CardGroup } from "../../../Common/Template/Card/CardGroup";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";

export const AdminPartnerCard = forwardRef<PageContainerBase, React.PropsWithChildren<{}>>((props, ref) => {
    return (
        <PageContainer pageType={SystemPageType.Card} title={"Scheda Partner"} endpoint="odata/partner" ref={ref}>
            <CardGroup name="general" label="Generale" isOpen={true}>
                <CardField name="id" type={SystemFieldType.Number} label="Id" readonly />
                <CardField name="name" type={SystemFieldType.String} label="Nome" />
                <CardField name="shopId" type={SystemFieldType.String} label="Id Shop" />
                <CardField name="relationshipUrl" type={SystemFieldType.String} label="Url Relazione" />
                <CardField name="commissionPercentage" type={SystemFieldType.Number} label="Percentuale Commissione" />
                <CardField name="erpSalesPersonCode" type={SystemFieldType.String} label="Codice Ordine ERP" />
                <CardField name="mainContractId" type={SystemFieldType.Number} label="Id contratto SoSmart" hasTableRelation tableRelationEndpoint="odata/digitalcontract" tableRelationKey="id" tableRelationField="code" />
                <CardField name="abletechContractId" type={SystemFieldType.Number} label="Id contratto principale Abletech" hasTableRelation tableRelationEndpoint="odata/digitalcontract" tableRelationKey="id" tableRelationField="code" />
                <CardField name="abletechDoubleSignatureContractId" type={SystemFieldType.Number} label="Id contratto principale Abletech doppia firma" hasTableRelation tableRelationEndpoint="odata/digitalcontract" tableRelationKey="id" tableRelationField="code" />
                <CardField name="abletechSlaveAooContractId" type={SystemFieldType.Number} label="Id contratto ditta secondaria Abletech" hasTableRelation tableRelationEndpoint="odata/digitalcontract" tableRelationKey="id" tableRelationField="code" />
                <CardField name="abletechDoubleSignatureSlaveAooContractId" type={SystemFieldType.Number} label="Id contratto ditta secondaria Abletech doppia firma" hasTableRelation tableRelationEndpoint="odata/digitalcontract" tableRelationKey="id" tableRelationField="code" />
                <CardField name="abletechCancellationContractId" type={SystemFieldType.Number} label="Id contratto disdetta Abletech" hasTableRelation tableRelationEndpoint="odata/digitalcontract" tableRelationKey="id" tableRelationField="code" />
                <CardField name="stripeShopUrl" type={SystemFieldType.String} label="Url Shop Stripe" />
                <CardField name="stripeKey" type={SystemFieldType.String} label="Key Stripe" />
                <CardField name="stripePublicKey" type={SystemFieldType.String} label="Chiave Pubblica Stripe" />
                <CardField name="stripeWebhookToken" type={SystemFieldType.String} label="Token Webhook Stripe" />
                <CardField name="stripeIvaId" type={SystemFieldType.String} label="Id Iva Stripe" />
                <CardField name="dns" type={SystemFieldType.String} label="DNS" />
                <CardField name="crmPartnerId" type={SystemFieldType.String} label="Id CRM Partner" />
                <CardField name="newLeadPromoCode" type={SystemFieldType.String} label="Codice Promo Nuovo Lead" />
            </CardGroup>
            <CardGroup name="localization" label="Localizzazione" isOpen={true}>
                <CardField label="Legal Entity" name="legalEntityCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/crm/legal/entity" tableRelationKey="code" tableRelationField="name" />
                <CardField label="Distributore" name="distributorCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/distributor" tableRelationKey="code" tableRelationField="name" />
                <CardField label="Partner Business Contract" name="partnerBusinessContractCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/partner/business/contract" tableRelationKey="code" tableRelationField="description" />
                <CardField label="Business line" name="businessLineCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/business/line" tableRelationKey="code" tableRelationField="description" />
                <CardField label="Lingua" name="languageCode" type={SystemFieldType.String} hasTableRelation tableRelationEndpoint="/odata/system/language" tableRelationKey="code" tableRelationField="name" />
            </CardGroup>
            <CardGroup name="personalData" label="Dati anagrafici" isOpen={false}>
                <CardField name="streetAddress" type={SystemFieldType.String} label="Indirizzo" readonly />
                <CardField name="city" type={SystemFieldType.String} label="Città" readonly />
                <CardField name="zipCode" type={SystemFieldType.String} label="CAP" readonly />
                <CardField name="county" type={SystemFieldType.String} label="Provincia" readonly />
                <CardField name="country" type={SystemFieldType.String} label="Stato" readonly />
                <CardField name="phoneNo" type={SystemFieldType.String} label="Telefono" readonly />
                <CardField name="pecEmail" type={SystemFieldType.String} label="PEC" readonly />
                <CardField name="vat" type={SystemFieldType.String} label="Partita IVA" readonly />
                <CardField name="crmEosProfiling" type={SystemFieldType.String} label="CRM profiling" readonly />
            </CardGroup>
            <CardGroup name="erp" label="Parametri ERP" isOpen={false}>
                <CardField name="erpBaseUrl" type={SystemFieldType.String} label="Base URL" />
                <CardField name="erpUserName" type={SystemFieldType.String} label="Username" />
                <CardField name="erpUserPassword" type={SystemFieldType.String} label="Password" />
                <CardField name="erpType" type={SystemFieldType.Option} label="Type" cmbOptions={[
                    {key: 0, text: "Business Central EOS Solutions"}
                ]} />
                <CardField name="erpDiscountDescription" type={SystemFieldType.String} label="Discount description" />
                <CardField name="erpPostingDescription" type={SystemFieldType.String} label="Posting description" />
                <CardField name="erpChannelDimension" type={SystemFieldType.String} label="Channel dimension" />
                <CardField name="erpSalesPersonCode" type={SystemFieldType.String} label="Sales Person Code" />
                <CardField name="erpGenBusPostingGroup" type={SystemFieldType.String} label="General Business Posting Group" />
                <CardField name="erpVATBusPostingGroup" type={SystemFieldType.String} label="VAT Business Posting Group" />
                <CardField name="erpCustomerPostingGroup" type={SystemFieldType.String} label="Customer Posting Group" />
                <CardField name="erpLanguageCode" type={SystemFieldType.String} label="Language Code" />
                <CardField name="erpCurrencyCode" type={SystemFieldType.String} label="Currency Code" />
                <CardField name="erpPaymentTermsCode" type={SystemFieldType.String} label="Payment Terms Code" />
                <CardField name="erpPaymentMethodCode" type={SystemFieldType.String} label="Payment Method Code" />
                <CardField name="erpReminderTermsCode" type={SystemFieldType.String} label="Reminder Terms Code" />
                <CardField name="erpDiscountItemNo" type={SystemFieldType.String} label="Discount Item No" />
                <CardField name="erpAccrualTemplate" type={SystemFieldType.String} label="Accrual Template" />
            </CardGroup>
            <CardGroup name="lead" label="Lead" isOpen={false}>
                <CardField name="leadManagementEnabled" type={SystemFieldType.Boolean} label="Gestione Lead abilitata"></CardField>
                <CardField name="leadMarketingAutomationEnabled" type={SystemFieldType.Boolean} label="Lead marketing automation abilitata"></CardField>
                <CardField name="leadMarketingAutomationWithPromoEnabled" type={SystemFieldType.Boolean} label="Lead marketing automation (con promo) abilitata"></CardField>
                <CardField name="leadNewsletterEnabled" type={SystemFieldType.Boolean} label="Newsletter Lead abilitata"></CardField>
                <CardField name="leadOrigin" type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="odata/crm/source" tableRelationKey="code" tableRelationField="description" label="Origine Lead" />
                <CardField name="hashKey" type={SystemFieldType.String} label="Chiave referral" readonly />
            </CardGroup>
        </PageContainer>
    );
});