import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminSecuritySetupCard } from "../AdminSecuritySetupCard/AdminSecuritySetupCard";

export const AdminSecuritySetupList : React.FC = () => {
    const navigate = useNavigate();
    const cardRef = useRef<PageContainerBase>(null);
    
    return (
        <>
            <PageContainer endpoint={"/odata/Security/setup"} pageType={SystemPageType.List} title={"Security Setup"} backAllowed insertAllowed updateAllowed deleteAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry label="Pagine" iconName="Page" name="OpenSystemPages" onClick={(req:any)=>{
                            navigate("/app/admin/system/pages");
                        }}>
                        </ActionEntry>
                        <ActionEntry label="Traduzioni backend" iconName="Globe" name="OpenBackendTranslations" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/backendTranslations?sourceId=SYSTEM&captionType=System&sourceEntity=BACKEND&title=Traduzioni backend");
                        }}>
                        </ActionEntry>
                        <ActionEntry label="Utenti" iconName="UserFollowed" name="OpenUser" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/userGeneral");
                        }}>
                        </ActionEntry>
                        <ActionEntry label="App" iconName="AllApps" name="OpenSystemApp" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/systemAppList");
                        }}>
                        </ActionEntry>
                        <ActionEntry label="Legal Entity" iconName="BusinessCenterLogo" name="OpenLegalEntity" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/crmLegalEntity");
                        }}>
                        </ActionEntry>
                        <ActionEntry label="Distributori" iconName="DistributeDown" name="OpenDistributor" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/distributor");
                        }}>
                        </ActionEntry>
                        <ActionEntry iconName="System" name="Openjobentry" label="Code processi" onClick={(req:any)=>{
                            navigate("/app/admin/systemjobqueue/entry");
                        }}>
                        </ActionEntry>
                        <ActionEntry iconName="BackLog" name="OpenFunctionjob" label="Funzioni background" onClick={(req:any)=>{
                            navigate("/app/admin/systemjobqueue/function");
                        }}>
                        </ActionEntry>
                        <ActionEntry iconName="ScheduleEventAction" name="OpenSystemEvent" label="Eventi di sistema" onClick={(req:any)=>{
                            navigate("/app/admin/systemevent/publisher");
                        }}>
                        </ActionEntry>
                        <ActionEntry iconName="SecurityGroup" name="OpenTokenEntryList" label="Refresh Token" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/userRefreshToken");
                        }}>
                        </ActionEntry>
                        <ActionEntry iconName="AuthenticatorApp" name="OpenAuthenticationSetup" label="Authentication Setup" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/authenticationSetup");
                        }}>
                        </ActionEntry>
                        <ActionEntry iconName="Link" name="OpenAzureGDAPRelationship" label="Relazioni GDAP" onClick={(req:any)=>{
                            navigate("/app/admin/administrative/azureGDAPRelationship");
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Primary Key" fieldName="primaryKey" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Correlation Id message" fieldName="correlationIdMessage" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Customer Portal App Id" fieldName="customerPortalAppId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Customer purchase role" fieldName="customerPurchaseRole" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Use test email" fieldName="useTestEmail" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Reset password minutes duration" fieldName="resetPasswordMinutesDuration" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Reset password link template code" fieldName="resetPasswordLinkTemplateCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Set password confirmation template code" fieldName="setPasswordConfirmationTemplateCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Production environment" fieldName="isProduction" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminSecuritySetupCard ref={cardRef} />
        </>
    );
}