import React, { forwardRef, useState} from "react";
import { PostalCode, SystemFieldType, SystemOperation, SystemPageType } from "../../../Model/SystemModels";
import PageContainer, { PageContainerBase } from "../../Common/Template/PageContainer";
import { CardGroup } from "../../Common/Template/Card/CardGroup";
import { CardField } from "../../Common/Template/Card/CardField";
import { Customer } from "../../../Model/CustomerModels";
import { t } from "i18next";
import { AxiosInstance } from "axios";
import { HttpHelper } from "../../../Core/Http/HttpHelper";

export interface ICustomerBillingProps{
    onSuccess:(operationType: SystemOperation, customer:Customer)=>void
    record: Customer;
}

export const CustomerBillingInfoCard = forwardRef<PageContainerBase, React.PropsWithChildren<ICustomerBillingProps>>((props, ref) => {

    const [allowFreeForm, setAllowFreeForm] = useState<boolean>(props.record.foreignAddress);
    const [hideFields, setHideFields] = useState<boolean>(props.record.legalEntityCode === 'ESS');
        
    return (
        <PageContainer pageType={SystemPageType.Card} 
                       title={t('customerCenter:CustomerBillingInfoCard:title')} 
                       endpoint="odata/customer"
                       ref={ref}
                       onSuccess={props.onSuccess}>
            <CardGroup name="general" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:label')} isOpen={true}>
                <CardField name="companyName" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:companyName')} type={SystemFieldType.String} />
                <CardField name="firstName" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:firstName')} type={SystemFieldType.String} />
                <CardField name="lastName" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:lastName')} type={SystemFieldType.String} />
                <CardField name="phoneNo" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:phoneNo')} type={SystemFieldType.String} />
                <CardField name="legalRepresentativeFirstName" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:legalRepresentativeFirstName')} type={SystemFieldType.String} />
                <CardField name="legalRepresentativeLastName" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:legalRepresentativeLastName')} type={SystemFieldType.String} />
                <CardField maxLength={2} name="country" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:country')} type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="/odata/CountryRegion" tableRelationKey="countryCode" tableRelationField="country" tableRelationFilter="?$filter=enabled eq true" onValidate={(fieldName: string, record: any, oldValue: any, newValue: any)=>{

                    const client: AxiosInstance = HttpHelper.buildAxiosInstance();
                    client.get<PostalCode[]>(`/odata/PostalCode?$filter=countryCode eq '${newValue}'`).then((resp) => {
                        setAllowFreeForm(resp.data.length === 0);
                    })
                    .catch((err) => {
                        setAllowFreeForm(true);
                    }); 
                                       
                    record.city = "";
                    record.county = "";
                    record.zipCode = "";
                    return record;
                }} />
                <CardField name="streetAddress" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:streetAddress')} type={SystemFieldType.String} />
                <CardField maxLength={10} name="zipCode" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:zipCode')} type={SystemFieldType.String} onValidate={(fieldName: string, record: any, oldValue: any, newValue: any)=>{
                    if (!allowFreeForm)
                    {
                        record.city = "";
                        record.county = "";
                    }
                    
                    return record;
                }} />
                <CardField allowFreeForm={allowFreeForm} name="city" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:city')} type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="/odata/PostalCode" tableRelationKey="city" tableRelationField="city" tableRelationFilter="?$filter=zipCode eq '#zipCode#' and countryCode eq '#country#'" onValidate={(fieldName: string, record: any, oldValue: any, newValue: any)=>{
                    if (!allowFreeForm){
                        record.county = "";
                    }
                    
                    return record;
                }} />
                <CardField allowFreeForm={allowFreeForm} name="county" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:county')} type={SystemFieldType.Option} hasTableRelation tableRelationEndpoint="/odata/PostalCode" tableRelationKey="county" tableRelationField="county" tableRelationFilter="?$filter=zipCode eq '#zipCode#' and city eq '#city#' and countryCode eq '#country#'" />
                <CardField name="email" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:email')} type={SystemFieldType.String} />
                <CardField name="vat" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:vat')} type={SystemFieldType.String} hidden={hideFields} />
                <CardField name="sdi" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:sdi')} type={SystemFieldType.String} hidden={hideFields} />
                <CardField name="pecEmail" label={t('customerCenter:CustomerBillingInfoCard:CardGroup:CardField:pecEmail')} type={SystemFieldType.String} hidden={hideFields} />
            </CardGroup>
        </PageContainer>

    );
});